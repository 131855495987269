import http from "../auth/http";

const StackingPlanService = () => {
  const fetchStackingPlan = request => {
    const { buildingId } = request;

    return http
      .get(`/api/v1/buildings/${buildingId}/stackingplan/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const fetchStackingPlanFilterProfile = request => {
    const { buildingId, filtertype } = request;

    return http
      .get(`/api/v1/buildings/${buildingId}/stackingplanfilter/${filtertype}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  return {
    fetchStackingPlan,
    fetchStackingPlanFilterProfile
  };
};

const api = StackingPlanService();
export default api;
