import http from "../auth/http";

const BuildingContactService = () => {
  const fetchBuildingContacts = request => {
    let { page } = request;
    page = page || 1;

    const { buildingId, filter } = request;
    let url = `api/v1/buildingcontacts/?page=${page}`;
    url = !!buildingId ? url.concat(`&building=${buildingId}`) : url;
    url = !!filter.contactType
      ? url.concat(`&_type=${filter.contactType.short}`)
      : url;
    url = url.concat(`&ordering=order`);

    return http
      .get(url)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const fetchAllContacts = ({ page, buildingId, query }) => {
    page = page || 1;
    let url = `api/v1/users/eligiblebuildingcontacts/${buildingId}/?page=${page}`;
    if (query) {
      url = url.concat(`&search=${query}`);
    }
    return http
      .get(url)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const addBuildingContacts = (buildingId, contacts, contactType) => {
    const url = `/api/v1/buildingcontacts/addbuildingcontacts/`;
    const request = {
      buildingId: buildingId,
      contacts: contacts,
      _type: contactType
    };
    return http
      .post(url, request)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const updateBuildingContact = (contactId, contact) =>
    http
      .patch(`/api/v1/buildingcontacts/${contactId}/`, contact)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const deleteBuildingContact = contactId =>
    http
      .delete(`/api/v1/buildingcontacts/${contactId}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const updateBuildingContactsOrderService = payload => {
    http
      .patch(`/api/v1/buildingcontacts/updatecontactsorder/`, payload)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const addExternalContact = payload => {
    return http
      .post(`/api/v1/buildingcontacts/addexternalcontact/`, payload)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const getExternalContact = payload => {
    return http
      .get(`/api/v1/buildingcontacts/getexternalcontact/?email=${payload.email}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };
  return {
    fetchBuildingContacts,
    fetchAllContacts,
    updateBuildingContact,
    deleteBuildingContact,
    addBuildingContacts,
    addExternalContact,
    getExternalContact,
    updateBuildingContactsOrderService
  };
};

const api = BuildingContactService();
export default api;
