import { takeEvery, call, put } from "redux-saga/effects";
import * as actionTypes from "../constants/appConstants";
import SettingService from "../services/settingService";
import { captureException } from "@sentry/browser";

function* systemSettings() {
  try {
    const systemSettings = yield call(SettingService.getSystemSettings);

    yield put({
      type: actionTypes.SYSTEM_SETTINGS_SUCCESSFUL,
      payload: systemSettings
    });
  } catch (error) {
    captureException(new Error(`Failed to obtain system settings: ${error}`));
  }
}

/**
 * Watcher function exports
 */

export function* systemSettingsWatcher() {
  yield takeEvery(actionTypes.SYSTEM_SETTINGS, systemSettings);
}
