import http from "../auth/http";
import axios from "axios";
import debounce from "lodash/debounce";
import { buffers, eventChannel, END } from "redux-saga";

const SuiteMediaService = () => {
  const fetchSuiteMedia = request =>
    http
      .get(`/api/v1/suitemedia/?suite=${request.suiteId}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const uploadSuiteMedia = request => {
    return eventChannel(emitter => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      http
        .post(`/api/v1/suitemedia/`, request, {
          cancelToken: source.token,
          /**
           * Debouncing because in safari the progress events become
           * too frequent and hog the event loop, preventing the progress
           * bar from progressing and sometimes crashing the browser
           */
          onUploadProgress: debounce(function(progressEvent) {
            if (progressEvent.lengthComputable) {
              const progress = progressEvent.loaded / progressEvent.total;
              emitter({ progress });
            }
          }, 10)
        })
        .then(response => {
          emitter({ success: true });
          emitter(END);
        })
        .catch(error => {
          emitter({ err: new Error("Upload failed") });
          emitter(END);
        });

      return () => {
        source.cancel();
      };
    }, buffers.sliding(2));
  };

  const deleteSuiteMedia = request =>
    http
      .delete(`/api/v1/suitemedia/${request.mediaId}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const updateSuiteMedia = (mediaId, media) =>
    http
      .patch(`/api/v1/suitemedia/${mediaId}/`, media)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const setPrimaryPicture = request =>
    http
      .patch(`/api/v1/suitemedia/${request.mediaId}/setprimarypicture/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const publishMedia = mediaId =>
    http
      .patch(`/api/v1/suitemedia/${mediaId}/publishmedia/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const unpublishMedia = mediaId =>
    http
      .patch(`/api/v1/suitemedia/${mediaId}/unpublishmedia/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const reorderMedia = (suiteId, data) =>
    http
      .patch(`/api/v1/suites/${suiteId}/suitemediaorder/`, data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  return {
    fetchSuiteMedia,
    uploadSuiteMedia,
    deleteSuiteMedia,
    updateSuiteMedia,
    setPrimaryPicture,
    publishMedia,
    unpublishMedia,
    reorderMedia
  };
};

const api = SuiteMediaService();
export default api;
