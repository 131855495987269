import _ from "lodash";
import update from "immutability-helper";
import * as stackingPlanConstants from "../constants/stackingPlanConstants";
import moment from "moment";

const MAX_LEASE_EXPIRY_BUCKET = 7;

const filtertypeMap = {
  "1": "leaseExpiry",
  "2": "subtenancies"
};

const initialState = {
  plan: [],
  staging: {
    plan: []
  },

  filters: {
    suiteType: null,
    suiteAvailabilityType: null,
    suiteVisibilityType: null,
    disabledSuitesForLeaseExpiryYear: [],
    disabledSuitesForSubtenancies: [],
    tenantRightsVisible: true,
    unselectedTenantRights: [],

    area_max: null,
    area_min: null,
    selectedProfileTab: 0
  },

  filterProfiles: {
    leaseExpiry: [],
    subtenancies: []
  },
  lockFiltersForPrint: false,
  isLoading: false
};

const isAnyTenantRightForSuiteUnselected = (lease, unselectedTenantRights) => {
  if (!!!lease) {
    return false;
  } else {
    let result = false;
    unselectedTenantRights.forEach((right, i) => {
      if (lease[right]) {
        result = true;
      }
    });
    return result;
  }
};

const updateFloorSuiteSet = (floor, filters) => {
  floor.isDisabled = true;
  floor.floor_suite_set_rel.forEach(suite => {
    const isSuiteDisabled = isDisabled(suite, filters);
    suite.isDisabled = isSuiteDisabled;
    if (!isSuiteDisabled) {
      floor.isDisabled = false;
    }
  });
  return floor;
};

const getLeaseExpiryKey = suite => {
  let key = "Vacant";
  if (suite.lease) {
    const endDate = moment(suite.lease.end_date, "YYYY-MM-DD");
    const todayDate = moment();
    if ((suite.lease.is_active_lease && endDate.isBefore(todayDate)) || suite.lease.is_month_to_month_lease) {
      key = "Mo-to-Mo";
    } else {
      key = moment(suite.lease.end_date, "YYYY-MM-DD").year();
      if (
        key >
        moment()
          .add(MAX_LEASE_EXPIRY_BUCKET, "years")
          .year()
      ) {
        key = moment()
          .add(MAX_LEASE_EXPIRY_BUCKET, "years")
          .year();
      }
    }
  }

  return key;
};
const getFilteredArea = suite => {
  let area;
  if (suite.lease && suite.lease.area) {
    area = suite.lease.area;
  } else if (suite.area) {
    area = suite.area;
  } else {
    area = suite.measured_area;
  }
  return area;
};
const isSuiteDisabledDueToSuiteVisibilitySelection = (
  published,
  suiteAvailabilityType
) => {
  if (
    !!suiteAvailabilityType &&
    published !== null &&
    !_.isUndefined(published)
  ) {
    if (
      (suiteAvailabilityType === "P" && !published) ||
      (suiteAvailabilityType === "PR" && published)
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const isSuiteDisabledBecauseOfSearchQuery = (option, suite) => {
  const suiteName = suite.suite_identifier
    ? suite.suite_identifier.toString().toLowerCase()
    : "";
  const suiteTenant = suite.lease ? suite.lease.tenant.toLowerCase() : "";

  const isEnabledForOption =
    suiteName.includes(option.value.toLowerCase()) ||
    suiteTenant.includes(option.value.toLowerCase());
  return !isEnabledForOption;
};

export const isDisabled = (suite, filters) => {
  const {
    suiteType,
    suiteAvailabilityType,
    area_max,
    area_min,
    measured_area_max,
    measured_area_min,
    disabledSuitesForLeaseExpiryYear,
    disabledSuitesForSubtenancies,
    unselectedTenantRights,
    selectedOption
  } = filters;
  const isDisabledBecauseOfArchived = suite.archived;
  const isDisableBecauseOfTenantRight = isAnyTenantRightForSuiteUnselected(
    suite.lease,
    unselectedTenantRights
  );
  const isDisableBecauseOfSuiteType = suiteType
    ? suiteType !== suite._type
    : false;
  const isDisabledBecauseOfSuiteAvailabilityType = suiteAvailabilityType
    ? suiteAvailabilityType !== suite.state &&
      (suiteAvailabilityType === "L" || suiteAvailabilityType === "C")
    : false;
  const isDisabledBecauseOfSuiteVisibilityType = isSuiteDisabledDueToSuiteVisibilitySelection(
    suite.published,
    suiteAvailabilityType
  );

  let isDisabledBecauseOfAreaRange = false;
  const area = getFilteredArea(suite);
  if (area_min && area < area_min) {
    isDisabledBecauseOfAreaRange = true;
  } else if (area_max && area > area_max) {
    isDisabledBecauseOfAreaRange = true;
  }

  let isDisabledBecauseOfProfile = false;
  switch (filters.selectedProfileTab) {
    case 0: // Lease Expiry Profile
      const key = getLeaseExpiryKey(suite);
      isDisabledBecauseOfProfile = disabledSuitesForLeaseExpiryYear.includes(
        key
      );
      break;

    case 2: // Subtenancy Profile
      isDisabledBecauseOfProfile = disabledSuitesForSubtenancies.includes(
        !!suite.lease && !!suite.lease.sublease && !!suite.lease.sublease.length
          ? "Yes"
          : "No"
      );
      break;
  }
  const isDisabledBecauseOfSearchQuery = selectedOption
    ? isSuiteDisabledBecauseOfSearchQuery(selectedOption, suite)
    : false;

  return (
    isDisabledBecauseOfArchived ||
    isDisableBecauseOfTenantRight ||
    isDisableBecauseOfSuiteType ||
    isDisabledBecauseOfSuiteAvailabilityType ||
    isDisabledBecauseOfAreaRange ||
    isDisabledBecauseOfProfile ||
    isDisabledBecauseOfSuiteVisibilityType ||
    isDisabledBecauseOfSearchQuery
  );
};

const updatePlan = (plan, filters) => {
  plan = plan.map((floor, i) => {
    return updateFloorSuiteSet(floor, filters);
  });
  return plan;
};

const reducer = function stackingPlanReducer(state = initialState, action) {
  switch (action.type) {
    case stackingPlanConstants.FETCH_STACKING_PLAN:
      return Object.assign({}, state, {
        isLoading: true
      });

    case stackingPlanConstants.FETCH_STACKING_PLAN_SUCCESSFUL:
      let plan = action.payload.stackingplan;

      plan = plan.map((floor, i) => {
        floor.isExpandable = false;
        if (floor.suite_count > 4) {
          floor.isExpandable = true;
        }
        floor = updateFloorSuiteSet(floor, state.filters);

        return floor;
      });

      return Object.assign({}, state, {
        plan: plan,
        staging: {
          plan: _.cloneDeep(plan)
        },
        isLoading: false
      });

    case stackingPlanConstants.FETCH_STACKING_PLAN_FAILED:
      return Object.assign({}, state, {
        plan: action.payload,
        staging: {
          plan: _.cloneDeep(action.payload)
        },
        isLoading: false
      });


    case stackingPlanConstants.LEASE_EXPIRY_TOGGLE: {
      const bucket = action.payload.bucket;
      var disabledSuitesForLeaseExpiryYear = [
        ...state.filters.disabledSuitesForLeaseExpiryYear
      ];

      if (disabledSuitesForLeaseExpiryYear.includes(bucket)) {
        disabledSuitesForLeaseExpiryYear = disabledSuitesForLeaseExpiryYear.filter(
          year => year !== bucket
        );
      } else {
        disabledSuitesForLeaseExpiryYear.push(bucket);
      }

      const filters = update(state.filters, {
        disabledSuitesForLeaseExpiryYear: {
          $set: disabledSuitesForLeaseExpiryYear
        }
      });
      const newPlan = updatePlan(state.plan, filters);
      const newState = update(state, {
        plan: { $set: newPlan },
        staging: { plan: { $set: newPlan } },
        filters: { $set: filters }
      });
      return newState;
    }

    case stackingPlanConstants.LEASE_EXPIRY_SELECT_ALL: {
      const filters = update(state.filters, {
        disabledSuitesForLeaseExpiryYear: {
          $set: action.payload.disabledSuites
        }
      });
      const newPlan = updatePlan(state.plan, filters);
      const newState = update(state, {
        plan: { $set: newPlan },
        staging: { plan: { $set: newPlan } },
        filters: { $set: filters }
      });
      return newState;
    }

    case stackingPlanConstants.LEASE_EXPIRY_UNSELECT_ALL: {
      const filters = update(state.filters, {
        disabledSuitesForLeaseExpiryYear: { $set: [] }
      });
      const newPlan = updatePlan(state.plan, filters);
      const newState = update(state, {
        plan: { $set: newPlan },
        staging: { plan: { $set: newPlan } },
        filters: { $set: filters }
      });
      return newState;
    }

    case stackingPlanConstants.SUBTENANCY_TOGGLE: {
      const filters = state.filters;
      const { bucket_name } = action.payload.bucket;
      var disabledSuitesForSubtenancies = [
        ...filters.disabledSuitesForSubtenancies
      ];
      if (disabledSuitesForSubtenancies.includes(bucket_name)) {
        disabledSuitesForSubtenancies = disabledSuitesForSubtenancies.filter(
          item => item !== bucket_name
        );
      } else {
        disabledSuitesForSubtenancies.push(bucket_name);
      }

      filters.disabledSuitesForSubtenancies = disabledSuitesForSubtenancies;
      return Object.assign({}, state, {
        filters: filters
      });
    }

    case stackingPlanConstants.SUITE_SEARCH_FILTER_CHANGED: {
      const newPlan = updatePlan(state.plan, {
        ...state.filters,
        selectedOption: action.payload.selectedOption
      });
      const newState = update(state, {
        plan: { $set: newPlan },
        staging: { plan: { $set: newPlan } },
        filters: {
          selectedOption: { $set: action.payload.selectedOption }
        }
      });
      return newState;
    }

    case stackingPlanConstants.SUITE_TYPE_FILTER_CHANGED: {
      const newPlan = updatePlan(state.plan, {
        ...state.filters,
        suiteType: action.payload.id
      });

      const newState = update(state, {
        plan: { $set: newPlan },
        staging: { plan: { $set: newPlan } },
        filters: {
          suiteType: { $set: action.payload.id }
        }
      });
      return newState;
    }

    case stackingPlanConstants.SUITE_AVAILABILITY_FILTER_CHANGED: {
      const newPlan = updatePlan(state.plan, {
        ...state.filters,
        suiteAvailabilityType: action.payload.id
      });
      const newState = update(state, {
        plan: { $set: newPlan },
        staging: { plan: { $set: newPlan } },
        filters: {
          suiteAvailabilityType: { $set: action.payload.id }
        }
      });
      return newState;
    }

    case stackingPlanConstants.SUITE_VISIBILITY_FILTER_CHANGED: {
      return update(state, {
        filters: {
          suiteVisibilityType: { $set: action.payload.id }
        }
      });
    }

    case stackingPlanConstants.AREA_RANGE_FILTER_CHANGED: {
      const filters = update(state.filters, {
        area_max: { $set: action.payload.areaRange[1] },
        area_min: { $set: action.payload.areaRange[0] }
      });
      const newPlan = updatePlan(state.plan, filters);
      const newState = update(state, {
        plan: { $set: newPlan },
        staging: { plan: { $set: newPlan } },
        filters: { $set: filters }
      });
      return newState;
    }
    case stackingPlanConstants.FETCH_STACKING_PLAN_FILTERPROFILE_SUCCESSFUL: {
      if (!state.filters.lockFiltersForPrint) {
        const filtertypeKey = filtertypeMap[action.payload.filtertype];

        return update(state, {
          filters: {
            area_max: { $set: action.payload.area_max },
            area_min: { $set: action.payload.area_min }
          },
          filterProfiles: {
            [filtertypeKey]: { $set: action.payload.profile }
          }
        });
      }
      return state;
    }

    case stackingPlanConstants.CHANGE_SELECTED_FILTER_PROFILE:
      return update(state, {
        filters: {
          selectedProfileTab: { $set: action.payload }
        }
      });

    case stackingPlanConstants.TENANT_RIGHTS_VISIBILITY_TOGGLE:
      return update(state, {
        filters: {
          tenantRightsVisible: { $set: !state.filters.tenantRightsVisible },
          unselectedTenantRights: { $set: [] }
        }
      });

    case stackingPlanConstants.TENANT_RIGHT_TOGGLE: {
      const toggledTenantRightId = action.payload;
      let unselectedTenantRights = state.filters.unselectedTenantRights.slice();
      if (unselectedTenantRights.includes(toggledTenantRightId)) {
        unselectedTenantRights = _.without(
          unselectedTenantRights,
          toggledTenantRightId
        );
      } else {
        unselectedTenantRights.push(toggledTenantRightId);
      }

      const filters = update(state.filters, {
        unselectedTenantRights: { $set: unselectedTenantRights }
      });
      const newPlan = updatePlan(state.plan, filters);
      const newState = update(state, {
        plan: { $set: newPlan },
        staging: { plan: { $set: newPlan } },
        filters: { $set: filters }
      });
      return newState;
    }

    case stackingPlanConstants.TENANT_RIGHTS_SELECT_ALL: {
      const filters = update(state.filters, {
        unselectedTenantRights: { $set: [] }
      });
      const newPlan = updatePlan(state.plan, filters);
      const newState = update(state, {
        plan: { $set: newPlan },
        staging: { plan: { $set: newPlan } },
        filters: { $set: filters }
      });
      return newState;
    }

    case stackingPlanConstants.TENANT_RIGHTS_UNSELECT_ALL: {
      const filters = update(state.filters, {
        unselectedTenantRights: { $set: action.payload }
      });
      const newPlan = updatePlan(state.plan, filters);
      const newState = update(state, {
        plan: { $set: newPlan },
        staging: { plan: { $set: newPlan } },
        filters: { $set: filters }
      });
      return newState;
    }

    case stackingPlanConstants.APPLY_FILTERS: {
      const filters = action.payload;

      return update(state, {
        filters: {
          lockFiltersForPrint: { $set: true },
          suiteType: { $set: filters.suiteType },
          suiteAvailabilityType: { $set: filters.suiteAvailabilityType },
          suiteVisibilityType: { $set: filters.suiteVisibilityType },
          disabledSuitesForLeaseExpiryYear: {
            $set: filters.disabledSuitesForLeaseExpiryYear
          },
          disabledSuitesForSubtenancies: {
            $set: filters.disabledSuitesForSubtenancies
          },
          tenantRightsVisible: { $set: filters.tenantRightsVisible },
          unselectedTenantRights: { $set: filters.unselectedTenantRights },
          area_max: { $set: filters.area_max },
          area_min: { $set: filters.area_min },
          selectedProfileTab: { $set: filters.selectedProfileTab }
        }
      });
    }

    case stackingPlanConstants.STACKING_PLAN_UNMOUNT:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
