import axios from "axios";
import { randomBytes } from "crypto";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import ReactGA from "react-ga";
import http, { noAuthHttp } from "../auth/http";
import settings from "../config";
import {
  base64URLEncode,
  generateRandomString,
  generateUrlEncodedQuery,
  pkceChallengeFromVerifier
} from "../utilities";

const tokenKey = "AuthenticationToken";
const forcePasswordChangeResponse = "FORCE_PASSWORD_CHANGE";

const AuthenticationService = () => {
  const state = {
    callback: null,
    tokenCallback: null,
    tokenVerified: false,
    verificationInProgress: false,
    lastRefreshTime: null
  };

  const getToken = () => Cookies.get(tokenKey);

  const setCookieExpiry = () => {
    return new Date(new Date().getTime() + 57 * 60 * 1000);
  };
  const setToken = (token, expiry = 14) => {
    Cookies.set(tokenKey, token, { expires: expiry });
    state.tokenVerified = true;
  };

  const setTokenExpiry = () => {
    Cookies.set("AuthenticationTokenExpiry", setCookieExpiry(), {
      expires: setCookieExpiry()
    });
  };
  const AuthenticationRefreshToken = token => {
    Cookies.set("RefreshToken", token);
  };
  const setOktaAuthFlag = () => {
    Cookies.set("oktaLoggedIn", "true", { expires: setCookieExpiry() });
  };

  const getOktaAuthFlag = () => Cookies.get("oktaLoggedIn");

  const removeOktaAuthFlag = () => {
    Cookies.remove("oktaLoggedIn");
  };

  const removeToken = () => {
    Cookies.remove(tokenKey);
    //http.defaults.headers.common.Authorization = '';
    state.tokenVerified = false;
  };

  const signalStateChange = authenticated => {
    if (state.callback && typeof state.callback === "function") {
      state.callback(authenticated);
    }
  };

  const signalTokenVerified = (verified, expired) => {
    expired = expired || false;
    if (state.tokenCallback && typeof state.tokenCallback === "function") {
      state.tokenCallback(verified, expired);
    }
  };

  const hasToken = () => {
    const token = getToken();
    return token !== null && token !== undefined;
  };

  const tokenVerified = () => state.tokenVerified;

  /** Only call this function if the hasToken() check returned true.
   */
  const verifyToken = () => {
    if (state.verificationInProgress) {
      return Promise.reject("in_progress");
    }
    const isOktaAuth = getOktaAuthFlag();
    if (!isOktaAuth) {
      state.verificationInProgress = true;
      return new Promise((resolve, reject) => {
        http
          .post("api/v1/token/verify", {
            token: getToken()
          })
          .then(response => {
            state.verificationInProgress = false;
            setToken(response.data.token);
            signalTokenVerified(true);
            resolve();
          })
          .catch(error => {
            if (error) {
              if (error.response.data === forcePasswordChangeResponse) {
                state.verificationInProgress = false;
                signalTokenVerified(false);
                reject(forcePasswordChangeResponse);
              } else {
                state.verificationInProgress = false;
                removeToken();
                signalTokenVerified(false);
                reject("invalid_token");
              }
            }
          });
      });
    }
  };

  const user = () => {
    if (!hasToken()) {
      return {};
    }
    const decoded = jwtDecode(getToken());
    ReactGA.set({ userId: decoded.user_id });
    return {
      username: decoded.username,
      id: decoded.user_id,
      email: decoded.email
    };
  };

  const login = (username, password) => {
    removeToken();

    return new Promise((resolve, reject) => {
      http
        .post("api/v1/token/auth", {
          username,
          password
        })
        .then(response => {
          setToken(response.data.token);
          const authenticated = true;
          signalStateChange(authenticated);
          resolve(response.data);
        })
        .catch(error => reject(error));
    });
  };

  const onLogout = () => {
    removeToken();
    removeOktaAuthFlag();
    ReactGA.set({ userId: null });
    window.location.reload();
    //const authenticated = false;
    //signalStateChange(authenticated);
  };

  const logout = () => {
    // http.post('api/v1/logout/', {
    //   token: getToken(),
    // });
    onLogout();
  };

  const refreshToken = () => {
    if (!hasToken()) {
      return;
    }
    const isOktaAuth = getOktaAuthFlag();
    if (isOktaAuth) {
      return;
    }

    if (state.lastRefreshTime !== null) {
      const timeSinceLastRefresh = new Date().valueOf() - state.lastRefreshTime;
      if (timeSinceLastRefresh < 45000) {
        return;
      }
    }

    return new Promise((resolve, reject) => {
      http
        .post("api/v1/token/refresh", {
          token: getToken()
        })
        .then(response => {
          setToken(response.data.token);
          signalTokenVerified(true);
          state.lastRefreshTime = new Date().valueOf();
          resolve(response.data.token);
        })
        .catch(error => {
          if (error) {
            onLogout();
            const tokenExpired = true;
            signalTokenVerified(false, tokenExpired);
          }
          reject();
        });
    });
  };

  const setCallback = callback => {
    state.callback = callback;
  };

  const setTokenCallback = callback => {
    state.tokenCallback = callback;
  };

  const forgotPassword = request =>
    noAuthHttp
      .post(`/api/v1/users/forgotpassword/`, request)
      .then(response => Promise.resolve(response.data))
      .catch(error => {
        // We treat 404 errors as success to avoid exposing
        // the potential security issue that this API identifies
        // whether or not a username exists in the system.
        if (error.response.status !== 404) {
          return Promise.reject(error.response.data);
        }
      });

  const validateResetPasswordKey = request =>
    noAuthHttp
      .post(`/api/v1/users/validatekey/`, request)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data));

  const resetPassword = request =>
    noAuthHttp
      .post(`/api/v1/users/resetpassword/`, request)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data));

  const forcePasswordChange = request =>
    http
      .post(`/api/v1/users/force-password-change/`, request)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data));

  const oktaAuthRedirect = async hasClientId => {
    const config = {
      client_id: settings.OKTA_CLIENT_ID, //YOUR_OAUTH_CLIENTID_HERE
      redirect_uri: settings.OKTA_REDIRECT_URI,
      authorization_url: settings.OKTA_AUTHORIZATION_URL,
      authorization_endpoint: settings.OKTA_AUTHORIZATION_ENDPOINT,
      token_endpoint: settings.OKTA_TOKEN_ENDPOINT,
      scope: "openid email profile offline_access",
      response_type: "code",
      response_mode: "query",
      code_challenge_method: "S256"
    };
    // Create and store a random "state" value
    const nonce = generateRandomString(64);
    const state = `${nonce};{"source_redirect_url":"${config.redirect_uri}", "authorize_url": "${config.authorization_url}"}`;
    localStorage.setItem("pkce_state", state);
    // Create and store a new PKCE code_verifier (the plaintext random secret)
    const code_verifier = base64URLEncode(randomBytes(64));
    localStorage.setItem("pkce_code_verifier", code_verifier);
    localStorage.setItem("cookieExpiry", setCookieExpiry());
    // Hash and base64-urlencode the secret to use as the challenge
    const code_challenge = await pkceChallengeFromVerifier(code_verifier);
    let query = {
      ...config,
      state,
      code_challenge,
      nonce
    };

    if (hasClientId) {
      query = { ...query, $interstitial_prompt_mri_client_id: true };
    }

    // Build the authorization URL
    const url = `${config.authorization_endpoint}?${generateUrlEncodedQuery(
      query
    )}`;

    // Navigate to Welcome home proxy
    window.location.replace(url);
  };

  const exchangeOktaAuthCode = async query => {
    const config = {
      client_id: settings.OKTA_CLIENT_ID,
      tokenEndpoint: settings.OKTA_TOKEN_ENDPOINT
    };
    if (query.code) {
      if (query.state != localStorage.getItem("pkce_state")) {
        throw "Invalid Pkce state";
      }
      const body = {
        grant_type: "authorization_code",
        redirect_uri: query.redirect_uri,
        code: query.code,
        client_id: config.client_id,
        response_mode: "query",
        code_verifier: localStorage.getItem("pkce_code_verifier")
      };

      //Exchange the authorization code for an id_token
      const response = await axios.post(
        config.tokenEndpoint,
        generateUrlEncodedQuery(body),
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      );
      // Clean up the pkce_state and verifier from localstorage
      localStorage.setItem("token", response.data.refresh_token);
      localStorage.removeItem("pkce_state");
      localStorage.removeItem("pkce_code_verifier");
      if (response.status == 200) {
        setToken(response.data.id_token, setCookieExpiry());
        setTokenExpiry();
        AuthenticationRefreshToken(response.data.refresh_token);
        const authenticated = true;
        signalStateChange(authenticated);
        setOktaAuthFlag();
        return response.data;
      }
      throw response.error;
    }
  };

  const refreshOktaAuthCode = async refreshToken => {
    const config = {
      client_id: settings.OKTA_CLIENT_ID,
      tokenEndpoint: settings.OKTA_TOKEN_ENDPOINT
    };
    const body = {
      grant_type: "refresh_token",
      redirect_uri: settings.OKTA_REDIRECT_URI,
      client_id: config.client_id,
      scope: "openid email profile offline_access",
      refresh_token: refreshToken
    };

    //Exchange the authorization code for an id_token
    const response = await axios.post(
      config.tokenEndpoint,
      generateUrlEncodedQuery(body),
      { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
    );

    if (response.status == 200) {
      localStorage.setItem("token", response.data.refresh_token);
      setToken(response.data.id_token, setCookieExpiry());
      setTokenExpiry();
      AuthenticationRefreshToken(response.data.refresh_token);
      const authenticated = true;
      signalStateChange(authenticated);
      setOktaAuthFlag();
      return response.data;
    }
    throw response.error;
  };

  return {
    removeToken,
    getToken,
    setToken,
    hasToken,
    tokenVerified,
    verifyToken,
    refreshToken,
    login,
    logout,
    setCallback,
    setTokenCallback,
    user,
    forgotPassword,
    validateResetPasswordKey,
    resetPassword,
    forcePasswordChange,
    forcePasswordChangeResponse,
    oktaAuthRedirect,
    exchangeOktaAuthCode,
    refreshOktaAuthCode,
    getOktaAuthFlag,
    setCookieExpiry,
    AuthenticationRefreshToken
  };
};

const api = AuthenticationService();
export default api;
