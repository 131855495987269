import http from "../auth/http";

function getFiltersQueryString(filters) {
  let queryString = "";

  if (!!filters) {
    if (!!filters.cities && filters.cities.length) {
      queryString = queryString.concat(
        "&city=" +
          filters.cities.map(city => encodeURIComponent(city)).join(",")
      );
    }

    if (!!filters.types && filters.types.length) {
      queryString = queryString.concat(
        "&_type=" +
          filters.types.map(type => encodeURIComponent(type)).join(",")
      );
    }

    !!filters.company &&
      (queryString = queryString.concat(
        "&company__name=" + encodeURIComponent(filters.company)
      ));
    !!filters.fund &&
      (queryString = queryString.concat(
        "&ownership__name=" + encodeURIComponent(filters.fund)
      ));
    !!filters.province &&
      (queryString = queryString.concat(
        "&province=" + encodeURIComponent(filters.province)
      ));
    !!filters.region &&
      (queryString = queryString.concat(
        "&region=" + encodeURIComponent(filters.region)
      ));
    !!filters.sub_region &&
      (queryString = queryString.concat(
        "&sub_region=" + encodeURIComponent(filters.sub_region)
      ));
    !!filters.group &&
      (queryString = queryString.concat(
        "&group=" + encodeURIComponent(filters.group)
      ));
  }

  return queryString;
}

const DashboardService = () => {
  const fetchDashboardFilterItems = request =>
    http
      .get(`/api/v1/dashboard/filteritems/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const fetchDashboardMeta = request => {
    let url = `/api/v1/dashboard/dashboardmeta/?`;
    let filtersQueryString = getFiltersQueryString(request.filters);
    url = !!filtersQueryString ? url.concat(filtersQueryString) : url;

    return http
      .get(url)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const fetchDistribution = request => {
    let url = `/api/v1/dashboard/distribution/?`;
    let filtersQueryString = getFiltersQueryString(request.filters);
    url = !!filtersQueryString ? url.concat(filtersQueryString) : url;

    return http
      .get(url)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const fetchLeaseExpiryProfile = request => {
    let url = `/api/v1/dashboard/leaseexpiryprofile/?`;
    let filtersQueryString = getFiltersQueryString(request.filters);
    url = !!filtersQueryString ? url.concat(filtersQueryString) : url;

    return http
      .get(url)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const fetchInsights = request => {
    let url = `/api/v1/leads/insights/?`;
    let filtersQueryString = getFiltersQueryString(request.filters);
    url = !!filtersQueryString ? url.concat(filtersQueryString) : url;

    return http
      .get(url)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const fetchEngagement = request => {
    let url = `/api/v1/leads/engagement/?`;
    let filtersQueryString = getFiltersQueryString(request.filters);
    url = !!filtersQueryString ? url.concat(filtersQueryString) : url;

    return http
      .get(url)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  return {
    fetchDashboardMeta,
    fetchDashboardFilterItems,
    fetchLeaseExpiryProfile,
    fetchDistribution,
    fetchInsights,
    fetchEngagement
  };
};

const api = DashboardService();
export default api;
