import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import progress from "react-redux-progress/reducer";

import * as authActionTypes from "./containers/Auth/constants";

import login from "./containers/Login/reducers";
import network from "./reducers/networkReducer";
import auth from "./containers/Auth/reducers";
import search from "./reducers/searchReducer";
import user from "./containers/User/reducers";

import app from "./reducers/appReducer";
import dashboard from "./reducers/dashboardReducer";
import buildings from "./reducers/buildingsReducer";
import suite from "./reducers/suitesReducer";
import lease from "./reducers/leaseReducer";
import buildingReports from "./reducers/buildingReportsReducer";
import rentRoll from "./reducers/rentRollReducer";
import stackingPlan from "./reducers/stackingPlanReducer";
import suiteMedia from "./reducers/suiteMediaReducer";
import lovs from "./reducers/lovReducer";
import buildingAnnouncement from "./reducers/buildingAnnouncementReducer";
import buildingInformation from "./reducers/buildingInformationReducer";
import buildingMedia from "./reducers/buildingMediaReducer";
import buildingContact from "./reducers/buildingContactReducer";
import floorplan from "./reducers/floorPlanReducer";
import buildingsVirtualTour from "./reducers/buildingsVirtualTourReducer";
import suitesVirtualTour from "./reducers/suitesVirtualTourReducer";

const rootReducer = (state, action) => {
  if (action.type === authActionTypes.CLEAR_APP_STATE) {
    console.log("Clearing app state...");
    state = {};
  }
  return appReducer(state, action);
};

const appReducer = combineReducers({
  /* App’s top-level reducers */
  auth,
  login,
  network,
  search,
  user,
  app,
  dashboard,
  buildings,
  suite,
  lease,
  buildingReports,
  rentRoll,
  stackingPlan,
  suiteMedia,
  lovs,
  buildingAnnouncement,
  buildingInformation,
  buildingMedia,
  buildingContact,
  floorplan,
  form: formReducer,
  progress: progress,
  buildingsVirtualTour,
  suitesVirtualTour
});

export default rootReducer;
