export const AUTHENTICATE_USER = "Auth/AUTHENTICATE_USER";
export const AUTHENTICATION_SUCCESSFUL = "Auth/AUTHENTICATION_SUCCESSFUL";
export const AUTHENTICATION_FAILED = "Auth/AUTHENTICATION_FAILED";

export const OKTA_REDIRECT = "Auth/OKTA_REDIRECT";
export const OKTA_REDIRECT_SUCCESSFUL = "Auth/OKTA_REDIRECT_SUCCESSFUL";
export const OKTA_REDIRECT_FAILED = "Auth/OKTA_REDIRECT_FAILED";

export const AUTHENTICATE_OKTA_USER = "Auth/AUTHENTICATE_OKTA_USER";
export const OKTA_AUTHENTICATION_SUCCESSFUL =
  "Auth/OKTA_AUTHENTICATION_SUCCESSFUL";
export const OKTA_AUTHENTICATION_FAILED = "Auth/OKTA_AUTHENTICATION_FAILED";

export const REFRESH_AUTHENTICATE_OKTA_USER =
  "Auth/REFRESH_AUTHENTICATE_OKTA_USER";
export const OKTA_REFRESH_AUTHENTICATION_SUCCESSFUL =
  "Auth/OKTA_REFRESH_AUTHENTICATION_SUCCESSFUL";
export const OKTA_REFRESH_AUTHENTICATION_FAILED =
  "Auth/OKTA_REFRESH_AUTHENTICATION_FAILED";

export const VERIFY_TOKEN = "Auth/VERIFY_TOKEN";
export const TOKEN_VERIFICATION_SUCCESSFUL =
  "Auth/TOKEN_VERIFICATION_SUCCESSFUL";
export const TOKEN_VERIFICATION_FAILED = "Auth/TOKEN_VERIFICATION_FAILED";

export const REFRESH_TOKEN = "Auth/REFRESH_TOKEN";
export const TOKEN_REFRESH_SUCCESSFUL = "Auth/TOKEN_REFRESH_SUCCESSFUL";
export const TOKEN_REFRESH_FAILED = "Auth/TOKEN_REFRESH_FAILED";

export const LOGOUT_USER = "Auth/LOGOUT_USER";
export const LOGOUT_SUCCESSFUL = "Auth/LOGOUT_SUCCESSFUL";
export const LOGOUT_FAILED = "Auth/LOGOUT_FAILED";

export const CLEAR_APP_STATE = "Auth/CLEAR_APP_STATE";