import { takeEvery, call, put } from "redux-saga/effects";

import * as buildingsVirtualTourConstants from "../constants/buildingsVirtualTourConstants";
import BuildingVirtualTourService from "../services/buildingsVirtualTourService";

function* createBuildingTour(action) {
  const { tour, onSuccess } = action.payload;

  try {
    const result = yield call(BuildingVirtualTourService.createTour, tour);
    yield put({
      type: buildingsVirtualTourConstants.CREATE_BUILDING_TOURS_SUCCESSFUL,
      payload: result
    });

    !!onSuccess && onSuccess(result);
  } catch (error) {
    yield put({ type: buildingsVirtualTourConstants.CREATE_BUILDING_TOURS_FAILED, error });
  }
}

function* fetchBuildingTours(action){
  const { id, onSuccess } = action.payload;

  try {
    const tours = yield call(BuildingVirtualTourService.fetchTours, { id: id });
    yield put({
      type: buildingsVirtualTourConstants.FETCH_BUILDING_TOURS_SUCCESSFUL,
      payload: tours
    });

    !!onSuccess && onSuccess();
  } catch (error) {
    yield put({ type: buildingsVirtualTourConstants.FETCH_BUILDING_TOURS_FAILED, error });
  }
}

function* updateBuildingTour(action) {
  const { onSuccess } = action.payload;

  try {
    const result = yield call(BuildingVirtualTourService.updateTour, action.payload);
    yield put({
      type: buildingsVirtualTourConstants.UPDATE_BUILDING_TOURS_SUCCESSFUL,
      payload: result
    });

    onSuccess && onSuccess(result);
  } catch (error) {
    yield put({ type: buildingsVirtualTourConstants.UPDATE_BUILDING_TOURS_FAILED, error });
  }
}

function* deleteBuildingTour(action) {
  const { onSuccess } = action.payload;

  try {
    const result = yield call(BuildingVirtualTourService.deleteTour, action.payload);
    yield put({
      type: buildingsVirtualTourConstants.DELETE_BUILDING_TOURS_SUCCESSFUL,
      payload: {
        id: action.payload.tourId
      }
    });

    !!onSuccess && onSuccess();
  } catch (error) {
    yield put({ type: buildingsVirtualTourConstants.DELETE_BUILDING_TOURS_FAILED, error });
  }
}

export function* createBuildingTourWatcher() {
  yield takeEvery(buildingsVirtualTourConstants.CREATE_BUILDING_TOURS, createBuildingTour);
}
export function* fetchBuildingToursWatcher() {
  yield takeEvery(buildingsVirtualTourConstants.FETCH_BUILDING_TOURS, fetchBuildingTours);
}
export function* updateBuildingTourWatcher() {
  yield takeEvery(buildingsVirtualTourConstants.UPDATE_BUILDING_TOURS, updateBuildingTour);
}
export function* deleteBuildingTourWatcher() {
  yield takeEvery(buildingsVirtualTourConstants.DELETE_BUILDING_TOURS, deleteBuildingTour);
}
