export const FETCH_ANNOUNCEMENTS = "announcement/FETCH_ANNOUNCEMENTS";
export const FETCH_ANNOUNCEMENTS_SUCCESSFUL =
  "announcement/FETCH_ANNOUNCEMENTS_SUCCESSFUL";
export const FETCH_ANNOUNCEMENTS_FAILED =
  "announcement/FETCH_ANNOUNCEMENTS_FAILED";

export const UPDATE_ANNOUNCEMENT = "announcement/UPDATE_ANNOUNCEMENT";
export const UPDATE_ANNOUNCEMENT_SUCCESSFUL =
  "announcement/UPDATE_ANNOUNCEMENT_SUCCESSFUL";
export const UPDATE_ANNOUNCEMENT_FAILED =
  "announcement/UPDATE_ANNOUNCEMENT_FAILED";
