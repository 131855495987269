import * as leaseConstants from "../constants/leaseConstants";
import * as buildingConstants from "../constants/buildings";

const initialState = {
  fetching: false,
  leasesPage: {
    results: []
  },

  //For create new lease
  suitesForBuilding: []
};

const reducer = function leaseReducer(state = initialState, action) {
  switch (action.type) {
    case leaseConstants.FETCH_LEASES:
      return Object.assign({}, state, {
        fetching: true
      });

    case leaseConstants.FETCH_LEASES_SUCCESSFUL:
      return Object.assign({}, state, {
        fetching: false,
        leasesPage: action.payload
      });

    case leaseConstants.FETCH_LEASES_FAILED:
      return Object.assign({}, state, {
        fetching: false
      });

    case buildingConstants.FETCH_SUITES_FOR_BUILDING_SUCCESSFUL:
      return Object.assign({}, state, {
        suitesForBuilding: action.payload
      });

    default:
      return state;
  }
};

export default reducer;
