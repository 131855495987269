import { takeEvery, call, put } from "redux-saga/effects";

import * as networkConstants from "../constants/networkConstants";
import NetworkService from "../services/networkService";

function* checkConnectivity(action) {
  try {
    const result = yield call(NetworkService.checkConnectivity, action.payload);
    yield put({
      type: networkConstants.CHECK_CONNECTIVITY_SUCCESSFUL,
      payload: result
    });
  } catch (error) {
    yield put({ type: networkConstants.CHECK_CONNECTIVITY_FAILED, error });
  }
}

export function* checkConnectivityWatcher() {
  yield takeEvery(networkConstants.CHECK_CONNECTIVITY, checkConnectivity);
}
