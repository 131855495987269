export const CREATE_BUILDING_TOURS = "buildingtours/CREATE_BUILDING_TOURS";
export const CREATE_BUILDING_TOURS_SUCCESSFUL = "buildingtours/CREATE_BUILDING_TOURS_SUCCESSFUL";
export const CREATE_BUILDING_TOURS_FAILED = "buildingtours/CREATE_BUILDING_TOURS_FAILED";

export const FETCH_BUILDING_TOURS = "buildingtours/FETCH_BUILDING_TOURS";
export const FETCH_BUILDING_TOURS_SUCCESSFUL = "buildingtours/FETCH_BUILDING_TOURS_SUCCESSFUL";
export const FETCH_BUILDING_TOURS_FAILED = "buildingtours/FETCH_BUILDING_TOURS_FAILED";

export const UPDATE_BUILDING_TOURS = "buildingtours/UPDATE_BUILDING_TOURS";
export const UPDATE_BUILDING_TOURS_SUCCESSFUL = "buildingtours/UPDATE_BUILDING_TOURS_SUCCESSFUL";
export const UPDATE_BUILDING_TOURS_FAILED = "buildingtours/UPDATE_BUILDING_TOURS_FAILED";

export const DELETE_BUILDING_TOURS = "buildingtours/DELETE_BUILDING_TOURS";
export const DELETE_BUILDING_TOURS_SUCCESSFUL = "buildingtours/DELETE_BUILDING_TOURS_SUCCESSFUL";
export const DELETE_BUILDING_TOURS_FAILED = "buildingtours/DELETE_BUILDING_TOURS_FAILED";

