import { takeEvery, call, put } from "redux-saga/effects";

import * as suiteConstants from "../constants/suiteConstants";
import * as stackingPlanConstants from "../constants/stackingPlanConstants";

import ExtendedSuiteService from "../services/extendedSuiteService";

function getServiceEndpointForExtendedSuiteUpdate(suiteType) {
  switch (suiteType) {
    case suiteConstants.suiteTypes.RESIDENTIAL:
      return ExtendedSuiteService.updateResidentialSuite;

    case suiteConstants.suiteTypes.INDUSTRIAL:
      return ExtendedSuiteService.updateIndustrialSuite;

    default:
      return null;
  }
}

function* updateExtendedSuiteInStackingPlan(action) {
  const { onSuccess } = action.payload;
  const endpoint = getServiceEndpointForExtendedSuiteUpdate(
    action.payload.suiteType
  );
  if (endpoint) {
    try {
      const result = yield call(endpoint, action.payload);
      yield put({
        type: suiteConstants.SP_UPDATE_EXTENDED_SUITE_SUCCESSFUL,
        payload: result
      });
      yield put({
        type: stackingPlanConstants.FETCH_STACKING_PLAN,
        payload: {
          buildingId: action.payload.buildingId
        }
      });
      yield put({
        type: suiteConstants.FETCH_STACKING_SUITE_DETAILS,
        payload: {
          suiteId: action.payload.suiteId
        }
      });

      !!onSuccess && onSuccess();
    } catch (error) {
      yield put({
        type: suiteConstants.SP_UPDATE_EXTENDED_SUITE_FAILED,
        error
      });
    }
  }
}

export function* updateExtendedSuiteInStackingPlanWatcher() {
  yield takeEvery(
    suiteConstants.SP_UPDATE_EXTENDED_SUITE,
    updateExtendedSuiteInStackingPlan
  );
}
