import * as authConstants from "./constants";

export function verifyToken(payload) {
  return {
    type: authConstants.VERIFY_TOKEN,
    payload
  };
}

export function refreshToken(payload) {
  return {
    type: authConstants.REFRESH_TOKEN,
    payload
  };
}

export function authenticateUser(payload) {
  return {
    type: authConstants.AUTHENTICATE_USER,
    payload
  };
}

export function redirectOktaUser(payload) {
  return {
    type: authConstants.OKTA_REDIRECT,
    payload
  };
}

export function authenticateOktaUser(payload) {
  return {
    type: authConstants.AUTHENTICATE_OKTA_USER,
    payload
  };
}

export function authenticateRefreshOktaUser(payload) {
  return {
    type: authConstants.REFRESH_AUTHENTICATE_OKTA_USER,
    payload
  };
}

export function logout(payload) {
  return {
    type: authConstants.LOGOUT_USER,
    payload
  };
}
