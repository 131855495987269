export const FETCH_BUILDING_CONTACTS =
  "buildingcontact/FETCH_BUILDING_CONTACTS";
export const FETCH_BUILDING_CONTACTS_SUCCESSFUL =
  "buildingcontact/FETCH_BUILDING_CONTACTS_SUCCESSFUL";
export const FETCH_BUILDING_CONTACTS_FAILED =
  "buildingcontact/FETCH_BUILDING_CONTACTS_FAILED";

export const UPDATE_BUILDING_CONTACT =
  "buildingcontact/UPDATE_BUILDING_CONTACT";
export const UPDATE_BUILDING_CONTACT_SUCCESSFUL =
  "buildingcontact/UPDATE_BUILDING_CONTACT_SUCCESSFUL";
export const UPDATE_BUILDING_CONTACT_FAILED =
  "buildingcontact/UPDATE_BUILDING_CONTACT_FAILED";

export const DELETE_BUILDING_CONTACT =
  "buildingcontact/DELETE_BUILDING_CONTACT";
export const DELETE_BUILDING_CONTACT_SUCCESSFUL =
  "buildingcontact/DELETE_BUILDING_CONTACT_SUCCESSFUL";
export const DELETE_BUILDING_CONTACT_FAILED =
  "buildingcontact/DELETE_BUILDING_CONTACT_FAILED";

export const FETCH_ALL_CONTACTS = "buildingcontact/FETCH_ALL_CONTACTS";
export const FETCH_ALL_CONTACTS_SUCCESSFUL =
  "buildingcontact/FETCH_ALL_CONTACTS_SUCCESSFUL";
export const FETCH_ALL_CONTACTS_FAILED =
  "buildingcontact/FETCH_ALL_CONTACTS_FAILED";

export const ADD_BUILDING_CONTACTS =
  "buildingcontact/ADD_BUILDING_CONTACTS";
export const ADD_BUILDING_CONTACTS_SUCCESSFUL =
  "buildingcontact/ADD_BUILDING_CONTACTS_SUCCESSFUL";
export const ADD_BUILDING_CONTACTS_FAILED =
  "buildingcontact/ADD_BUILDING_CONTACTS_FAILED";
export const UPDATE_CONTACT_ORDER = "buildingcontact/UPDATE_CONTACT_ORDER"
export const UPDATE_CONTACT_ORDER_SUCCESS = "buildingcontact/UPDATE_CONTACT_ORDER_SUCCESS"
export const UPDATE_CONTACT_ORDER_FAILED = "buildingcontact/UPDATE_CONTACT_ORDER_FAILED"

export const ADD_EXTERNAL_CONTACT =
  "companycontacts/ADD_EXTERNAL_CONTACT";
export const ADD_EXTERNAL_CONTACT_SUCCESSFUL =
  "companycontacts/ADD_EXTERNAL_CONTACT_SUCCESSFUL";
export const ADD_EXTERNAL_CONTACT_FAILED =
  "companycontacts/ADD_EXTERNAL_CONTACT_FAILED";

export const GET_EXTERNAL_CONTACT =
  "companycontacts/GET_EXTERNAL_CONTACT";
export const GET_EXTERNAL_CONTACT_SUCCESSFUL =
  "companycontacts/GET_EXTERNAL_CONTACT_SUCCESSFUL";
export const GET_EXTERNAL_CONTACT_FAILED =
  "companycontacts/GET_EXTERNAL_CONTACT_FAILED";

export const CONTACT_TYPE = {
  LEASING: {
    value: "LEASING",
    short: "L"
  },
  PROPERTY: {
    value: "PROPERTY",
    short: "P"
  }
};
