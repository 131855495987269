import React from "react";
import config from "./config";
import { randomBytes, createHash } from 'crypto'


export const convertLOVtoOptions = types => {
  return types.map((type, i) => ({ value: type.id, label: type.value }));
};

export const getPlaceholderImage = (dimensions, text) => {
  dimensions = dimensions || "100x70";
  return `${
    config.HTTPS_ENABLED === "true" ? "http" : "https"
  }://via.placeholder.com/${dimensions}/ddd/bbb?text=${text}`;
};

export const getNoImagePlaceholder = (
  text,
  returnOnlyFirstChar,
  defaultText = "A"
) => {
  const str = !!text
    ? returnOnlyFirstChar
      ? text.charAt(0).toUpperCase()
      : text
    : defaultText;
  return <div className="no-image-placeholder-container">{str}</div>;
};

export const getBuildingImageURI = (
  building,
  placeholderDimensions,
  placeholderText
) => {
  placeholderDimensions = placeholderDimensions || "100x70";
  placeholderText =
    placeholderText || (building.name ? building.name.substring(0, 1) : "A");

  return building.image
    ? building.image
    : getPlaceholderImage(placeholderDimensions, placeholderText);
};

export const isBuildingImageAvailable = building => {
  return !!building.image;
};

export const getGoogleAPIKeyValuePair = () => {
  return config.GOOGLE_MAPS_KEY_TYPE === "api"
    ? { keyName: "key", value: config.GOOGLE_MAPS_API_KEY }
    : { keyName: "client", value: config.GOOGLE_MAPS_CLIENT_ID };
};

export const intlNumber = (intl, value) => {
  return value === null || value === ""
    ? value
    : intl.formatNumber(value, { minimumFractionDigits: 2 });
};

export const decimalFeetStringToInches = decimalFeetString => {
  /*
    Changes a string representing feet (decimal) to inches
    e.g. "5" -> 6 in.
  */
  const decimalFeetNumber = parseInt(decimalFeetString);
  return Math.round(
    (decimalFeetNumber / Math.pow(10, decimalFeetString.length)) * 12
  );
};

export const inchesToFeet = inches => {
  /*
    Changes a number representing inches to feet
    e.g. 6 in. -> 5
  */
  if (inches < 0) throw "Inches must be positive";
  return inches / 12;
};


export const generateRandomString = function (length, randomString="") {
  randomString += Math.random().toString(20).substr(2, length);
  if (randomString.length > length) return randomString.slice(0, length);
  return generateRandomString(length, randomString);
};

const sha256 = (buffer) => {
  return createHash('sha256').update(buffer).digest()
}

export const base64URLEncode = (str) => {
  return str
    .toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '')
}

export const pkceChallengeFromVerifier = async (v) => {
  const hashed = await sha256(Buffer.from(v));
  return base64URLEncode(hashed);
}

export const generateUrlEncodedQuery = (query) => {
  return Object.keys(query)
    .map(
      (k) =>
        encodeURIComponent(k) + "=" + encodeURIComponent(query[k])
    )
    .join("&");
};

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch {
  }
};

export const pendo = (clientName, clientId, subId) => {
  (function (p, e, n, d, o) {
    var v, w, x, y, z;
    o = p[d] = p[d] || {};
    o._q = o._q || [];

    v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
    for (w = 0, x = v.length; w < x; ++w)
      (function (m) {
        o[m] =
          o[m] ||
          function () {
            o._q[m === v[0] ? "unshift" : "push"](
              [m].concat([].slice.call(arguments, 0))
            );
          };
      })(v[w]);

    y = e.createElement(n);
    y.async = !0;
    y.src =
      "https://cdn.pendo.io/agent/static/" +
      config.PENDO_KEY +
      "/pendo.js";

    z = e.getElementsByTagName(n)[0];
    z.parentNode.insertBefore(y, z);
  })(window, document, "script", "pendo");

  // Call this whenever information about your visitors becomes available
  // Please use Strings, Numbers, or Bools for value types.
  window.pendo.initialize({
    visitor: {
      id: subId, // *Mandatory, and must be unique across MRI.UseOkta ID
    },

    account: {
      id: clientId, // Use the MRI Client Account ID here (as per Salesforce)
      name: clientName // The client's name
    },
  });
}
