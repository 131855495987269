import * as networkConstants from "../constants/networkConstants";

export function checkConnectivity(payload) {
  return {
    type: networkConstants.CHECK_CONNECTIVITY,
    payload
  };
}

export function connectionDown(payload) {
  return {
    type: networkConstants.CONNECTION_DOWN,
    payload
  };
}

export function connectionUp(payload) {
  return {
    type: networkConstants.CONNECTION_UP,
    payload
  };
}
