import { takeEvery, call, put } from "redux-saga/effects";

import * as suiteConstants from "../constants/suiteConstants";
import * as stackingPlanConstants from "../constants/stackingPlanConstants";

import SuiteService from "../services/suiteService";

function* fetchSuites(action) {
  try {
    const suitesPage = yield call(SuiteService.fetchSuites, action.payload);
    yield put({
      type: suiteConstants.FETCH_SUITES_SUCCESSFUL,
      payload: suitesPage
    });
  } catch (error) {
    yield put({ type: suiteConstants.FETCH_SUITES_FAILED, error });
  }
}

function* updateSuite(action) {
  const { onSuccess } = action.payload;

  try {
    const result = yield call(SuiteService.updateSuite, action.payload);
    yield put({
      type: suiteConstants.UPDATE_SUITE_SUCCESSFUL,
      payload: result
    });

    yield put({
      type: stackingPlanConstants.FETCH_STACKING_PLAN,
      payload: {
        buildingId: action.payload.buildingId
      }
    });
    yield put({
      type: suiteConstants.FETCH_STACKING_SUITE_DETAILS,
      payload: {
        suiteId: action.payload.suiteId
      }
    });
    yield put({
      type: stackingPlanConstants.FETCH_STACKING_PLAN_FILTERPROFILE,
      payload: {
        buildingId: action.payload.buildingId,
        filtertype: stackingPlanConstants.filterProfileTypes.LEASE_EXPIRY
      }
    });

    !!onSuccess && onSuccess();
  } catch (error) {
    yield put({ type: suiteConstants.UPDATE_SUITE_FAILED, error });
  }
}

function* deleteSuite(action) {
  try {
    const result = yield call(SuiteService.deleteSuite, action.payload);
    yield put({
      type: suiteConstants.DELETE_SUITE_SUCCESSFUL,
      payload: result
    });
    yield put({
      type: stackingPlanConstants.FETCH_STACKING_PLAN,
      payload: {
        buildingId: action.payload.buildingId
      }
    });
    yield put({
      type: stackingPlanConstants.FETCH_STACKING_PLAN_FILTERPROFILE,
      payload: {
        buildingId: action.payload.buildingId,
        filtertype: stackingPlanConstants.filterProfileTypes.LEASE_EXPIRY
      }
    });
  } catch (error) {
    yield put({ type: suiteConstants.DELETE_SUITE_FAILED, error });
  }
}

function* splitSuite(action) {
  try {
    const result = yield call(SuiteService.splitSuite, action.payload);
    yield put({ type: suiteConstants.SPLIT_SUITE_SUCCESSFUL, payload: result });
    yield put({
      type: stackingPlanConstants.FETCH_STACKING_PLAN,
      payload: {
        buildingId: action.payload.buildingId
      }
    });
    yield put({
      type: stackingPlanConstants.FETCH_STACKING_PLAN_FILTERPROFILE,
      payload: {
        buildingId: action.payload.buildingId,
        filtertype: stackingPlanConstants.filterProfileTypes.LEASE_EXPIRY
      }
    });
  } catch (error) {
    yield put({ type: suiteConstants.SPLIT_SUITE_FAILED, error });
  }
}

function* addSuiteLeft(action) {
  try {
    const result = yield call(SuiteService.addSuiteLeft, action.payload);
    yield put({
      type: suiteConstants.ADD_SUITE_LEFT_SUCCESSFUL,
      payload: result
    });
    yield put({
      type: stackingPlanConstants.FETCH_STACKING_PLAN,
      payload: {
        buildingId: action.payload.buildingId
      }
    });
    yield put({
      type: stackingPlanConstants.FETCH_STACKING_PLAN_FILTERPROFILE,
      payload: {
        buildingId: action.payload.buildingId,
        filtertype: stackingPlanConstants.filterProfileTypes.LEASE_EXPIRY
      }
    });
  } catch (error) {
    yield put({ type: suiteConstants.ADD_SUITE_LEFT_FAILED, error });
  }
}

function* addSuiteRight(action) {
  try {
    const result = yield call(SuiteService.addSuiteRight, action.payload);
    yield put({
      type: suiteConstants.ADD_SUITE_RIGHT_SUCCESSFUL,
      payload: result
    });
    yield put({
      type: stackingPlanConstants.FETCH_STACKING_PLAN,
      payload: {
        buildingId: action.payload.buildingId
      }
    });
    yield put({
      type: stackingPlanConstants.FETCH_STACKING_PLAN_FILTERPROFILE,
      payload: {
        buildingId: action.payload.buildingId,
        filtertype: stackingPlanConstants.filterProfileTypes.LEASE_EXPIRY
      }
    });
  } catch (error) {
    yield put({ type: suiteConstants.ADD_SUITE_RIGHT_FAILED, error });
  }
}

function* addNewSuite(action) {
  try {
    const result = yield call(SuiteService.addNewSuite, action.payload);
    yield put({
      type: suiteConstants.ADD_NEW_SUITE_SUCCESSFUL,
      payload: result
    });
    yield put({
      type: stackingPlanConstants.FETCH_STACKING_PLAN,
      payload: {
        buildingId: action.payload.buildingId
      }
    });
    yield put({
      type: stackingPlanConstants.FETCH_STACKING_PLAN_FILTERPROFILE,
      payload: {
        buildingId: action.payload.buildingId,
        filtertype: stackingPlanConstants.filterProfileTypes.LEASE_EXPIRY
      }
    });
  } catch (error) {
    yield put({ type: suiteConstants.ADD_NEW_SUITE_FAILED, error });
  }
}

function* fetchSuitesFilterItems(action) {
  try {
    const result = yield call(SuiteService.fetchSuitesFilterItems);
    yield put({
      type: suiteConstants.FETCH_SUITES_FILTER_ITEMS_SUCCESSFUL,
      payload: result
    });
  } catch (error) {
    yield put({ type: suiteConstants.FETCH_SUITES_FILTER_ITEMS_FAILED, error });
  }
}

function* fetchStackingSuiteDetails(action) {
  try {
    const result = yield call(
      SuiteService.fetchStackingSuiteDetails,
      action.payload
    );
    yield put({
      type: suiteConstants.FETCH_STACKING_SUITE_DETAILS_SUCCESSFUL,
      payload: result
    });
  } catch (error) {
    yield put({
      type: suiteConstants.FETCH_STACKING_SUITE_DETAILS_FAILED,
      error
    });
  }
}

export function* fetchSuitesWatcher() {
  yield takeEvery(suiteConstants.FETCH_SUITES, fetchSuites);
}

export function* updateSuiteWatcher() {
  yield takeEvery(suiteConstants.UPDATE_SUITE, updateSuite);
}

export function* deleteSuiteWatcher() {
  yield takeEvery(suiteConstants.DELETE_SUITE, deleteSuite);
}

export function* splitSuiteWatcher() {
  yield takeEvery(suiteConstants.SPLIT_SUITE, splitSuite);
}

export function* addSuiteLeftWatcher() {
  yield takeEvery(suiteConstants.ADD_SUITE_LEFT, addSuiteLeft);
}

export function* addSuiteRightWatcher() {
  yield takeEvery(suiteConstants.ADD_SUITE_RIGHT, addSuiteRight);
}

export function* addNewSuiteWatcher() {
  yield takeEvery(suiteConstants.ADD_NEW_SUITE, addNewSuite);
}

export function* fetchSuitesFilterItemsWatcher() {
  yield takeEvery(
    suiteConstants.FETCH_SUITES_FILTER_ITEMS,
    fetchSuitesFilterItems
  );
}

export function* fetchStackingSuiteDetailsWatcher() {
  yield takeEvery(
    suiteConstants.FETCH_STACKING_SUITE_DETAILS,
    fetchStackingSuiteDetails
  );
}
