import http from "../auth/http";

const UserService = () => {
  const fetchUser = userId =>
    http
      .get(`api/v1/users/${userId}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  
  const fetchOktaAuthUser = (companyId) => {

    let url = "api/v1/users/me/";
    if (companyId) {
      url = `${url}?companyId=${companyId}`
    }
    return http.get(url)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data))
  }

  const fetchUsers = ({ page, ordering, search }) => {
    page = page || 1;

    let url = `api/v1/users/?page=${page}`;
    url = ordering ? url.concat(`&ordering=${ordering}`) : url;
    url = search ? url.concat(`&search=${search}`) : url;

    return http
      .get(url)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const deleteUser = userId =>
    http
      .delete(`api/v1/users/${userId}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data));

  const editUser = (userId, data) =>
    http
      .patch(`api/v1/users/${userId}/`, data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data));

  const createUser = data =>
    http
      .post("api/v1/users/", data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data));

  const fetchUserBuildings = request =>
    http
      .get(
        `api/v1/users/${request.userId}/getbuildingassignments/?page=${
          request.page
        }`
      )
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data));

  const deleteUserBuilding = (userId, buildingId) =>
    http
      .delete(`api/v1/users/${userId}/removebuilding/${buildingId}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data));

  const editUserRole = request =>
    http
      .patch(`api/v1/users/${request.userId}/setuserrole/`, request.payload)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data));

  const transferBuildingAssignments = (from, to) =>
    http
      .post(`api/v1/users/${from}/transferbuildingasssignments/${to}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data));

  const copyBuildingAssignments = (from, to) =>
    http
      .post(`api/v1/users/${from}/copybuildingassignments/${to}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data));

  const addUserBuildings = (userId, request) =>
    http
      .post(`api/v1/users/${userId}/addbuildings/`, request)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data));

  return {
    fetchUser,
    fetchUsers,
    deleteUser,
    editUser,
    createUser,
    fetchUserBuildings,
    deleteUserBuilding,
    editUserRole,
    transferBuildingAssignments,
    copyBuildingAssignments,
    addUserBuildings,
    fetchOktaAuthUser
  };
};

const api = UserService();
export default api;
