export const REORDER_SUITES = "floor/REORDER_SUITES";
export const REORDER_SUITES_SUCCESSFUL = "floor/REORDER_SUITES_SUCCESSFUL";
export const REORDER_SUITES_FAILED = "floor/REORDER_SUITES_FAILED";

export const ADD_FLOOR_ABOVE = "floor/ADD_FLOOR_ABOVE";
export const ADD_FLOOR_ABOVE_SUCCESSFUL = "floor/ADD_FLOOR_ABOVE_SUCCESSFUL";
export const ADD_FLOOR_ABOVE_FAILED = "floor/ADD_FLOOR_ABOVE_FAILED";

export const ADD_FLOOR_BELOW = "floor/ADD_FLOOR_BELOW";
export const ADD_FLOOR_BELOW_SUCCESSFUL = "floor/ADD_FLOOR_BELOW_SUCCESSFUL";
export const ADD_FLOOR_BELOW_FAILED = "floor/ADD_FLOOR_BELOW_FAILED";

export const DELETE_FLOOR = "floor/DELETE_FLOOR";
export const DELETE_FLOOR_SUCCESSFUL = "floor/DELETE_FLOOR_SUCCESSFUL";
export const DELETE_FLOOR_FAILED = "floor/DELETE_FLOOR_FAILED";

export const UPDATE_FLOOR = "floor/UPDATE_FLOOR";
export const UPDATE_FLOOR_SUCCESSFUL = "floor/UPDATE_FLOOR_SUCCESSFUL";
export const UPDATE_FLOOR_FAILED = "floor/UPDATE_FLOOR_FAILED";

export const UPDATE_FLOORPLAN_ASSET = "floor/UPDATE_FLOORPLAN_ASSET";
export const UPDATE_FLOORPLAN_ASSET_SUCCESSFUL =
  "floor/UPDATE_FLOORPLAN_ASSET_SUCCESSFUL";
export const UPDATE_FLOORPLAN_ASSET_FAILED =
  "floor/UPDATE_FLOORPLAN_ASSET_FAILED";

export const CREATE_FLOORPLAN_ASSET = "floor/CREATE_FLOORPLAN_ASSET";
export const CREATE_FLOORPLAN_ASSET_SUCCESSFUL =
  "floor/CREATE_FLOORPLAN_ASSET_SUCCESSFUL";
export const CREATE_FLOORPLAN_ASSET_FAILED =
  "floor/CREATE_FLOORPLAN_ASSET_FAILED";

export const FETCH_FLOOR = "floor/FETCH_FLOOR";
export const FETCH_FLOOR_SUCCESSFUL = "floor/FETCH_FLOOR_SUCCESSFUL";
export const FETCH_FLOOR_FAILED = "floor/FETCH_FLOOR_FAILED";

export const FETCH_FLOORPLAN = "floor/FETCH_FLOORPLAN";
export const FETCH_FLOORPLAN_SUCCESSFUL = "floor/FETCH_FLOORPLAN_SUCCESSFUL";
export const FETCH_FLOORPLAN_FAILED = "floor/FETCH_FLOORPLAN_FAILED";

export const CLEAR_FLOORPLAN = "floor/CLEAR_FLOORPLAN";

export const UPDATE_SVG_TEXT = "floor/UPDATE_SVG_TEXT";
export const UPDATE_IMAGE_URL = "floor/UPDATE_IMAGE_URL";
