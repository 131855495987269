import axios from "axios";
import config from "../config";

const http = axios.create({
  baseURL: config.API_SERVER_BASE_URI
});

http.interceptors.request.use(
  function (req) {
    if(req.url.includes('token/') || req.url.includes('users/') || req.url.includes('ping/')) {
      return req;
    }

    // Load Alt Company from localstorage State
    const state = JSON.parse(localStorage.getItem('state'));
    const altCompany = state.user.altCompany;
    if (!altCompany) return req;

    req.url += req.url[req.url.length-1] === '/' ? `?company_id=${altCompany.id}` : `&company_id=${altCompany.id}`;

    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export const noAuthHttp = axios.create({
  baseURL: config.API_SERVER_BASE_URI
});

export const noBaseHttp = axios.create();

export default http;
