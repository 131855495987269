import http from "../auth/http";

const LeaseService = () => {
  const fetchLeases = request => {
    var {
      page,
      suiteFloorBuilding,
      start_date__gte,
      end_date__lte,
      ordering,
      suiteId
    } = request;

    page = page || 1;

    let url = `/api/v1/leases/?page=${page}&suite_set__floor__building=${suiteFloorBuilding}`;
    url = !!start_date__gte
      ? url.concat(`&start_date__gte=${start_date__gte}`)
      : url;
    url = !!end_date__lte ? url.concat(`&end_date__lte=${end_date__lte}`) : url;
    url = !!ordering ? url.concat(`&ordering=${ordering}`) : url;
    url = !!suiteId ? url.concat(`&suite_set__id=${suiteId}`) : url; // old key: lease_suite_set_rel__id

    return http
      .get(url)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const updateLease = request => {
    return http
      .patch(`/api/v1/leases/${request.id}/`, request.payload)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const createLease = lease =>
    http
      .post(`/api/v1/leases/`, lease)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const deleteLease = leaseId =>
    http
      .delete(`/api/v1/leases/${leaseId}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  return {
    fetchLeases,
    updateLease,
    createLease,
    deleteLease
  };
};

const api = LeaseService();
export default api;
