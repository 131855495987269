export const FETCH_SUITE_MEDIA = "suiteMedia/FETCH_SUITE_MEDIA";
export const FETCH_SUITE_MEDIA_SUCCESSFUL =
  "suiteMedia/FETCH_SUITE_MEDIA_SUCCESSFUL";
export const FETCH_SUITE_MEDIA_FAILED = "suiteMedia/FETCH_SUITE_MEDIA_FAILED";

export const UPLOAD_SUITE_MEDIA = "suiteMedia/UPLOAD_SUITE_MEDIA";
export const UPLOAD_SUITE_MEDIA_SUCCESSFUL =
  "suiteMedia/UPLOAD_SUITE_MEDIA_SUCCESSFUL";
export const UPLOAD_SUITE_MEDIA_FAILED = "suiteMedia/UPLOAD_SUITE_MEDIA_FAILED";

export const SUITE_MEDIA_UPLOAD_PROGRESS =
  "suiteMedia/SUITE_MEDIA_UPLOAD_PROGRESS";
export const CLEAR_SUITE_MEDIA_UPLOAD_STATUS =
  "suiteMedia/CLEAR_SUITE_MEDIA_UPLOAD_STATUS";

export const DELETE_SUITE_MEDIA = "suiteMedia/DELETE_SUITE_MEDIA";
export const DELETE_SUITE_MEDIA_SUCCESSFUL =
  "suiteMedia/DELETE_SUITE_MEDIA_SUCCESSFUL";
export const DELETE_SUITE_MEDIA_FAILED = "suiteMedia/DELETE_SUITE_MEDIA_FAILED";

export const UPDATE_SUITE_MEDIA = "suiteMedia/UPDATE_SUITE_MEDIA";
export const UPDATE_SUITE_MEDIA_SUCCESSFUL =
  "suiteMedia/UPDATE_SUITE_MEDIA_SUCCESSFUL";
export const UPDATE_SUITE_MEDIA_FAILED = "suiteMedia/UPDATE_SUITE_MEDIA_FAILED";

export const SET_PRIMARY_PICTURE = "suiteMedia/SET_PRIMARY_PICTURE";
export const SET_PRIMARY_PICTURE_SUCCESSFUL =
  "suiteMedia/SET_PRIMARY_PICTURE_SUCCESSFUL";
export const SET_PRIMARY_PICTURE_FAILED =
  "suiteMedia/SET_PRIMARY_PICTURE_FAILED";

export const TOGGLE_PUBLISH_MEDIA_STATUS =
  "suiteMedia/TOGGLE_PUBLISH_MEDIA_STATUS";
export const TOGGLE_PUBLISH_MEDIA_STATUS_SUCCESSFUL =
  "suiteMedia/TOGGLE_PUBLISH_MEDIA_STATUS_SUCCESSFUL";
export const TOGGLE_PUBLISH_MEDIA_STATUS_FAILED =
  "suiteMedia/TOGGLE_PUBLISH_MEDIA_STATUS_FAILED";

export const REORDER_SUITE_MEDIA = "suiteMedia/REORDER_SUITE_MEDIA";
export const REORDER_SUITE_MEDIA_SUCCESSFUL =
  "suiteMedia/REORDER_SUITE_MEDIA_SUCCESSFUL";
export const REORDER_SUITE_MEDIA_FAILED =
  "suiteMedia/REORDER_SUITE_MEDIA_FAILED";
