import { takeEvery, call, put } from "redux-saga/effects";
import { startProgress, stopProgress } from "./sagaUtils";
import uuid from "uuid/v4";

import * as buildingInformationConstants from "../constants/buildingInformationConstants";
import BuildingInformationService from "../services/buildingInformationService";
import BuildingsService from "../services/buildingsService";

function getAddressStringFromBuilding(building) {
  return [
    building.address,
    building.province,
    building.sub_region,
    building.region,
    building.city,
    building.country
  ].join(",");
}

function getCoordinates(geolocation) {
  return geolocation.results &&
    geolocation.results.length &&
    geolocation.results[0].geometry &&
    geolocation.results[0].geometry.location
    ? geolocation.results[0].geometry.location
    : {
        lat: null,
        lng: null
      };
}

function* updateBuildingInBuildingInformation(action) {
  const progressId = uuid();
  yield put(startProgress(progressId));

  try {
    const result = yield call(BuildingsService.updateBuilding, action.payload);
    yield put({
      type: buildingInformationConstants.UPDATE_BUILDING_SUCCESSFUL,
      payload: result
    });
    yield put({
      type: buildingInformationConstants.FETCH_BUILDING_INFO_LIST,
      payload: {
        buildingId: action.payload.buildingId
      }
    });

    !!action.payload.onSuccess && action.payload.onSuccess();
  } catch (error) {
    yield put({
      type: buildingInformationConstants.UPDATE_BUILDING_FAILED,
      error
    });
  } finally {
    yield put(stopProgress(progressId));
  }
}

function* fetchBuildingInformation(action) {
  const progressId = uuid();
  yield put(startProgress(progressId));

  try {
    const buildingInformation = yield call(
      BuildingInformationService.fetchBuildingInformation,
      action.payload
    );
    // if(buildingInformation.building_info.building.latitude === null || buildingInformation.building_info.building.longitude === null){
    //   const geolocation = yield call(BuildingInformationService.getBuildingGeolocation, {
    //     address: getAddressStringFromBuilding(buildingInformation.building_info.building)
    //   });
    //   const location = getCoordinates(geolocation);
    //   buildingInformation.building_info.building.latitude = location.lat;
    //   buildingInformation.building_info.building.longitude = location.lng;
    // }
    yield put({
      type: buildingInformationConstants.FETCH_BUILDING_INFO_LIST_SUCCESSFUL,
      payload: buildingInformation
    });
  } catch (error) {
    yield put({
      type: buildingInformationConstants.FETCH_BUILDING_INFO_LIST_FAILED,
      error
    });
  } finally {
    yield put(stopProgress(progressId));
  }
}

function* createBuildingInformationItem(action) {
  const progressId = uuid();
  yield put(startProgress(progressId));

  try {
    const result = yield call(
      BuildingInformationService.createBuildingInformationItem,
      action.payload
    );
    yield put({
      type:
        buildingInformationConstants.CREATE_BUILDING_INFORMATION_ITEM_SUCCESSFUL,
      payload: result
    });
    yield put({
      type: buildingInformationConstants.FETCH_BUILDING_INFO_LIST,
      payload: {
        buildingId: action.payload.building
      }
    });
  } catch (error) {
    yield put({
      type:
        buildingInformationConstants.CREATE_BUILDING_INFORMATION_ITEM_FAILED,
      error
    });
  } finally {
    yield put(stopProgress(progressId));
  }
}

function* updateBuildingInformationItem(action) {
  const progressId = uuid();
  yield put(startProgress(progressId));

  try {
    const { id, buildingInformationItem, onSuccess } = action.payload;

    const result = yield call(
      BuildingInformationService.updateBuildingInformationItem,
      id,
      buildingInformationItem
    );
    yield put({
      type:
        buildingInformationConstants.UPDATE_BUILDING_INFORMATION_ITEM_SUCCESSFUL,
      payload: result
    });
    !!onSuccess && onSuccess();
  } catch (error) {
    yield put({
      type:
        buildingInformationConstants.UPDATE_BUILDING_INFORMATION_ITEM_FAILED,
      error
    });
  } finally {
    yield put(stopProgress(progressId));
  }
}

function* deleteBuildingInformationItem(action) {
  const { id, buildingId } = action.payload;
  const progressId = uuid();
  yield put(startProgress(progressId));

  try {
    yield call(BuildingInformationService.deleteBuildingInformationItem, id);
    yield put({
      type:
        buildingInformationConstants.DELETE_BUILDING_INFORMATION_ITEM_SUCCESSFUL
    });
    yield put({
      type: buildingInformationConstants.FETCH_BUILDING_INFO_LIST,
      payload: {
        buildingId: buildingId
      }
    });
  } catch (error) {
    yield put({
      type:
        buildingInformationConstants.DELETE_BUILDING_INFORMATION_ITEM_FAILED,
      error
    });
  } finally {
    yield put(stopProgress(progressId));
  }
}

function* fetchBuildingInformationMeta(action) {
  const progressId = uuid();
  yield put(startProgress(progressId));

  try {
    const result = yield call(
      BuildingInformationService.fetchBuildingInformationMeta,
      action.payload
    );
    if (result?.detail === "Not found.") {
      yield put({
        type: buildingInformationConstants.FETCH_BUILDING_INFO_META_FAILED,
        payload: result.detail
      });
    } else {
      yield put({
        type: buildingInformationConstants.FETCH_BUILDING_INFO_META_SUCCESSFUL,
        payload: result
      });
    }
  } catch (error) {
    yield put({
      type: buildingInformationConstants.FETCH_BUILDING_INFO_META_FAILED,
      error
    });
  } finally {
    yield put(stopProgress(progressId));
  }
}

function* reorderBuildingInformaionItems(action) {
  const progressId = uuid();
  yield put(startProgress(progressId));

  const { buildingId, data, onSuccess } = action.payload;

  try {
    const result = yield call(
      BuildingInformationService.reorderBuildingInformationItems,
      buildingId,
      data
    );
    yield put({
      type:
        buildingInformationConstants.REORDER_BUILDING_INFORMATION_ITEMS_SUCCESSFUL,
      payload: result
    });
    !!onSuccess && onSuccess();
  } catch (error) {
    yield put({
      type:
        buildingInformationConstants.REORDER_BUILDING_INFORMATION_ITEMS_FAILED,
      error
    });
  } finally {
    yield put(stopProgress(progressId));
  }
}

export function* updateBuildingInBuildingInformationWatcher() {
  yield takeEvery(
    buildingInformationConstants.UPDATE_BUILDING,
    updateBuildingInBuildingInformation
  );
}

export function* fetchBuildingInformationWatcher() {
  yield takeEvery(
    buildingInformationConstants.FETCH_BUILDING_INFO_LIST,
    fetchBuildingInformation
  );
}

export function* createBuildingInformationItemWatcher() {
  yield takeEvery(
    buildingInformationConstants.CREATE_BUILDING_INFORMATION_ITEM,
    createBuildingInformationItem
  );
}

export function* updateBuildingInformationItemWatcher() {
  yield takeEvery(
    buildingInformationConstants.UPDATE_BUILDING_INFORMATION_ITEM,
    updateBuildingInformationItem
  );
}

export function* deleteBuildingInformationItemWatcher() {
  yield takeEvery(
    buildingInformationConstants.DELETE_BUILDING_INFORMATION_ITEM,
    deleteBuildingInformationItem
  );
}

export function* fetchBuildingInformationMetaWatcher() {
  yield takeEvery(
    buildingInformationConstants.FETCH_BUILDING_INFO_META,
    fetchBuildingInformationMeta
  );
}

export function* reorderBuildingInformaionItemsWatcher() {
  yield takeEvery(
    buildingInformationConstants.REORDER_BUILDING_INFORMATION_ITEMS,
    reorderBuildingInformaionItems
  );
}
