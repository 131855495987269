import React from "react";
import { Route, Redirect } from "react-router-dom";

import AuthService from "../../../services/authService";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      AuthService.hasToken() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            search: "?next=" + props.location.pathname
          }}
        />
      )
    }
  />
);

export default PrivateRoute;
