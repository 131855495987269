import http from "../auth/http";

const ExtendedSuiteService = () => {
  const updateResidentialSuite = request =>
    http
      .patch(
        `/api/v1/extendedsuite/${request.suiteId}/residentialsuite/`,
        request.extendedSuite
      )
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const updateIndustrialSuite = request =>
    http
      .patch(
        `/api/v1/extendedsuite/${request.suiteId}/industrialsuite/`,
        request.extendedSuite
      )
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  return {
    updateResidentialSuite,
    updateIndustrialSuite
  };
};

const api = ExtendedSuiteService();
export default api;
