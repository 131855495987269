import http from "../auth/http";

const SuitesVirtualTourService = () => {
  /**
   *  Suites Virtual Tour Service consumes API from CoRE
   *  Basic CRUD
   */
  const createTour = request =>
    http
      .post(`api/v1/suitetours/`, request)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const fetchTours = request =>
    http
      .get(`api/v1/suitetours/suite/${request.id}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const updateTour = request =>
    http
      .patch(`api/v1/suitetours/${request.id}/`, request.tour)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const deleteTour = request =>
    http
      .delete(`api/v1/suitetours/${request.id}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  return {
    createTour,
    fetchTours,
    updateTour,
    deleteTour
  };
};

const api = SuitesVirtualTourService();
export default api;
