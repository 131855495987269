import http from "../auth/http";

const RentRollService = () => {
  const fetchRentRoll = request => {
    const { buildingId, filters } = request;

    let url = `/api/v1/buildings/${buildingId}/rentroll/?`;

    if (!!filters && !!filters.pubStatus && filters.pubStatus.length) {
      url = url.concat(
        "&published=" +
          filters.pubStatus
            .map(status => {
              return status === "P" ? "true" : "false";
            })
            .join(",")
      );
    }

    // Area range filters
    !!filters &&
      !!filters.area_max &&
      (url = url.concat("&area__lte=" + filters.area_max));
    !!filters &&
      !!filters.area_min &&
      (url = url.concat("&area__gte=" + filters.area_min));

    return http
      .get(url)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  return {
    fetchRentRoll
  };
};

const api = RentRollService();
export default api;
