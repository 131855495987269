import { takeEvery, call, put } from "redux-saga/effects";

import * as buildingReportsConstants from "../constants/buildingReportsConstants";
import BuildingReportsService from "../services/buildingReportsService";

function* fetchOccupancyReport(action) {
  try {
    const result = yield call(
      BuildingReportsService.fetchOccupancyReport,
      action.payload
    );
    if (result?.detail === "Not found.") {
      yield put({
        type: buildingReportsConstants.FETCH_OCCUPANCY_REPORT_FAILED,
        payload: result.detail
      });
    } else {
      yield put({
        type: buildingReportsConstants.FETCH_OCCUPANCY_REPORT_SUCCESSFUL,
        payload: result
      });
    }
  } catch (error) {
    yield put({
      type: buildingReportsConstants.FETCH_OCCUPANCY_REPORT_FAILED,
      error
    });
  }
}

function* fetchDistributionReport(action) {
  try {
    const result = yield call(
      BuildingReportsService.fetchDistributionReport,
      action.payload
    );
    yield put({
      type: buildingReportsConstants.FETCH_DISTRIBUTION_REPORT_SUCCESSFUL,
      payload: result
    });
  } catch (error) {
    yield put({
      type: buildingReportsConstants.FETCH_DISTRIBUTION_REPORT_FAILED,
      error
    });
  }
}

function* fetchLeaseExpiryProfileReport(action) {
  try {
    const result = yield call(
      BuildingReportsService.fetchLeaseExpiryProfileReport,
      action.payload
    );
    yield put({
      type:
        buildingReportsConstants.FETCH_LEASE_EXPIRY_PROFILE_REPORT_SUCCESSFUL,
      payload: result
    });
  } catch (error) {
    yield put({
      type: buildingReportsConstants.FETCH_LEASE_EXPIRY_PROFILE_REPORT_FAILED,
      error
    });
  }
}

function* fetchLargestTenantReport(action) {
  try {
    const result = yield call(
      BuildingReportsService.fetchLargestTenantReport,
      action.payload
    );
    yield put({
      type: buildingReportsConstants.FETCH_LARGEST_TENANT_REPORT_SUCCESSFUL,
      payload: result
    });
  } catch (error) {
    yield put({
      type: buildingReportsConstants.FETCH_LARGEST_TENANT_REPORT_FAILED,
      error
    });
  }
}

function* fetchVacantSuitesReport(action) {
  try {
    const result = yield call(
      BuildingReportsService.fetchVacantSuitesReport,
      action.payload
    );
    yield put({
      type: buildingReportsConstants.FETCH_VACANT_SUITES_REPORT_SUCCESSFUL,
      payload: result
    });
  } catch (error) {
    yield put({
      type: buildingReportsConstants.FETCH_VACANT_SUITES_REPORT_FAILED,
      error
    });
  }
}

export function* fetchOccupancyReportWatcher() {
  yield takeEvery(
    buildingReportsConstants.FETCH_OCCUPANCY_REPORT,
    fetchOccupancyReport
  );
}

export function* fetchDistributionReportWatcher() {
  yield takeEvery(
    buildingReportsConstants.FETCH_DISTRIBUTION_REPORT,
    fetchDistributionReport
  );
}

export function* fetchLeaseExpiryProfileReportWatcher() {
  yield takeEvery(
    buildingReportsConstants.FETCH_LEASE_EXPIRY_PROFILE_REPORT,
    fetchLeaseExpiryProfileReport
  );
}

export function* fetchLargestTenantReportWatcher() {
  yield takeEvery(
    buildingReportsConstants.FETCH_LARGEST_TENANT_REPORT,
    fetchLargestTenantReport
  );
}

export function* fetchVacantSuitesReportWatcher() {
  yield takeEvery(
    buildingReportsConstants.FETCH_VACANT_SUITES_REPORT,
    fetchVacantSuitesReport
  );
}
