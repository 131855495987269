import * as appConstants from "../constants/appConstants";

export function enablePrintMode(payload) {
  return {
    type: appConstants.ENABLE_PRINT_MODE,
    payload
  };
}

export function startProgress(progressId) {
  return {
    type: appConstants.START_ASYNC_ACTION,
    progressId
  };
}

export function stopProgress(progressId) {
  return {
    type: appConstants.STOP_ASYNC_ACTION,
    progressId
  };
}

export function fetchSystemSettings() {
  return {
    type: appConstants.SYSTEM_SETTINGS
  };
}
