import { takeEvery, call, put } from "redux-saga/effects";

import * as buildingsConstants from "../constants/buildings";

import ExtendedBuildingService from "../services/extendedBuildingService";

function* fetchExtendedBuilding(action) {
  try {
    const result = yield call(
      ExtendedBuildingService.fetchExtendedBuilding,
      action.payload
    );
    yield put({
      type: buildingsConstants.FETCH_EXTENDED_BUILDING_SUCCESSFUL,
      payload: result
    });
  } catch (error) {
    yield put({
      type: buildingsConstants.FETCH_EXTENDED_BUILDING_FAILED,
      error
    });
  }
}

function* updateExtendedBuilding(action) {
  const { buildingId, building, onSuccess } = action.payload;

  try {
    const result = yield call(
      ExtendedBuildingService.updateExtendedBuilding,
      buildingId,
      building
    );
    yield put({
      type: buildingsConstants.UPDATE_EXTENDED_BUILDING_SUCCESSFUL,
      payload: result
    });

    !!onSuccess && onSuccess();
  } catch (error) {
    yield put({
      type: buildingsConstants.UPDATE_EXTENDED_BUILDING_FAILED,
      error
    });
  }
}

export function* calculateAdditionalRent(action) {
  const { buildingId, onSuccess } = action.payload;

  try {
    const result = yield call(
      ExtendedBuildingService.calculateAdditionalRent,
      buildingId
    );
    yield put({
      type: buildingsConstants.CALCULATE_ADDITIONAL_RENT_SUCCESSFUL,
      payload: result
    });

    !!onSuccess && onSuccess(result);
  } catch (error) {
    yield put({
      type: buildingsConstants.CALCULATE_ADDITIONAL_RENT_FAILED,
      error 
    });
  }
}

function* autoComputeValues(action) {
  const { buildingId, onSuccess } = action.payload;

  try {
    const result = yield call(
      ExtendedBuildingService.autoComputeValues,
      buildingId
    );
    yield put({
      type: buildingsConstants.AUTO_COMPUTE_VALUES_SUCCESSFUL,
      payload: result
    });

    !!onSuccess && onSuccess(result);
  } catch (error) {
    yield put({
      type:
      buildingsConstants.AUTO_COMPUTE_VALUES_FAILED,
      error
    });
  }
}

export function* fetchExtendedBuildingWatcher() {
  yield takeEvery(
    buildingsConstants.FETCH_EXTENDED_BUILDING,
    fetchExtendedBuilding
  );
}

export function* updateExtendedBuildingWatcher() {
  yield takeEvery(
    buildingsConstants.UPDATE_EXTENDED_BUILDING,
    updateExtendedBuilding
  );
}

export function* autoComputeValuesWatcher() {
  yield takeEvery(
    buildingsConstants.AUTO_COMPUTE_VALUES,
    autoComputeValues
  );
}

export function* calculateAdditionalRentWatcher() {
  yield takeEvery(
    buildingsConstants.CALCULATE_ADDITIONAL_RENT,
    calculateAdditionalRent
  );
}
