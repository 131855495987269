import * as suiteConstants from "../constants/suiteConstants";
import * as lovConstants from "../constants/lovConstants";

import update from "immutability-helper";

const initialState = {
  fetching: false,
  suitesPage: {
    area__max: null,
    area__min: null,
    results: [],
    meta: {
      available_suites: 0,
      marketed_area: 0,
      marketed_suites: 0,
      not_marketed_area: 0,
      not_marketed_suites: 0,
      total_rentable_area: 0,
      published_suite_area: null,
      published_suites: null,
      total_suite_area: null,
      total_suites: null,
      non_published_suite_area: null,
      non_published_suites: null
    }
  },

  filters: {
    cities: [],
    suiteTypes: [],
    area_max: null,
    area_min: null,
    contacts: []
  },
  selectedFilters: {
    cities: [],
    suiteTypes: [],
    area_max: null,
    area_min: null,
    contacts: []
  },
  staging: {
    cities: [],
    suiteTypes: [],
    area_max: null,
    area_min: null,
    contacts: []
  },
  isFiltersApplied: false,

  stackingPlanSuiteDetails: {}
};

// TODO: Use lov store for fetching suiteTypes here

const reducer = function suitesReducer(state = initialState, action) {
  switch (action.type) {
    case suiteConstants.FETCH_SUITES:
      return Object.assign({}, state, {
        fetching: true
      });

    case suiteConstants.FETCH_SUITES_SUCCESSFUL:
      return Object.assign({}, state, {
        fetching: false,
        suitesPage: action.payload
      });

    case suiteConstants.FETCH_SUITES_FAILED:
      return Object.assign({}, state, {
        fetching: false
      });

    case suiteConstants.FETCH_SUITES_FILTER_ITEMS_SUCCESSFUL: {
      let cities = action.payload.city_counts.map(city => {
        return {
          count: city.count,
          id: city.floor__building__city,
          label: city.floor__building__city
        };
      });

      let contacts = action.payload.leasing_contacts.map(contact => {
        return {
          count: contact.count,
          id: `${contact.first_name} ${contact.last_name}`,
          label: `${contact.first_name} ${contact.last_name}`
        };
      });

      let selectedCities =
        !!state.selectedFilters.cities.length || state.isFiltersApplied
          ? state.selectedFilters.cities
          : cities.map(city => city.label);

      let selectedContacts =
        !!state.selectedFilters.contacts.length || state.isFiltersApplied
          ? state.selectedFilters.contacts
          : contacts.map(contact => contact.label);

      let selectedAreaMax =
        state.selectedFilters.area_max === null || !state.isFiltersApplied
          ? action.payload.area_max
          : state.selectedFilters.area_max;

      let selectedAreaMin =
        state.selectedFilters.area_min === null || !state.isFiltersApplied
          ? action.payload.area_min
          : state.selectedFilters.area_min;

      return update(state, {
        filters: {
          cities: { $set: cities },
          area_max: { $set: action.payload.area_max },
          area_min: { $set: action.payload.area_min },
          contacts: { $set: contacts }
        },
        selectedFilters: {
          cities: { $set: [...selectedCities] },
          contacts: { $set: [...selectedContacts] },
          area_max: { $set: selectedAreaMax },
          area_min: { $set: selectedAreaMin }
        },
        staging: {
          cities: { $set: [...selectedCities] },
          contacts: { $set: [...selectedContacts] },
          area_max: { $set: selectedAreaMax },
          area_min: { $set: selectedAreaMin }
        }
      });
    }

    case lovConstants.FETCH_SUITE_TYPES_SUCCESSFUL: {
      let suiteTypes = action.payload;

      let selectedSuiteTypes =
        !!state.selectedFilters.suiteTypes.length || state.isFiltersApplied
          ? state.selectedFilters.suiteTypes
          : suiteTypes.map(suiteType => suiteType.id);

      return update(state, {
        filters: {
          suiteTypes: { $set: suiteTypes }
        },
        selectedFilters: {
          suiteTypes: { $set: [...selectedSuiteTypes] }
        },
        staging: {
          suiteTypes: { $set: [...selectedSuiteTypes] }
        }
      });
    }

    case suiteConstants.TOGGLE_FILTER_ITEM: {
      const type = action.payload.type;
      let selectedTypeList = [...state.staging[type]];
      const id = action.payload.id;

      if (id === "-1") {
        selectedTypeList =
          selectedTypeList.length === state.filters[type].length
            ? []
            : state.filters[type].map(item => item.id);
      } else {
        if (selectedTypeList.includes(id)) {
          selectedTypeList = selectedTypeList.filter(item => item !== id);
        } else {
          selectedTypeList.push(id);
        }
      }

      return update(state, {
        staging: {
          [type]: { $set: selectedTypeList }
        }
      });
    }

    case suiteConstants.AREA_SLIDER_CHANGE:
      return update(state, {
        staging: {
          area_min: { $set: action.payload.range[0] },
          area_max: { $set: action.payload.range[1] }
        }
      });

    case suiteConstants.SAVE_FILTERS:
      return update(state, {
        selectedFilters: {
          cities: { $set: [...state.staging.cities] },
          suiteTypes: { $set: [...state.staging.suiteTypes] },
          contacts: { $set: [...state.staging.contacts] },
          area_max: { $set: state.staging.area_max },
          area_min: { $set: state.staging.area_min }
        },
        isFiltersApplied: { $set: true }
      });

    case suiteConstants.RESET_FILTERS: {
      const selectedCities = state.filters.cities.map(item => item.id);
      const selectedSuiteTypes = state.filters.suiteTypes.map(item => item.id);
      const selectedContacts = state.filters.contacts.map(item => item.id);
      const selectedAreaMax = state.filters.area_max;
      const selectedAreaMin = state.filters.area_min;

      return update(state, {
        selectedFilters: {
          cities: { $set: [...selectedCities] },
          suiteTypes: { $set: [...selectedSuiteTypes] },
          contacts: { $set: [...selectedContacts] },
          area_max: { $set: selectedAreaMax },
          area_min: { $set: selectedAreaMin }
        },
        staging: {
          cities: { $set: [...selectedCities] },
          suiteTypes: { $set: [...selectedSuiteTypes] },
          contacts: { $set: [...selectedContacts] },
          area_max: { $set: selectedAreaMax },
          area_min: { $set: selectedAreaMin }
        },
        isFiltersApplied: { $set: false }
      });
    }

    case suiteConstants.FETCH_STACKING_SUITE_DETAILS_SUCCESSFUL:
      return Object.assign({}, state, {
        stackingPlanSuiteDetails: action.payload
      });

    default:
      return state;
  }
};

export default reducer;
