import * as stackingPlanConstants from "../constants/stackingPlanConstants";

export function fetchStackingPlan(payload) {
  return {
    type: stackingPlanConstants.FETCH_STACKING_PLAN,
    payload
  };
}

export function toggleSuitesByLeaseExpiry(payload) {
  return {
    type: stackingPlanConstants.LEASE_EXPIRY_TOGGLE,
    payload
  };
}

export function toggleSuitesBySubtenancy(payload) {
  return {
    type: stackingPlanConstants.SUBTENANCY_TOGGLE,
    payload
  };
}

export function selectAllSuitesByLeaseExpiry(payload) {
  return {
    type: stackingPlanConstants.LEASE_EXPIRY_SELECT_ALL,
    payload
  };
}

export function unselectAllSuitesByLeaseExpiry(payload) {
  return {
    type: stackingPlanConstants.LEASE_EXPIRY_UNSELECT_ALL,
    payload
  };
}

export function suiteSearchFilterChanged(payload) {
  return {
    type: stackingPlanConstants.SUITE_SEARCH_FILTER_CHANGED,
    payload
  };
}

export function suiteTypeFilterChanged(payload) {
  return {
    type: stackingPlanConstants.SUITE_TYPE_FILTER_CHANGED,
    payload
  };
}

export function suiteAvailabilityFilterChanged(payload) {
  return {
    type: stackingPlanConstants.SUITE_AVAILABILITY_FILTER_CHANGED,
    payload
  };
}

export function suiteVisibilityFilterChanged(payload) {
  return {
    type: stackingPlanConstants.SUITE_VISIBILITY_FILTER_CHANGED,
    payload
  };
}

export function areaRangeFilterChanged(payload) {
  return {
    type: stackingPlanConstants.AREA_RANGE_FILTER_CHANGED,
    payload
  };
}

export function fetchStackingPlanFilterProfile(payload) {
  return {
    type: stackingPlanConstants.FETCH_STACKING_PLAN_FILTERPROFILE,
    payload
  };
}

export function onPageUnmount(payload) {
  return {
    type: stackingPlanConstants.STACKING_PLAN_UNMOUNT,
    payload
  };
}

export function toggleTenantRightsVisibility(payload) {
  return {
    type: stackingPlanConstants.TENANT_RIGHTS_VISIBILITY_TOGGLE,
    payload
  };
}

export function toggleTenantRight(payload) {
  return {
    type: stackingPlanConstants.TENANT_RIGHT_TOGGLE,
    payload
  };
}

export function selectAllTenantRights(payload) {
  return {
    type: stackingPlanConstants.TENANT_RIGHTS_SELECT_ALL,
    payload
  };
}

export function unselectAllTenantRights(payload) {
  return {
    type: stackingPlanConstants.TENANT_RIGHTS_UNSELECT_ALL,
    payload
  };
}

export function changeSelectedFilterProfile(payload) {
  return {
    type: stackingPlanConstants.CHANGE_SELECTED_FILTER_PROFILE,
    payload
  };
}

export function applyStackingPlanFilters(payload) {
  return {
    type: stackingPlanConstants.APPLY_FILTERS,
    payload
  };
}
