export const FETCH_LEASES = "lease/FETCH_LEASES";
export const FETCH_LEASES_SUCCESSFUL = "lease/FETCH_LEASES_SUCCESSFUL";
export const FETCH_LEASES_FAILED = "lease/FETCH_LEASES_FAILED";

export const UPDATE_LEASE = "lease/UPDATE_LEASE";
export const UPDATE_LEASE_SUCCESSFUL = "lease/UPDATE_LEASE_SUCCESSFUL";
export const UPDATE_LEASE_FAILED = "lease/UPDATE_LEASE_FAILED";

export const CREATE_LEASE = "lease/CREATE_LEASE";
export const CREATE_LEASE_SUCCESSFUL = "lease/CREATE_LEASE_SUCCESSFUL";
export const CREATE_LEASE_FAILED = "lease/CREATE_LEASE_FAILED";

export const DELETE_LEASE = "lease/DELETE_LEASE";
export const DELETE_LEASE_SUCCESSFUL = "lease/DELETE_LEASE_SUCCESSFUL";
export const DELETE_LEASE_FAILED = "lease/DELETE_LEASE_FAILED";
