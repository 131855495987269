export const FETCH_SUITES = "suite/FETCH_SUITES";
export const FETCH_SUITES_SUCCESSFUL = "suite/FETCH_SUITES_SUCCESSFUL";
export const FETCH_SUITES_FAILED = "suite/FETCH_SUITES_FAILED";

export const DELETE_SUITE = "suite/DELETE_SUITE";
export const DELETE_SUITE_SUCCESSFUL = "suite/DELETE_SUITE_SUCCESSFUL";
export const DELETE_SUITE_FAILED = "suite/DELETE_SUITE_FAILED";

export const FETCH_STACKING_SUITE_DETAILS =
  "suite/FETCH_STACKING_SUITE_DETAILS";
export const FETCH_STACKING_SUITE_DETAILS_SUCCESSFUL =
  "suite/FETCH_STACKING_SUITE_DETAILS_SUCCESSFUL";
export const FETCH_STACKING_SUITE_DETAILS_FAILED =
  "suite/FETCH_STACKING_SUITE_DETAILS_FAILED";

export const FETCH_SUITES_FILTER_ITEMS = "suite/FETCH_SUITES_FILTER_ITEMS";
export const FETCH_SUITES_FILTER_ITEMS_SUCCESSFUL =
  "suite/FETCH_SUITES_FILTER_ITEMS_SUCCESSFUL";
export const FETCH_SUITES_FILTER_ITEMS_FAILED =
  "suite/FETCH_SUITES_FILTER_ITEMS_FAILED";

export const SPLIT_SUITE = "suite/SPLIT_SUITE";
export const SPLIT_SUITE_SUCCESSFUL = "suite/SPLIT_SUITE_SUCCESSFUL";
export const SPLIT_SUITE_FAILED = "suite/SPLIT_SUITE_FAILED";

export const ADD_SUITE_LEFT = "suite/ADD_SUITE_LEFT";
export const ADD_SUITE_LEFT_SUCCESSFUL = "suite/ADD_SUITE_LEFT_SUCCESSFUL";
export const ADD_SUITE_LEFT_FAILED = "suite/ADD_SUITE_LEFT_FAILED";

export const ADD_SUITE_RIGHT = "suite/ADD_SUITE_RIGHT";
export const ADD_SUITE_RIGHT_SUCCESSFUL = "suite/ADD_SUITE_RIGHT_SUCCESSFUL";
export const ADD_SUITE_RIGHT_FAILED = "suite/ADD_SUITE_RIGHT_FAILED";

export const ADD_NEW_SUITE = "suite/ADD_NEW_SUITE";
export const ADD_NEW_SUITE_SUCCESSFUL = "suite/ADD_NEW_SUITE_SUCCESSFUL";
export const ADD_NEW_SUITE_FAILED = "suite/ADD_NEW_SUITE_FAILED";

export const UPDATE_SUITE = "suite/UPDATE_SUITE";
export const UPDATE_SUITE_SUCCESSFUL = "suite/UPDATE_SUITE_SUCCESSFUL";
export const UPDATE_SUITE_FAILED = "suite/UPDATE_SUITE_FAILED";

export const SAVE_FILTERS = "suite/SAVE_FILTERS";
export const RESET_FILTERS = "suite/RESET_FILTERS";

export const TOGGLE_FILTER_ITEM = "suite/TOGGLE_FILTER_ITEM";
export const AREA_SLIDER_CHANGE = "suite/AREA_SLIDER_CHANGE";

//SP -> Stacking Plan
export const SP_UPDATE_EXTENDED_SUITE = "suite/SP_UPDATE_EXTENDED_SUITE";
export const SP_UPDATE_EXTENDED_SUITE_SUCCESSFUL =
  "suite/SP_UPDATE_EXTENDED_SUITE_SUCCESSFUL";
export const SP_UPDATE_EXTENDED_SUITE_FAILED =
  "suite/SP_UPDATE_EXTENDED_SUITE_FAILED";

export const suiteTypes = {
  INDUSTRIAL: "I",
  OFFICE: "O",
  RETAIL: "R",
  RESIDENTIAL: "E",
  RETIREMENT: "S",
  OTHER_LEASABLE: "Z",
  LAND: "L"
};
