import { takeLatest, call, put } from "redux-saga/effects";

import * as appSearchConstants from "../constants/appSearchConstants";
import SearchService from "../services/searchService";

function* searchApp(action) {
  if (action.type === appSearchConstants.CLEAR_SEARCH_APP_RESULTS) {
    return;
  }

  try {
    const result = yield call(
      SearchService.searchApp,
      action.payload.searchText
    );
    yield put({
      type: appSearchConstants.SEARCH_APP_SUCCESSFUL,
      payload: result
    });
  } catch (error) {
    yield put({ type: appSearchConstants.SEARCH_APP_FAILED, error });
  }
}

export function* searchAppWatcher() {
  yield takeLatest(
    [
      appSearchConstants.SEARCH_APP,
      appSearchConstants.CLEAR_SEARCH_APP_RESULTS
    ],
    searchApp
  );
}
