import { all, call } from "redux-saga/effects";

import {
  authenticateOktaUserWatcher,
  authenticateUserWatcher,
  logoutWatcher,
  redirectOktaUserWatcher,
  refreshOktaUserWatcher,
  refreshTokenWatcher,
  tokenVerificationFailedWatcher,
  verifyTokenWatcher
} from "./containers/Auth/sagas";

import { checkConnectivityWatcher } from "./sagas/networkSaga";

import { searchAppWatcher } from "./sagas/searchSaga";
import { systemSettingsWatcher } from "./sagas/settingSaga";

import {
  addUserBuildingsWatcher,
  copyBuildingAssignmentsWatcher,
  createUserWatcher,
  deleteUserBuildingWatcher,
  deleteUserWatcher,
  editUserDetailsWatcher,
  editUserRoleTypeWatcher,
  editUserWatcher,
  fetchAllBuildingsWatcher,
  fetchOktaUserWatcher,
  fetchUserBuildingsWatcher,
  fetchUserDetailsWatcher,
  fetchUserWatcher,
  fetchUsersWatcher,
  transferBuildingAssignmentsWatcher
} from "./containers/User/sagas";

import {
  fetchDashboardFilterItemsWatcher,
  fetchDashboardMetaWatcher,
  fetchDistributionWatcher,
  fetchEngagementWatcher,
  fetchInsightsWatcher,
  fetchLeaseExpiryProfileWatcher
} from "./sagas/dashboardSaga";

import {
  archiveBuildingWatcher,
  createBuildingWatcher,
  deleteBuildingWatcher,
  fetchBuildingAfterUpdateWatcher,
  fetchBuildingGroupsWatcher,
  fetchBuildingSuitesWatcher,
  fetchBuildingWatcher,
  fetchBuildingsFilterItemsWatcher,
  fetchBuildingsWatcher,
  fetchPromotedBuildingsWatcher,
  promoteBuildingWatcher,
  setFavouriteWatcher,
  unArchiveBuildingWatcher,
  unPromoteBuildingWatcher,
  unsetFavouriteWatcher,
  updateBuildingWatcher,
  updatePromotedBuildingListWatcher
} from "./sagas/buildingsSaga";

import {
  autoComputeValuesWatcher,
  calculateAdditionalRentWatcher,
  fetchExtendedBuildingWatcher,
  updateExtendedBuildingWatcher
} from "./sagas/extendedBuildingSaga";

import {
  addNewSuiteWatcher,
  addSuiteLeftWatcher,
  addSuiteRightWatcher,
  deleteSuiteWatcher,
  fetchStackingSuiteDetailsWatcher,
  fetchSuitesFilterItemsWatcher,
  fetchSuitesWatcher,
  splitSuiteWatcher,
  updateSuiteWatcher
} from "./sagas/suitesSaga";

import { updateExtendedSuiteInStackingPlanWatcher } from "./sagas/extendedSuiteSaga";

import {
  createLeaseWatcher,
  deleteLeaseWatcher,
  fetchLeasesWatcher,
  updateLeaseWatcher
} from "./sagas/leaseSaga";

import { fetchRentRollWatcher } from "./sagas/rentRollSaga";

import {
  fetchDistributionReportWatcher,
  fetchLargestTenantReportWatcher,
  fetchLeaseExpiryProfileReportWatcher,
  fetchOccupancyReportWatcher,
  fetchVacantSuitesReportWatcher
} from "./sagas/buildingReportsSaga";

import {
  fetchStackingPlanFilterProfileWatcher,
  fetchStackingPlanWatcher
} from "./sagas/stackingPlanSaga";

import {
  deleteSuiteMediaWatcher,
  fetchSuiteMediaWatcher,
  reorderSuiteMediaWatcher,
  setSuitePrimaryPictureWatcher,
  toggleSuitePublishMediaStatusWatcher,
  updateSuiteMediaWatcher,
  uploadSuiteMediaWatcher
} from "./sagas/suiteMediaSaga";

import {
  fetchAllLOVTypesWatcher,
  fetchArchivedStatusesWatcher,
  fetchBuildingTypesWatcher,
  fetchFavStatusesWatcher,
  fetchPubStatusesWatcher,
  fetchSuiteTypesWatcher,
  fetchTenantRightsWatcher
} from "./sagas/lovSaga";

import {
  fetchBuildingAnnouncementsWatcher,
  updateBuildingAnnouncementWatcher
} from "./sagas/buildingAnnouncementSaga";

import {
  createBuildingInformationItemWatcher,
  deleteBuildingInformationItemWatcher,
  fetchBuildingInformationMetaWatcher,
  fetchBuildingInformationWatcher,
  reorderBuildingInformaionItemsWatcher,
  updateBuildingInBuildingInformationWatcher,
  updateBuildingInformationItemWatcher
} from "./sagas/buildingInformationSaga";

import {
  deleteBuildingMediaWatcher,
  fetchBuildingMediaWatcher,
  reorderMediaWatcher,
  setPrimaryPictureWatcher,
  togglePublishMediaStatusWatcher,
  updateBuildingMediaWatcher,
  uploadBuildingMediaWatcher
} from "./sagas/buildingMediaSaga";

import {
  addFloorAboveWatcher,
  addFloorBelowWatcher,
  createFloorplanAssetWatcher,
  deleteFloorWatcher,
  fetchFloorPlanWatcher,
  reorderSuitesWatcher,
  updateFloorWatcher,
  updateFloorplanAssetWatcher
} from "./sagas/floorSaga";

import {
  addBuildingContactsWatcher,
  addExternalContactWatcher,
  deleteBuildingContactWatcher,
  fetchAllContactsWatcher,
  fetchBuildingContactsWatcher,
  getExternalContactWatcher,
  updateBuildingContactWatcher,
  updateBuildingContactsOrderWatcher
} from "./sagas/buildingContactSaga";

import {
  createBuildingTourWatcher,
  deleteBuildingTourWatcher,
  fetchBuildingToursWatcher,
  updateBuildingTourWatcher
} from "./sagas/buildingsVirtualTourSaga";

import {
  createSuiteTourWatcher,
  deleteSuiteTourWatcher,
  fetchSuiteToursWatcher,
  updateSuiteTourWatcher
} from "./sagas/suitesVirtualTourSaga";

export default function* rootSaga() {
  yield all([
    call(authenticateUserWatcher),
    call(verifyTokenWatcher),
    call(tokenVerificationFailedWatcher),
    call(refreshTokenWatcher),
    call(logoutWatcher),
    call(redirectOktaUserWatcher),
    call(authenticateOktaUserWatcher),
    call(refreshOktaUserWatcher),
    call(checkConnectivityWatcher),

    call(searchAppWatcher),

    call(systemSettingsWatcher),

    call(fetchUserWatcher),
    call(fetchOktaUserWatcher),
    call(createUserWatcher),
    call(editUserWatcher),
    call(deleteUserWatcher),
    call(fetchUsersWatcher),
    call(fetchUserDetailsWatcher),
    call(editUserDetailsWatcher),
    call(fetchUserBuildingsWatcher),
    call(deleteUserBuildingWatcher),
    call(editUserRoleTypeWatcher),
    call(fetchAllBuildingsWatcher),
    call(transferBuildingAssignmentsWatcher),
    call(copyBuildingAssignmentsWatcher),
    call(addUserBuildingsWatcher),

    call(fetchDashboardMetaWatcher),
    call(fetchDashboardFilterItemsWatcher),
    call(fetchDistributionWatcher),
    call(fetchLeaseExpiryProfileWatcher),
    call(fetchInsightsWatcher),
    call(fetchEngagementWatcher),

    call(fetchBuildingWatcher),
    call(fetchBuildingAfterUpdateWatcher),
    call(fetchBuildingsWatcher),
    call(fetchBuildingsFilterItemsWatcher),
    call(createBuildingWatcher),
    call(deleteBuildingWatcher),
    call(updateBuildingWatcher),
    call(setFavouriteWatcher),
    call(unsetFavouriteWatcher),
    call(fetchBuildingGroupsWatcher),
    call(fetchPromotedBuildingsWatcher),
    call(promoteBuildingWatcher),
    call(unPromoteBuildingWatcher),
    call(updatePromotedBuildingListWatcher),
    call(archiveBuildingWatcher),
    call(unArchiveBuildingWatcher),
    call(fetchBuildingSuitesWatcher),

    call(fetchExtendedBuildingWatcher),
    call(updateExtendedBuildingWatcher),
    call(autoComputeValuesWatcher),
    call(calculateAdditionalRentWatcher),

    call(fetchSuitesWatcher),
    call(updateSuiteWatcher),
    call(deleteSuiteWatcher),
    call(fetchSuitesFilterItemsWatcher),
    call(splitSuiteWatcher),
    call(addSuiteLeftWatcher),
    call(addSuiteRightWatcher),
    call(addNewSuiteWatcher),
    call(fetchStackingSuiteDetailsWatcher),

    call(updateExtendedSuiteInStackingPlanWatcher),

    call(fetchLeasesWatcher),
    call(updateLeaseWatcher),
    call(createLeaseWatcher),
    call(deleteLeaseWatcher),

    call(fetchRentRollWatcher),

    call(fetchOccupancyReportWatcher),
    call(fetchDistributionReportWatcher),
    call(fetchLeaseExpiryProfileReportWatcher),
    call(fetchLargestTenantReportWatcher),
    call(fetchVacantSuitesReportWatcher),

    call(fetchStackingPlanWatcher),
    call(fetchStackingPlanFilterProfileWatcher),

    call(fetchSuiteMediaWatcher),
    call(uploadSuiteMediaWatcher),
    call(deleteSuiteMediaWatcher),
    call(updateSuiteMediaWatcher),
    call(setSuitePrimaryPictureWatcher),
    call(toggleSuitePublishMediaStatusWatcher),
    call(reorderSuiteMediaWatcher),

    call(fetchAllLOVTypesWatcher),
    call(fetchSuiteTypesWatcher),
    call(fetchBuildingTypesWatcher),
    call(fetchPubStatusesWatcher),
    call(fetchFavStatusesWatcher),
    call(fetchArchivedStatusesWatcher),
    call(fetchTenantRightsWatcher),
    call(fetchBuildingAnnouncementsWatcher),
    call(updateBuildingAnnouncementWatcher),
    call(updateBuildingInBuildingInformationWatcher),
    call(fetchBuildingInformationWatcher),
    call(fetchBuildingInformationMetaWatcher),
    call(createBuildingInformationItemWatcher),
    call(updateBuildingInformationItemWatcher),
    call(deleteBuildingInformationItemWatcher),
    call(reorderBuildingInformaionItemsWatcher),
    call(fetchBuildingMediaWatcher),
    call(uploadBuildingMediaWatcher),
    call(deleteBuildingMediaWatcher),
    call(setPrimaryPictureWatcher),
    call(togglePublishMediaStatusWatcher),
    call(updateBuildingMediaWatcher),
    call(reorderMediaWatcher),
    call(fetchFloorPlanWatcher),
    call(reorderSuitesWatcher),
    call(addFloorAboveWatcher),
    call(addFloorBelowWatcher),
    call(deleteFloorWatcher),
    call(updateFloorWatcher),
    call(updateFloorplanAssetWatcher),
    call(createFloorplanAssetWatcher),
    call(deleteBuildingContactWatcher),
    call(addBuildingContactsWatcher),
    call(addExternalContactWatcher),
    call(getExternalContactWatcher),
    call(updateBuildingContactsOrderWatcher),
    call(fetchBuildingContactsWatcher),
    call(fetchAllContactsWatcher),
    call(updateBuildingContactWatcher),

    call(createBuildingTourWatcher),
    call(fetchBuildingToursWatcher),
    call(updateBuildingTourWatcher),
    call(deleteBuildingTourWatcher),

    call(createSuiteTourWatcher),
    call(fetchSuiteToursWatcher),
    call(updateSuiteTourWatcher),
    call(deleteSuiteTourWatcher)
  ]);
}
