import * as appSearchConstants from "../constants/appSearchConstants";

const initialState = {
  appSearchResults: []
};

const reducer = function searchReducer(state = initialState, action) {
  switch (action.type) {
    case appSearchConstants.SEARCH_APP_SUCCESSFUL:
      return Object.assign({}, state, {
        appSearchResults: action.payload
      });

    case appSearchConstants.CLEAR_SEARCH_APP_RESULTS:
      return Object.assign({}, state, {
        appSearchResults: []
      });

    default:
      return state;
  }
};

export default reducer;
