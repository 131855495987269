import http, { noBaseHttp } from "../auth/http";

const FloorService = () => {
  const reorderSuites = request => {
    const { floorId, suiteOrder } = request;

    return http
      .patch(`/api/v1/floors/${floorId}/suiteorder/`, {
        order: suiteOrder
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const addFloorAbove = request => {
    const { floorId } = request;

    return http
      .post(`/api/v1/floors/${floorId}/addfloorabove/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const addFloorBelow = request => {
    const { floorId } = request;

    return http
      .post(`/api/v1/floors/${floorId}/addfloorbelow/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const deleteFloor = request => {
    const { floorId } = request;
    return http
      .delete(`/api/v1/floors/${floorId}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const updateFloor = request => {
    const { floorId, floor } = request;
    return http
      .patch(`/api/v1/floors/${floorId}/`, floor)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const updateFloorplanAsset = request => {
    const { floorplanId, formData } = request;
    return http
      .patch(`/api/v1/floorplan/${floorplanId}/`, formData)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const createFloorplanAsset = request => {
    const { formData, floor } = request;
    formData.append("floor", floor);
    return http
      .post(`/api/v1/floorplan/`, formData)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const getSvgAsset = url => {
    return noBaseHttp(url)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error));
  };

  const fetchFloorPlan = floorPlanId => {
    return http
      .get(`/api/v1/floorplan/${floorPlanId}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error));
  };

  const createFloorPlanLease = ({ leaseId, floorplanId }) => {
    const payload = {
      lease: leaseId,
      floor_plan: floorplanId
    };

    return http
      .post(`/api/v1/floorplanlease/`, payload)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const updateFloorPlanLease = ({ leaseId, floorplanLeaseId }) => {
    const payload = {
      lease: leaseId
    };
    return http
      .patch(`/api/v1/floorplanlease/${floorplanLeaseId}/`, payload)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const deleteFloorPlanLease = ({ floorplanLeaseId }) => {
    return http
      .delete(`/api/v1/floorplanlease/${floorplanLeaseId}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  return {
    reorderSuites,
    addFloorAbove,
    addFloorBelow,
    deleteFloor,
    updateFloor,
    updateFloorplanAsset,
    getSvgAsset,
    fetchFloorPlan,
    createFloorplanAsset,
    createFloorPlanLease,
    updateFloorPlanLease,
    deleteFloorPlanLease
  };
};

const api = FloorService();

export default api;
