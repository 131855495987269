import * as buildingsConstants from "../constants/buildings";
import * as lovConstants from "../constants/lovConstants";
import update from "immutability-helper";

const initialState = {
  selectedBuildingsViewFilter: buildingsConstants.BUILDINGS_VIEW_FILTER.ALL,
  fetching: false,
  selectedBuilding: {},
  buildingsPage: {
    results: [],
    meta: {
      building_count: 0,
      suite_count: 0,
      promoted_buildings_count: 0,
      promoted_suites_count: 0,
      group_count: 0,
      grouped_buildings_count: 0,
      office_building_count: 0,
      industrial_building_count: 0,
      retail_building_count: 0,
      residential_building_count: 0,
      land_building_count: 0
    }
  },

  promotedBuildings: {
    buildings: [],
    meta: {
      building_count: 0,
      suite_count: 0,
      promoted_buildings_count: 0,
      promoted_suites_count: 0,
      group_count: 0,
      grouped_buildings_count: 0,
      office_building_count: 0,
      industrial_building_count: 0,
      retail_building_count: 0,
      residential_building_count: 0,
      land_building_count: 0
    }
  },

  createBuilding: {
    submitting: false
  },

  filters: {
    cities: [],
    companies: [],
    funds: [],
    groups: [],
    buildingTypes: [],
    pubStatus: [],
    favStatus: [],
    archivedStatus: [],
    area_max: null,
    area_min: null
  },
  selectedFilters: {
    cities: [],
    company: null,
    fund: null,
    group: null,
    buildingTypes: [],
    pubStatus: [],
    favStatus: [],
    archivedStatus: ["NA"],
    area_max: null,
    area_min: null
  },
  staging: {
    cities: [],
    company: null,
    fund: null,
    group: null,
    buildingTypes: [],
    pubStatus: [],
    favStatus: [],
    archivedStatus: [],
    area_max: null,
    area_min: null
  },
  isFiltersApplied: true,

  buildingGroups: [],

  // New Building Wizard state
  showNewBuildingWizard: false,

  // Extended Building state
  extendedBuilding: {}
};

const reducer = function buildingsReducer(state = initialState, action) {
  switch (action.type) {
    case buildingsConstants.FETCH_BUILDING:
      return Object.assign({}, state, {
        fetching: true
      });

    case buildingsConstants.FETCH_BUILDING_SUCCESSFUL:
      return Object.assign({}, state, {
        selectedBuilding: action.payload,
        fetching: false
      });

    case buildingsConstants.FETCH_BUILDING_FAILED:
      return Object.assign({}, state, {
        fetching: false,
        selectedBuilding: action.payload
      });

    case buildingsConstants.FETCH_BUILDINGS:
      return Object.assign({}, state, {
        fetching: true
      });

    case buildingsConstants.FETCH_BUILDINGS_SUCCESSFUL:
      return Object.assign({}, state, {
        fetching: false,
        buildingsPage: action.payload
      });

    case buildingsConstants.FETCH_BUILDINGS_FAILED:
      return Object.assign({}, state, {
        fetching: false
      });

    case buildingsConstants.FETCH_PROMOTED_BUILDINGS_SUCCESSFUL:
      return Object.assign({}, state, {
        promotedBuildings: action.payload
      });

    case buildingsConstants.APPLY_BUILDINGS_VIEW_FILTER:
      return Object.assign({}, state, {
        selectedBuildingsViewFilter: action.payload
      });

    case buildingsConstants.FETCH_BUILDINGS_FILTER_ITEMS_SUCCESSFUL: {
      const cities = action.payload.city_counts.map(city => ({
        ...city,
        id: city.city
      }));

      const selectedCities = state.selectedFilters.cities.length
        ? state.selectedFilters.cities
        : cities.map(city => city.city);

      const companies = action.payload.company__name.map(item => {
        return {
          value: item.company__name,
          label: item.company__name + ` (${item.count})`
        };
      });

      const funds = action.payload.funds.map(item => {
        return {
          value: item.ownership__name,
          label: item.ownership__name + ` (${item.count})`
        };
      });

      const groups = action.payload.groups.map(item => {
        return {
          value: item.group,
          label: item.group + ` (${item.count})`
        };
      });

      const selectedAreaMax =
        state.selectedFilters.area_max === null
          ? action.payload.area_max
          : state.selectedFilters.area_max;

      const selectedAreaMin =
        state.selectedFilters.area_min === null
          ? action.payload.area_min
          : state.selectedFilters.area_min;

      return update(state, {
        filters: {
          cities: { $set: cities },
          companies: { $set: companies },
          funds: { $set: funds },
          groups: { $set: groups },
          area_max: { $set: action.payload.area_max },
          area_min: { $set: action.payload.area_min }
        },
        selectedFilters: {
          cities: { $set: [...selectedCities] },
          area_max: { $set: selectedAreaMax },
          area_min: { $set: selectedAreaMin }
        },
        staging: {
          cities: { $set: [...selectedCities] },
          area_max: { $set: selectedAreaMax },
          area_min: { $set: selectedAreaMin }
        }
      });
    }

    case lovConstants.FETCH_PUB_STATUSES_SUCCESSFUL: {
      const pubStatus = action.payload;

      const selectedPubStatus = state.selectedFilters.pubStatus.length
        ? state.selectedFilters.pubStatus
        : pubStatus.map(status => status.id);

      return update(state, {
        filters: {
          pubStatus: { $set: pubStatus }
        },
        selectedFilters: {
          pubStatus: { $set: [...selectedPubStatus] }
        },
        staging: {
          pubStatus: { $set: [...selectedPubStatus] }
        }
      });
    }

    case lovConstants.FETCH_FAV_STATUSES_SUCCESSFUL: {
      const favStatus = action.payload;

      const selectedFavStatus = state.selectedFilters.favStatus.length
        ? state.selectedFilters.favStatus
        : favStatus.map(status => status.id);

      return update(state, {
        filters: {
          favStatus: { $set: favStatus }
        },
        selectedFilters: {
          favStatus: { $set: [...selectedFavStatus] }
        },
        staging: {
          favStatus: { $set: [...selectedFavStatus] }
        }
      });
    }

    case lovConstants.FETCH_ARCHIVED_STATUSES_SUCCESSFUL: {
      const archivedStatus = action.payload;

      const selectedArchivedStatus = state.selectedFilters.archivedStatus.length
        ? state.selectedFilters.archivedStatus
        : archivedStatus.map(status => status.id);

      return update(state, {
        filters: {
          archivedStatus: { $set: archivedStatus }
        },
        selectedFilters: {
          archivedStatus: { $set: [...selectedArchivedStatus] }
        },
        staging: {
          archivedStatus: { $set: [...selectedArchivedStatus] }
        }
      });
    }

    case lovConstants.FETCH_BUILDING_TYPES_SUCCESSFUL: {
      const buildingTypes = action.payload;

      const selectedBuildingTypes = state.selectedFilters.buildingTypes.length
        ? state.selectedFilters.buildingTypes
        : buildingTypes.map(buildingType => buildingType.id);

      return update(state, {
        filters: {
          buildingTypes: { $set: buildingTypes }
        },
        selectedFilters: {
          buildingTypes: { $set: [...selectedBuildingTypes] }
        },
        staging: {
          buildingTypes: { $set: [...selectedBuildingTypes] }
        }
      });
    }

    case buildingsConstants.TOGGLE_FILTER_ITEM: {
      const type = action.payload.type;
      let selectedTypeList = [...state.staging[type]];
      const id = action.payload.id;

      if (id === "-1") {
        selectedTypeList =
          selectedTypeList.length === state.filters[type].length
            ? []
            : state.filters[type].map(item => item.id);
      } else {
        if (selectedTypeList.includes(id)) {
          selectedTypeList = selectedTypeList.filter(item => item !== id);
        } else {
          selectedTypeList.push(id);
        }
      }

      return update(state, {
        staging: {
          [type]: { $set: selectedTypeList }
        }
      });
    }

    case buildingsConstants.AREA_SLIDER_CHANGE:
      return update(state, {
        staging: {
          area_min: { $set: action.payload.range[0] },
          area_max: { $set: action.payload.range[1] }
        }
      });

    case buildingsConstants.SELECT_FILTER_DROPDOWN_ITEM: {
      const val = !!action.payload.id ? action.payload.id.value : null;
      return update(state, {
        staging: {
          [action.payload.type]: { $set: val }
        }
      });
    }

    case buildingsConstants.SAVE_FILTERS:
      return update(state, {
        selectedFilters: {
          cities: { $set: [...state.staging.cities] },
          company: { $set: state.staging.company },
          fund: { $set: state.staging.fund },
          group: { $set: state.staging.group },
          buildingTypes: { $set: [...state.staging.buildingTypes] },
          pubStatus: { $set: [...state.staging.pubStatus] },
          favStatus: { $set: [...state.staging.favStatus] },
          archivedStatus: { $set: [...state.staging.archivedStatus] },
          area_max: { $set: state.staging.area_max },
          area_min: { $set: state.staging.area_min }
        },
        isFiltersApplied: { $set: true }
      });

    case buildingsConstants.RESET_FILTERS: {
      const selectedCities = state.filters.cities.map(city => city.id);
      const selectedBuildingTypes = state.filters.buildingTypes.map(
        ({ id }) => id
      );
      const selectedPubStatus = state.filters.pubStatus.map(
        status => status.id
      );
      const selectedFavStatus = state.filters.favStatus.map(
        status => status.id
      );
      const selectedArchivedStatus = state.filters.archivedStatus
        .filter(item => item.id !== "A")
        .map(status => status.id);
      const selectedAreaMax = state.filters.area_max;
      const selectedAreaMin = state.filters.area_min;

      return update(state, {
        selectedFilters: {
          cities: { $set: [...selectedCities] },
          company: { $set: null },
          fund: { $set: null },
          group: { $set: null },
          buildingTypes: { $set: [...selectedBuildingTypes] },
          pubStatus: { $set: [...selectedPubStatus] },
          favStatus: { $set: [...selectedFavStatus] },
          archivedStatus: { $set: [...selectedArchivedStatus] },
          area_max: { $set: selectedAreaMax },
          area_min: { $set: selectedAreaMin }
        },
        staging: {
          cities: { $set: [...selectedCities] },
          company: { $set: null },
          fund: { $set: null },
          group: { $set: null },
          buildingTypes: { $set: [...selectedBuildingTypes] },
          pubStatus: { $set: [...selectedPubStatus] },
          favStatus: { $set: [...selectedFavStatus] },
          archivedStatus: { $set: [...selectedArchivedStatus] },
          area_max: { $set: selectedAreaMax },
          area_min: { $set: selectedAreaMin }
        },
        isFiltersApplied: { $set: false }
      });
    }

    case buildingsConstants.CREATE_BUILDING: {
      return Object.assign({}, state, {
        createBuilding: {
          submitting: true
        }
      });
    }

    case buildingsConstants.CREATE_BUILDING_SUCCESSFUL: {
      return Object.assign({}, state, {
        createBuilding: {
          submitting: false
        }
      });
    }

    case buildingsConstants.CREATE_BUILDING_FAILED: {
      return Object.assign({}, state, {
        createBuilding: {
          submitting: false
        }
      });
    }

    case buildingsConstants.FETCH_BUILDING_AFTER_UPDATE_SUCCESSFUL: {
      const index = state.buildingsPage.results.findIndex(building => {
        return action.payload.id === building.id;
      });

      return index >= 0
        ? update(state, {
            buildingsPage: {
              results: { $splice: [[index, 1, action.payload]] }
            }
          })
        : state;
    }

    case buildingsConstants.DELETE_BUILDING_SUCCESSFUL: {
      var index = state.buildingsPage.results.findIndex(building => {
        return action.payload.id === building.id;
      });
      return update(state, {
        buildingsPage: {
          results: { $splice: [[index, 1]] }
        }
      });
    }

    case buildingsConstants.FETCH_BUILDING_GROUPS_SUCCESSFUL: {
      return Object.assign({}, state, {
        buildingGroups: action.payload.map(group => {
          return {
            id: group.group,
            name: group.group
          };
        })
      });
    }

    case buildingsConstants.UNMOUNT_BUILDINGS_VIEW:
      return Object.assign({}, state, {
        selectedBuildingsViewFilter:
          buildingsConstants.BUILDINGS_VIEW_FILTER.ALL,
        promotedBuildings: {
          buildings: [],
          meta: {}
        },
        showNewBuildingWizard: false
      });

    case buildingsConstants.TOGGLE_NEW_BUILDING_WIZARD:
      return Object.assign({}, state, {
        showNewBuildingWizard: action.payload
      });

    case buildingsConstants.FETCH_EXTENDED_BUILDING_SUCCESSFUL:
      return Object.assign({}, state, {
        extendedBuilding: action.payload
      });

    default:
      return state;
  }
};

export default reducer;
