import * as buildingAnnouncementConstants from "../constants/buildingAnnouncementConstants";

const initialState = {
  announcements: []
};

const reducer = function announcementReducer(state = initialState, action) {
  switch (action.type) {
    case buildingAnnouncementConstants.FETCH_ANNOUNCEMENTS_SUCCESSFUL:
      return Object.assign({}, state, {
        announcements: action.payload
      });

    default:
      return state;
  }
};

export default reducer;
