export const CREATE_SUITE_TOURS = "suitetours/CREATE_SUITE_TOURS";
export const CREATE_SUITE_TOURS_SUCCESSFUL = "suitetours/CREATE_SUITE_TOURS_SUCCESSFUL";
export const CREATE_SUITE_TOURS_FAILED = "suitetours/CREATE_SUITE_TOURS_FAILED";

export const FETCH_SUITE_TOURS = "suitetours/FETCH_SUITE_TOURS";
export const FETCH_SUITE_TOURS_SUCCESSFUL = "suitetours/FETCH_SUITE_TOURS_SUCCESSFUL";
export const FETCH_SUITE_TOURS_FAILED = "suitetours/FETCH_SUITE_TOURS_FAILED";

export const UPDATE_SUITE_TOURS = "suitetours/UPDATE_SUITE_TOURS";
export const UPDATE_SUITE_TOURS_SUCCESSFUL = "suitetours/UPDATE_SUITE_TOURS_SUCCESSFUL";
export const UPDATE_SUITE_TOURS_FAILED = "suitetours/UPDATE_SUITE_TOURS_FAILED";

export const DELETE_SUITE_TOURS = "suitetours/DELETE_SUITE_TOURS";
export const DELETE_SUITE_TOURS_SUCCESSFUL = "suitetours/DELETE_SUITE_TOURS_SUCCESSFUL";
export const DELETE_SUITE_TOURS_FAILED = "suitetours/DELETE_SUITE_TOURS_FAILED";

