export const FETCH_SUITE_TYPES = "lovs/FETCH_SUITE_TYPES";
export const FETCH_SUITE_TYPES_SUCCESSFUL = "lovs/FETCH_SUITE_TYPES_SUCCESSFUL";
export const FETCH_SUITE_TYPES_FAILED = "lovs/FETCH_SUITE_TYPES_FAILED";

export const FETCH_SUITE_AVAILABILITY_TYPES =
  "lovs/FETCH_SUITE_AVAILABILITY_TYPES";
export const FETCH_SUITE_AVAILABILITY_TYPES_SUCCESSFUL =
  "lovs/FETCH_SUITE_AVAILABILITY_TYPES_SUCCESSFUL";
export const FETCH_SUITE_AVAILABILITY_TYPES_FAILED =
  "lovs/FETCH_SUITE_AVAILABILITY_TYPES_FAILED";

export const FETCH_BUILDING_TYPES = "lovs/FETCH_BUILDING_TYPES";
export const FETCH_BUILDING_TYPES_SUCCESSFUL =
  "lovs/FETCH_BUILDING_TYPES_SUCCESSFUL";
export const FETCH_BUILDING_TYPES_FAILED = "lovs/FETCH_BUILDING_TYPES_FAILED";

export const FETCH_PUB_STATUSES = "lovs/FETCH_PUB_STATUSES";
export const FETCH_PUB_STATUSES_SUCCESSFUL =
  "lovs/FETCH_PUB_STATUSES_SUCCESSFUL";
export const FETCH_PUB_STATUSES_FAILED = "lovs/FETCH_PUB_STATUSES_FAILED";

export const FETCH_FAV_STATUSES = "lovs/FETCH_FAV_STATUSES";
export const FETCH_FAV_STATUSES_SUCCESSFUL =
  "lovs/FETCH_FAV_STATUSES_SUCCESSFUL";
export const FETCH_FAV_STATUSES_FAILED = "lovs/FETCH_FAV_STATUSES_FAILED";

export const FETCH_ARCHIVED_STATUSES = "lovs/FETCH_ARCHIVED_STATUSES";
export const FETCH_ARCHIVED_STATUSES_SUCCESSFUL =
  "lovs/FETCH_ARCHIVED_STATUSES_SUCCESSFUL";
export const FETCH_ARCHIVED_STATUSES_FAILED =
  "lovs/FETCH_ARCHIVED_STATUSES_FAILED";

export const FETCH_LISTING_STATUSES = "lovs/FETCH_LISTING_STATUSES";
export const FETCH_LISTING_STATUSES_SUCCESSFUL =
  "lovs/FETCH_LISTING_STATUSES_SUCCESSFUL";
export const FETCH_LISTING_STATUSES_FAILED =
  "lovs/FETCH_LISTING_STATUSES_FAILED";

export const FETCH_RESIDENTIAL_SUITE_TYPES =
  "lovs/FETCH_RESIDENTIAL_SUITE_TYPES";
export const FETCH_RESIDENTIAL_SUITE_TYPES_SUCCESSFUL =
  "lovs/FETCH_RESIDENTIAL_SUITE_TYPES_SUCCESSFUL";
export const FETCH_RESIDENTIAL_SUITE_TYPES_FAILED =
  "lovs/FETCH_RESIDENTIAL_SUITE_TYPES_FAILED";

export const FETCH_SUITE_CONDITION_TYPES = "lovs/FETCH_SUITE_CONDITION_TYPES";
export const FETCH_SUITE_CONDITION_TYPES_SUCCESSFUL =
  "lovs/FETCH_SUITE_CONDITION_TYPES_SUCCESSFUL";
export const FETCH_SUITE_CONDITION_TYPES_FAILED =
  "lovs/FETCH_SUITE_CONDITION_TYPES_FAILED";

export const FETCH_RESIDENTIAL_BUILDING_TYPES =
  "lovs/FETCH_RESIDENTIAL_BUILDING_TYPES";
export const FETCH_RESIDENTIAL_BUILDING_TYPES_SUCCESSFUL =
  "lovs/FETCH_RESIDENTIAL_BUILDING_TYPES_SUCCESSFUL";
export const FETCH_RESIDENTIAL_BUILDING_TYPES_FAILED =
  "lovs/FETCH_RESIDENTIAL_BUILDING_TYPES_FAILED";

export const FETCH_USER_TYPES = "lovs/FETCH_USER_TYPES";
export const FETCH_USER_TYPES_SUCCESSFUL = "lovs/FETCH_USER_TYPES_SUCCESSFUL";
export const FETCH_USER_TYPES_FAILED = "lovs/FETCH_USER_TYPES_FAILED";

export const FETCH_TENANT_RIGHTS = "lovs/FETCH_TENANT_RIGHTS";
export const FETCH_TENANT_RIGHTS_SUCCESSFUL =
  "lovs/FETCH_TENANT_RIGHTS_SUCCESSFUL";
export const FETCH_TENANT_RIGHTS_FAILED = "lovs/FETCH_TENANT_RIGHTS_FAILED";

export const FETCH_ALL_LOV_TYPES = "lovs/FETCH_ALL_LOV_TYPES";
export const FETCH_ALL_LOV_TYPES_SUCCESSFUL =
  "lovs/FETCH_ALL_LOV_TYPES_SUCCESSFUL";
export const FETCH_ALL_LOV_TYPES_FAILED = "lovs/FETCH_ALL_LOV_TYPES_FAILED";

export const fieldTypes = {
  NUMBER: "number",
  FLOAT: "float",
  TEXT: "text",
  BOOL: "bool",
  SELECT: "select",
  DATEPICKER: "datepicker",
  UNIT: "unit",
  RICHTEXT: "richtext",
  FRACTIONUNIT: "fractionunit",
  LENGTH: "length",
  FEETINCHES: "feetinches"
};
