export const FETCH_DISTRIBUTION_REPORT =
  "buildingReports/FETCH_DISTRIBUTION_REPORT";
export const FETCH_DISTRIBUTION_REPORT_SUCCESSFUL =
  "buildingReports/FETCH_DISTRIBUTION_REPORT_SUCCESSFUL";
export const FETCH_DISTRIBUTION_REPORT_FAILED =
  "buildingReports/FETCH_DISTRIBUTION_REPORT_FAILED";

export const FETCH_LEASE_EXPIRY_PROFILE_REPORT =
  "buildingReports/FETCH_LEASE_EXPIRY_PROFILE_REPORT";
export const FETCH_LEASE_EXPIRY_PROFILE_REPORT_SUCCESSFUL =
  "buildingReports/FETCH_LEASE_EXPIRY_PROFILE_REPORT_SUCCESSFUL";
export const FETCH_LEASE_EXPIRY_PROFILE_REPORT_FAILED =
  "buildingReports/FETCH_LEASE_EXPIRY_PROFILE_REPORT_FAILED";

export const FETCH_OCCUPANCY_REPORT = "buildingReports/FETCH_OCCUPANCY_REPORT";
export const FETCH_OCCUPANCY_REPORT_SUCCESSFUL =
  "buildingReports/FETCH_OCCUPANCY_REPORT_SUCCESSFUL";
export const FETCH_OCCUPANCY_REPORT_FAILED =
  "buildingReports/FETCH_OCCUPANCY_REPORT_FAILED";

export const FETCH_LARGEST_TENANT_REPORT =
  "buildingReports/FETCH_LARGEST_TENANT_REPORT";
export const FETCH_LARGEST_TENANT_REPORT_SUCCESSFUL =
  "buildingReports/FETCH_LARGEST_TENANT_REPORT_SUCCESSFUL";
export const FETCH_LARGEST_TENANT_REPORT_FAILED =
  "buildingReports/FETCH_LARGEST_TENANT_REPORT_FAILED";

export const FETCH_VACANT_SUITES_REPORT =
  "buildingReports/FETCH_VACANT_SUITES_REPORT";
export const FETCH_VACANT_SUITES_REPORT_SUCCESSFUL =
  "buildingReports/FETCH_VACANT_SUITES_REPORT_SUCCESSFUL";
export const FETCH_VACANT_SUITES_REPORT_FAILED =
  "buildingReports/FETCH_VACANT_SUITES_REPORT_FAILED";

export const BUILDING_REPORTS_UNMOUNT =
  "buildingReports/BUILDING_REPORTS_UNMOUNT";
