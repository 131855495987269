import * as suiteMediaConstants from "../constants/suiteMediaConstants";
import update from "immutability-helper";

const initialState = {
  media: [],
  uploadStatus: {},
  failedUploads: [],
  uploading: false,
  uploadFailed: false
};

const reducer = function suiteMediaReducer(state = initialState, action) {
  switch (action.type) {
    case suiteMediaConstants.FETCH_SUITE_MEDIA_SUCCESSFUL:
      return Object.assign({}, state, {
        media: action.payload
      });

    case suiteMediaConstants.UPLOAD_SUITE_MEDIA:
      return Object.assign({}, state, {
        uploading: true,
        uploadFailed: false,
        failedUploads: [],
        uploadStatus: {}
      });

    case suiteMediaConstants.UPLOAD_SUITE_MEDIA_SUCCESSFUL:
      return Object.assign({}, state, {
        uploading: false
      });

    case suiteMediaConstants.UPLOAD_SUITE_MEDIA_FAILED:
      return update(state, {
        failedUploads: { $push: [action.payload.uid] },
        uploadFailed: { $set: true },
        uploading: { $set: false }
      });

    case suiteMediaConstants.SUITE_MEDIA_UPLOAD_PROGRESS:
      return update(state, {
        uploadStatus: {
          [action.payload.uid]: { $set: action.payload.progress }
        }
      });

    case suiteMediaConstants.CLEAR_SUITE_MEDIA_UPLOAD_STATUS:
      return Object.assign({}, state, {
        uploadStatus: {}
      });

    default:
      return state;
  }
};

export default reducer;
