import Cookies from "js-cookie";
import * as types from "./constants";

const tokenKey = "AuthenticationToken";

const initialState = {
  token: Cookies.get(tokenKey) ? Cookies.get(tokenKey) : null,
  tokenVerificationInProgress: false,
  tokenVerified: false,
  tokenVerificationFailed: false,

  authenticationInProgress: false,
  authenticated: false,

  oktaAuthenticationInProgress: false,
  oktaAuthenticated: false,

  lastRefreshTime: null,
  tokenRefreshInProgress: false,
  tokenRefreshed: false
};

const reducer = function login(state = initialState, action) {
  switch (action.type) {
    // User authentication
    case types.AUTHENTICATE_USER:
      return Object.assign({}, state, {
        authenticationInProgress: true,
        successful: false
      });

    case types.AUTHENTICATE_OKTA_USER:
      return {
        ...state,
        oktaAuthenticationInProgress: true,
        successful: false
      };

    case types.AUTHENTICATION_SUCCESSFUL:
      return Object.assign({}, state, {
        token: action.payload.token,
        authenticated: true,
        authenticationInProgress: false
      });

    case types.OKTA_AUTHENTICATION_SUCCESSFUL:
      return {
        ...state,
        token: action.payload.id_token,
        oktaAuthenticationInProgress: false,
        oktaAuthenticated: true
      };

    case types.AUTHENTICATION_FAILED:
      return Object.assign({}, state, {
        token: null,
        authenticated: false,
        authenticationInProgress: false
      });

    case types.OKTA_AUTHENTICATION_FAILED:
      return {
        ...state,
        token: null,
        authenticated: false,
        authenticationInProgress: false
      };

    // Token verification
    case types.VERIFY_TOKEN:
      return Object.assign({}, state, {
        tokenVerificationInProgress: true,
        tokenVerificationFailed: false
      });

    case types.TOKEN_VERIFICATION_SUCCESSFUL:
      return Object.assign({}, state, {
        tokenVerified: true,
        tokenVerificationInProgress: false,
        tokenVerificationFailed: false,
        authenticated: true
      });

    case types.TOKEN_VERIFICATION_FAILED:
      return Object.assign({}, state, {
        tokenVerified: false,
        tokenVerificationInProgress: false,
        tokenVerificationFailed: true,
        authenticated: false
      });

    // Token refresh
    case types.REFRESH_TOKEN:
      return Object.assign({}, state, {
        tokenRefreshInProgress: true
      });

    case types.TOKEN_REFRESH_SUCCESSFUL:
      return Object.assign({}, state, {
        token: action.payload,
        tokenRefreshInProgress: false,
        authenticated: true,
        tokenRefreshed: true,
        lastRefreshTime: new Date().valueOf()
      });

    case types.TOKEN_REFRESH_FAILED:
      return Object.assign({}, state, {
        tokenRefreshInProgress: false,
        authenticated: false,
        tokenRefreshed: false
      });

    default:
      return state;
  }
};

export default reducer;
