import React from "react";
import * as networkConstants from "../constants/networkConstants";
import { toast } from "react-toastify";

const initialState = {
  isConnectionDown: false
};

const Msg = ({ closeToast }) => (
  <div>
    <i className="fa fa-exclamation-triangle" />
    <span>Connection down</span>
  </div>
);

let toastId = null;

const reducer = function networkReducer(state = initialState, action) {
  switch (action.type) {
    case networkConstants.CONNECTION_DOWN:
    case networkConstants.CHECK_CONNECTIVITY_FAILED: {
      // Remove this code from here, this is a side effect and should be handled in sagas
      if (!toast.isActive(toastId)) {
        toastId = toast(<Msg />, {
          type: toast.TYPE.ERROR,
          autoClose: false,
          className: "connectivity-notification-toast"
        });
      }

      return Object.assign({}, state, {
        isConnectionDown: true
      });
    }

    case networkConstants.CONNECTION_UP:
    case networkConstants.CHECK_CONNECTIVITY_SUCCESSFUL: {
      if (toast.isActive(toastId)) {
        toast.dismiss(toastId);
        toastId = null;
      }
      return Object.assign({}, state, {
        isConnectionDown: false
      });
    }

    default:
      return state;
  }
};

export default reducer;
