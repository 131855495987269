export const ENABLE_PRINT_MODE = "app/ENABLE_PRINT_MODE";
export const SYSTEM_SETTINGS = "app/SYSTEM_SETTINGS";
export const SYSTEM_SETTINGS_SUCCESSFUL = "app/SYSTEM_SETTINGS_SUCCESSFUL";

export const START_ASYNC_ACTION = "START_ASYNC_ACTION";
export const STOP_ASYNC_ACTION = "STOP_ASYNC_ACTION";

export const MAJOR_VERSION = 0;
export const MINOR_VERSION = 1;
export const PATCH_VERSION = 0;
export const VERSION = `${MAJOR_VERSION}.${MINOR_VERSION}.${PATCH_VERSION}`;

export const SUPPORT_WEBSITE = "https://support.findspace.com";
