import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import rootReducer from "../rootReducer";
import rootSaga from "../rootSaga";
import { loadState, saveState } from "../utilities";
import { throttle } from "lodash";

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initial) {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const previousState = initial ? initial : loadState();
  const store = createStore(
    rootReducer,
    previousState,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  store.subscribe(throttle(() => {
    saveState({
      user: store.getState().user
    });
  }, 1000));

  sagaMiddleware.run(rootSaga);

  return store;
}
