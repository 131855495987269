import { takeEvery, call, put } from "redux-saga/effects";
import { startProgress, stopProgress } from "./sagaUtils";
import uuid from "uuid/v4";

import * as buildingAnnouncementConstants from "../constants/buildingAnnouncementConstants";
import BuildingAnnouncementService from "../services/buildingAnnouncementService";

function* fetchBuildingAnnouncements(action) {
  const progressId = uuid();
  yield put(startProgress(progressId));

  try {
    const announcements = yield call(
      BuildingAnnouncementService.fetchBuildingAnnouncements,
      action.payload
    );
    yield put({
      type: buildingAnnouncementConstants.FETCH_ANNOUNCEMENTS_SUCCESSFUL,
      payload: announcements
    });
  } catch (error) {
    yield put({
      type: buildingAnnouncementConstants.FETCH_ANNOUNCEMENTS_FAILED,
      error
    });
  } finally {
    yield put(stopProgress(progressId));
  }
}

function* updateBuildingAnnouncement(action) {
  const { announcementId, announcement, onSuccess } = action.payload;
  const progressId = uuid();
  yield put(startProgress(progressId));

  try {
    yield call(
      BuildingAnnouncementService.updateBuildingAnnouncement,
      announcementId,
      announcement
    );
    yield put({
      type: buildingAnnouncementConstants.UPDATE_ANNOUNCEMENT_SUCCESSFUL
    });
    !!onSuccess && onSuccess();
  } catch (error) {
    yield put({
      type: buildingAnnouncementConstants.UPDATE_ANNOUNCEMENT_FAILED,
      error
    });
  } finally {
    yield put(stopProgress(progressId));
  }
}

export function* fetchBuildingAnnouncementsWatcher() {
  yield takeEvery(
    buildingAnnouncementConstants.FETCH_ANNOUNCEMENTS,
    fetchBuildingAnnouncements
  );
}

export function* updateBuildingAnnouncementWatcher() {
  yield takeEvery(
    buildingAnnouncementConstants.UPDATE_ANNOUNCEMENT,
    updateBuildingAnnouncement
  );
}
