import * as buildingsTourConstants from "../constants/buildingsVirtualTourConstants";

const initialState = {
  virtualTours: [],
  createTour: {
    submitting: false
  },
  updateTour: {
    submitting: false
  },
  fetching: false,
  selectedTour: {}
};

const reducer = function buildingsReducer(state = initialState, action){
  switch (action.type) {
    // CREATE
    case buildingsTourConstants.CREATE_BUILDING_TOURS: {
      return Object.assign({}, state, {
        createTour: {
          submitting: true
        }
      });
    }
    case buildingsTourConstants.CREATE_BUILDING_TOURS_SUCCESSFUL: {
      return Object.assign({}, state, {
        createTour: {
          submitting: false
        }
      });
    }
    case buildingsTourConstants.CREATE_BUILDING_TOURS_FAILED: {
      return Object.assign({}, state, {
        createTour: {
          submitting: false
        }
      });
    }

    // FETCH
    case buildingsTourConstants.FETCH_BUILDING_TOURS: {
      return Object.assign({}, state, {
        fetching: true
      });
    }

    case buildingsTourConstants.FETCH_BUILDING_TOURS_SUCCESSFUL: {
      return Object.assign({}, state, {
        fetching: false,
        virtualTours: action.payload
      });
    }

    case buildingsTourConstants.FETCH_BUILDING_TOURS_FAILED: {
      return Object.assign({}, state, {
        fetching: false
      });
    }

    // UPDATE
    case buildingsTourConstants.UPDATE_BUILDING_TOURS: {
      return Object.assign({}, state, {
        updateTour: {
          submitting: true
        }
      });
    }

    case buildingsTourConstants.UPDATE_BUILDING_TOURS_SUCCESSFUL: {
      return Object.assign({}, state, {
        updateTour: {
          submitting: false
        }
      });
    }

    case buildingsTourConstants.UPDATE_BUILDING_TOURS_FAILED: {
      return Object.assign({}, state, {
        updateTour: {
          submitting: false
        }
      });
    }

    // DELETE
    case buildingsTourConstants.DELETE_BUILDING_TOURS: {
      return Object.assign({}, state, {
        deleteTour: {
          submitting: true
        }
      });
    }

    case buildingsTourConstants.DELETE_BUILDING_TOURS_SUCCESSFUL: {
      return Object.assign({}, state, {
        deleteTour: {
          submitting: false
        }
      });
    }

    case buildingsTourConstants.DELETE_BUILDING_TOURS_FAILED: {
      return Object.assign({}, state, {
        deleteTour: {
          submitting: false
        }
      });
    }

    default:
      return state;
  }
};

export default reducer;
