export const FETCH_BUILDING = "buildings/FETCH_BUILDING";
export const FETCH_BUILDING_SUCCESSFUL = "buildings/FETCH_BUILDING_SUCCESSFUL";
export const FETCH_BUILDING_FAILED = "buildings/FETCH_BUILDING_FAILED";

export const FETCH_BUILDINGS = "buildings/FETCH_BUILDINGS";
export const FETCH_BUILDINGS_SUCCESSFUL =
  "buildings/FETCH_BUILDINGS_SUCCESSFUL";
export const FETCH_BUILDINGS_FAILED = "buildings/FETCH_BUILDINGS_FAILED";

export const UPDATE_BUILDING = "buildings/UPDATE_BUILDING";
export const UPDATE_BUILDING_SUCCESSFUL =
  "buildings/UPDATE_BUILDING_SUCCESSFUL";
export const UPDATE_BUILDING_FAILED = "buildings/UPDATE_BUILDING_FAILED";

export const FETCH_BUILDING_AFTER_UPDATE =
  "buildings/FETCH_BUILDING_AFTER_UPDATE";
export const FETCH_BUILDING_AFTER_UPDATE_SUCCESSFUL =
  "buildings/FETCH_BUILDING_AFTER_UPDATE_SUCCESSFUL";
export const FETCH_BUILDING_AFTER_UPDATE_FAILED =
  "buildings/FETCH_BUILDING_AFTER_UPDATE_FAILED";

export const SET_FAVOURITE = "buildings/SET_FAVOURITE";
export const SET_FAVOURITE_SUCCESSFUL = "buildings/SET_FAVOURITE_SUCCESSFUL";
export const SET_FAVOURITE_FAILED = "buildings/SET_FAVOURITE_FAILED";

export const UNSET_FAVOURITE = "buildings/UNSET_FAVOURITE";
export const UNSET_FAVOURITE_SUCCESSFUL =
  "buildings/UNSET_FAVOURITE_SUCCESSFUL";
export const UNSET_FAVOURITE_FAILED = "buildings/UNSET_FAVOURITE_FAILED";

export const FETCH_BUILDINGS_FILTER_ITEMS =
  "buildings/FETCH_BUILDINGS_FILTER_ITEMS";
export const FETCH_BUILDINGS_FILTER_ITEMS_SUCCESSFUL =
  "buildings/FETCH_BUILDINGS_FILTER_ITEMS_SUCCESSFUL";
export const FETCH_BUILDINGS_FILTER_ITEMS_FAILED =
  "buildings/FETCH_BUILDINGS_LOVS_CITIES_FAILED";

export const SELECT_FILTER_DROPDOWN_ITEM =
  "buildings/SELECT_FILTER_DROPDOWN_ITEM";
export const SAVE_FILTERS = "buildings/SAVE_FILTERS";
export const RESET_FILTERS = "buildings/RESET_FILTERS";

export const TOGGLE_FILTER_ITEM = "buildings/TOGGLE_FILTER_ITEM";
export const AREA_SLIDER_CHANGE = "buildings/AREA_SLIDER_CHANGE";

export const CREATE_BUILDING = "buildings/CREATE_BUILDING";
export const CREATE_BUILDING_SUCCESSFUL =
  "buildings/CREATE_BUILDING_SUCCESSFUL";
export const CREATE_BUILDING_FAILED = "buildings/CREATE_BUILDING_FAILED";

export const DELETE_BUILDING = "buildings/DELETE_BUILDING";
export const DELETE_BUILDING_SUCCESSFUL =
  "buildings/DELETE_BUILDING_SUCCESSFUL";
export const DELETE_BUILDING_FAILED = "buildings/DELETE_BUILDING_FAILED";

export const FETCH_BUILDING_GROUPS = "buildings/FETCH_BUILDING_GROUPS";
export const FETCH_BUILDING_GROUPS_SUCCESSFUL =
  "buildings/FETCH_BUILDING_GROUPS_SUCCESSFUL";
export const FETCH_BUILDING_GROUPS_FAILED =
  "buildings/FETCH_BUILDING_GROUPS_FAILED";

export const UNMOUNT_BUILDING_VIEW = "buildings/UNMOUNT_BUILDING_VIEW"; // For a single building view
export const UNMOUNT_BUILDINGS_VIEW = "buildings/UNMOUNT_BUILDINGS_VIEW"; // For a buildings view

export const FETCH_SUITES_FOR_BUILDING = "buildings/FETCH_SUITES_FOR_BUILDING";
export const FETCH_SUITES_FOR_BUILDING_SUCCESSFUL =
  "buildings/FETCH_SUITES_FOR_BUILDING_SUCCESSFUL";
export const FETCH_SUITES_FOR_BUILDING_FAILED =
  "buildings/FETCH_SUITES_FOR_BUILDING_FAILED";

//TODO: Use building types LOV instead
export const BUILDING_TYPES = [
  {
    text: "Office",
    icon: "icon-office",
    value: "O"
  },
  {
    text: "Retail",
    icon: "icon-retail",
    value: "R"
  },
  {
    text: "Industrial",
    icon: "icon-industrial",
    value: "I"
  },
  {
    text: "Residential",
    icon: "icon-residential",
    value: "E"
  },
  {
    text: "Land",
    icon: "icon-land",
    value: "L"
  }
];

export const APPLY_BUILDINGS_VIEW_FILTER =
  "buildings/APPLY_BUILDINGS_VIEW_FILTER";
export const BUILDINGS_VIEW_FILTER = {
  ALL: "A",
  PROMOTED: "P"
};

export const buildingType = {
  OFFICE: "O",
  RESIDENTIAL: "E",
  RETAIL: "R",
  INDUSTRIAL: "I",
  LAND: "L"
};

export const FETCH_PROMOTED_BUILDINGS = "buildings/FETCH_PROMOTED_BUILDINGS";
export const FETCH_PROMOTED_BUILDINGS_SUCCESSFUL =
  "buildings/FETCH_PROMOTED_BUILDINGS_SUCCESSFUL";
export const FETCH_PROMOTED_BUILDINGS_FAILED =
  "buildings/FETCH_PROMOTED_BUILDINGS_FAILED";

export const PROMOTE_BUILDING = "buildings/PROMOTE_BUILDING";
export const PROMOTE_BUILDING_SUCCESSFUL =
  "buildings/PROMOTE_BUILDING_SUCCESSFUL";
export const PROMOTE_BUILDING_FAILED = "buildings/PROMOTE_BUILDING_FAILED";

export const UNPROMOTE_BUILDING = "buildings/UNPROMOTE_BUILDING";
export const UNPROMOTE_BUILDING_SUCCESSFUL =
  "buildings/UNPROMOTE_BUILDING_SUCCESSFUL";
export const UNPROMOTE_BUILDING_FAILED = "buildings/UNPROMOTE_BUILDING_FAILED";

export const UPDATE_PROMOTED_BUILDING_LIST =
  "buildings/UPDATE_PROMOTED_BUILDING_LIST";
export const UPDATE_PROMOTED_BUILDING_LIST_SUCCESSFUL =
  "buildings/UPDATE_PROMOTED_BUILDING_LIST_SUCCESSFUL";
export const UPDATE_PROMOTED_BUILDING_LIST_FAILED =
  "buildings/UPDATE_PROMOTED_BUILDING_LIST_FAILED";

// New Building Wizard Constants

export const TOGGLE_NEW_BUILDING_WIZARD =
  "buildings/TOGGLE_NEW_BUILDING_WIZARD";

// Extended Building Constants

export const FETCH_EXTENDED_BUILDING = "buildings/FETCH_EXTENDED_BUILDING";
export const FETCH_EXTENDED_BUILDING_SUCCESSFUL =
  "buildings/FETCH_EXTENDED_BUILDING_SUCCESSFUL";
export const FETCH_EXTENDED_BUILDING_FAILED =
  "buildings/FETCH_EXTENDED_BUILDING_FAILED";

export const UPDATE_EXTENDED_BUILDING = "buildings/UPDATE_EXTENDED_BUILDING";
export const UPDATE_EXTENDED_BUILDING_SUCCESSFUL =
  "buildings/UPDATE_EXTENDED_BUILDING_SUCCESSFUL";
export const UPDATE_EXTENDED_BUILDING_FAILED =
  "buildings/UPDATE_EXTENDED_BUILDING_FAILED";

export const CALCULATE_ADDITIONAL_RENT = "buildings/CALCULATE_ADDITIONAL_RENT";
export const CALCULATE_ADDITIONAL_RENT_SUCCESSFUL = 
  "buildings/CALCULATE_ADDITIONAL_RENT_SUCCESSFUL";
export const CALCULATE_ADDITIONAL_RENT_FAILED = 
  "buildings/CALCULATE_ADDITIONAL_RENT_FAILED";

export const AUTO_COMPUTE_VALUES = "buildings/AUTO_COMPUTE_VALUES";
export const AUTO_COMPUTE_VALUES_SUCCESSFUL =
  "buildings/AUTO_COMPUTE_VALUES_SUCCESSFUL";
export const AUTO_COMPUTE_VALUES_FAILED =
  "buildings/AUTO_COMPUTE_VALUES_FAILED";

export const ARCHIVE_BUILDING = "buildings/ARCHIVE_BUILDING";
export const ARCHIVE_BUILDING_SUCCESSFUL =
  "buildings/ARCHIVE_BUILDING_SUCCESSFUL";
export const ARCHIVE_BUILDING_FAILED = "buildings/ARCHIVE_BUILDING_FAILED";

export const UNARCHIVE_BUILDING = "buildings/UNARCHIVE_BUILDING";
export const UNARCHIVE_BUILDING_SUCCESSFUL =
  "buildings/UNARCHIVE_BUILDING_SUCCESSFUL";
export const UNARCHIVE_BUILDING_FAILED = "buildings/UNARCHIVE_BUILDING_FAILED";
