import * as authActionTypes from "../Auth/constants";

const initialState = {
  requesting: false,
  successful: false,
  messages: [],
  errors: {}
};

const reducer = function login(state = initialState, action) {
  switch (action.type) {
    case authActionTypes.AUTHENTICATE_USER:
      return Object.assign({}, state, {
        requesting: true
      });

    case authActionTypes.AUTHENTICATION_SUCCESSFUL:
      return Object.assign({}, state, {
        requesting: false,
        successful: true
      });

    case authActionTypes.AUTHENTICATION_FAILED:
      return Object.assign({}, state, {
        requesting: false,
        successful: false,
        errors: action.error.response.data
      });

    default:
      return state;
  }
};

export default reducer;
