import { takeEvery, call, put, take } from "redux-saga/effects";

import * as suiteMediaConstants from "../constants/suiteMediaConstants";
import SuiteMediaService from "../services/suiteMediaService";

function* fetchSuiteMedia(action) {
  try {
    const result = yield call(
      SuiteMediaService.fetchSuiteMedia,
      action.payload
    );
    yield put({
      type: suiteMediaConstants.FETCH_SUITE_MEDIA_SUCCESSFUL,
      payload: result
    });
  } catch (error) {
    yield put({ type: suiteMediaConstants.FETCH_SUITE_MEDIA_FAILED, error });
  }
}

function* uploadSuiteMedia(action) {
  try {
    const channel = yield call(
      SuiteMediaService.uploadSuiteMedia,
      action.payload.data
    );
    while (true) {
      const { progress = 0, err, success } = yield take(channel);
      if (err) {
        /**
         * Manually complete the progress as the final progress event may have been debounced
         * in the service call by lodash
         */
        yield put({
          type: suiteMediaConstants.SUITE_MEDIA_UPLOAD_PROGRESS,
          payload: {
            uid: action.payload.upload_uid,
            progress: 1
          }
        });
        yield put({
          type: suiteMediaConstants.UPLOAD_SUITE_MEDIA_FAILED,
          payload: {
            uid: action.payload.upload_uid,
            error: err
          }
        });
        return;
      }
      if (success) {
        /**
         * Manually complete the progress as the final progress event may have been debounced
         * in the service call by lodash
         */
        yield put({
          type: suiteMediaConstants.SUITE_MEDIA_UPLOAD_PROGRESS,
          payload: {
            uid: action.payload.upload_uid,
            progress: 1
          }
        });
        yield put({
          type: suiteMediaConstants.UPLOAD_SUITE_MEDIA_SUCCESSFUL,
          payload: {
            uid: action.payload.upload_uid
          }
        });
        yield put({
          type: suiteMediaConstants.FETCH_SUITE_MEDIA,
          payload: {
            suiteId: action.payload.suiteId
          }
        });
        return;
      }
      yield put({
        type: suiteMediaConstants.SUITE_MEDIA_UPLOAD_PROGRESS,
        payload: {
          uid: action.payload.upload_uid,
          progress: progress
        }
      });
    }
  } catch (error) {
    /**
     * Manually complete the progress as the final progress event may have been debounced
     * in the service call by lodash
     */
    yield put({
      type: suiteMediaConstants.SUITE_MEDIA_UPLOAD_PROGRESS,
      payload: {
        uid: action.payload.upload_uid,
        progress: 1
      }
    });
    yield put({
      type: suiteMediaConstants.UPLOAD_SUITE_MEDIA_FAILED,
      payload: {
        uid: action.payload.upload_uid,
        error: error
      }
    });
  } finally {
    return;
  }
}

function* deleteSuiteMedia(action) {
  try {
    const result = yield call(
      SuiteMediaService.deleteSuiteMedia,
      action.payload
    );
    yield put({
      type: suiteMediaConstants.DELETE_SUITE_MEDIA_SUCCESSFUL,
      payload: result
    });
    yield put({
      type: suiteMediaConstants.FETCH_SUITE_MEDIA,
      payload: {
        suiteId: action.payload.suiteId
      }
    });
  } catch (error) {
    yield put({ type: suiteMediaConstants.DELETE_SUITE_MEDIA_FAILED, error });
  }
}

function* updateSuiteMedia(action) {
  const { mediaId, media, onSuccess } = action.payload;

  try {
    const result = yield call(
      SuiteMediaService.updateSuiteMedia,
      mediaId,
      media
    );
    yield put({
      type: suiteMediaConstants.UPDATE_SUITE_MEDIA_SUCCESSFUL,
      payload: result
    });

    !!onSuccess && onSuccess();
  } catch (error) {
    yield put({ type: suiteMediaConstants.UPDATE_SUITE_MEDIA_FAILED, error });
  }
}

function* setPrimaryPicture(action) {
  try {
    const result = yield call(
      SuiteMediaService.setPrimaryPicture,
      action.payload
    );
    yield put({
      type: suiteMediaConstants.SET_PRIMARY_PICTURE_SUCCESSFUL,
      payload: result
    });
    yield put({
      type: suiteMediaConstants.FETCH_SUITE_MEDIA,
      payload: {
        suiteId: action.payload.suiteId
      }
    });
  } catch (error) {
    yield put({ type: suiteMediaConstants.SET_PRIMARY_PICTURE_FAILED, error });
  }
}

function* toggleSuitePublishMediaStatus(action) {
  const { mediaId, publish, suiteId } = action.payload;
  try {
    const result = yield call(
      publish
        ? SuiteMediaService.publishMedia
        : SuiteMediaService.unpublishMedia,
      mediaId
    );
    yield put({
      type: suiteMediaConstants.TOGGLE_PUBLISH_MEDIA_STATUS_SUCCESSFUL,
      payload: result
    });
    yield put({
      type: suiteMediaConstants.FETCH_SUITE_MEDIA,
      payload: {
        suiteId: suiteId
      }
    });
  } catch (error) {
    yield put({
      type: suiteMediaConstants.TOGGLE_PUBLISH_MEDIA_STATUS_FAILED,
      error
    });
  }
}

function* reorderSuiteMedia(action) {
  const { data, suiteId, onSuccess } = action.payload;
  try {
    const result = yield call(SuiteMediaService.reorderMedia, suiteId, data);
    yield put({
      type: suiteMediaConstants.REORDER_SUITE_MEDIA_SUCCESSFUL,
      payload: result
    });
    !!onSuccess && onSuccess();
  } catch (error) {
    yield put({ type: suiteMediaConstants.REORDER_SUITE_MEDIA_FAILED, error });
  }
}

export function* fetchSuiteMediaWatcher() {
  yield takeEvery(suiteMediaConstants.FETCH_SUITE_MEDIA, fetchSuiteMedia);
}

export function* uploadSuiteMediaWatcher() {
  yield takeEvery(suiteMediaConstants.UPLOAD_SUITE_MEDIA, uploadSuiteMedia);
}

export function* deleteSuiteMediaWatcher() {
  yield takeEvery(suiteMediaConstants.DELETE_SUITE_MEDIA, deleteSuiteMedia);
}

export function* updateSuiteMediaWatcher() {
  yield takeEvery(suiteMediaConstants.UPDATE_SUITE_MEDIA, updateSuiteMedia);
}

export function* setSuitePrimaryPictureWatcher() {
  yield takeEvery(suiteMediaConstants.SET_PRIMARY_PICTURE, setPrimaryPicture);
}

export function* toggleSuitePublishMediaStatusWatcher() {
  yield takeEvery(
    suiteMediaConstants.TOGGLE_PUBLISH_MEDIA_STATUS,
    toggleSuitePublishMediaStatus
  );
}

export function* reorderSuiteMediaWatcher() {
  yield takeEvery(suiteMediaConstants.REORDER_SUITE_MEDIA, reorderSuiteMedia);
}
