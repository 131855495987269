import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

// Internationalization Support
import {
  IntlProvider,
  addLocaleData,
  FormattedNumber,
  FormattedMessage
} from "react-intl";
import en from "react-intl/locale-data/en";
import fr from "react-intl/locale-data/fr";
//import es from 'react-intl/locale-data/es';
import messages from "../../translations/messages";
import { ENGLISH_LOCALE, FRENCH_LOCALE } from "../../constants/localeConstants";
import moment from "moment";
import "moment/locale/fr";

addLocaleData([...en, ...fr]);

export const languageToLocale = (input, defaultLanguage = "e") => {
  const languageCodeToLocale = {
    e: ENGLISH_LOCALE,
    f: FRENCH_LOCALE
  };
  try {
    return languageCodeToLocale[input.toLowerCase()];
  } catch (err) {
    // Locale not recognized, resorting to default language
    return languageCodeToLocale[defaultLanguage];
  }
};

export const FormattedUnit = props => {
  return (
    <span>
      <FormattedMessage
        values={{
          value: props.value
        }}
        id="lang.formatted-unit.id"
      />{" "}
      {props.units}
    </span>
  );
};

// Due to the way FormattedNumber outputs currency values in non-English
// locales (i.e. it always renders both the symbol and currency type,
// e.g. $US in French), we have changed the implementation to display
// just a $ symbol based on either English or French language.
export const CurrencyNumber = ({ language, value }) => {
  return (
    <Fragment>
      {language === "E" && <span>$</span>}
      <FormattedNumber
        value={value}
        minimumFractionDigits={2}
        maximumFractionDigits={2}
      />
      {language === "F" && <span> $</span>}
    </Fragment>
  );
};

export const LeaseRentUnits = ({ rent }) => {
  return (
    <Fragment>
      <FormattedMessage
        id="rentroll.bucket-list.bucket.lease-rent.units-label"
        defaultMessage={`{leaseRent, number} psf`}
        values={{
          leaseRent: rent
        }}
      />
    </Fragment>
  );
};

class Lang extends Component {
  getLanguage() {
    return languageToLocale(this.props.language);
  }

  render() {
    const language = this.getLanguage();
    moment.locale(language);
    return (
      <IntlProvider
        key={language}
        locale={language}
        messages={messages[language]}
      >
        {this.props.children}
      </IntlProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    language: state.user.details.language
  };
}

export const Currency = connect(mapStateToProps)(CurrencyNumber);
export default connect(mapStateToProps)(Lang);
