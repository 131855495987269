export const FETCH_RENT_ROLL = "rent-roll/FETCH_RENT_ROLL";
export const FETCH_RENT_ROLL_SUCCESSFUL =
  "rent-roll/FETCH_RENT_ROLL_SUCCESSFUL";
export const FETCH_RENT_ROLL_FAILED = "rent-roll/FETCH_RENT_ROLL_FAILED";

export const SAVE_FILTERS = "rent-roll/SAVE_FILTERS";
export const RESET_FILTERS = "rent-roll/RESET_FILTERS";

export const TOGGLE_BUCKET = "rent-roll/TOGGLE_BUCKET";

export const TOGGLE_FILTER_ITEM = "rent-roll/TOGGLE_FILTER_ITEM";
export const AREA_SLIDER_CHANGE = "rent-roll/AREA_SLIDER_CHANGE";
