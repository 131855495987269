import http from "../auth/http";

const BuildingReportsService = () => {
  const fetchOccupancyReport = request =>
    http
      .get(`/api/v1/buildingreports/${request.buildingId}/occupancyreport/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const fetchDistributionReport = request =>
    http
      .get(`/api/v1/buildingreports/${request.buildingId}/distributionreport/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const fetchLeaseExpiryProfileReport = request =>
    http
      .get(
        `/api/v1/buildingreports/${
          request.buildingId
        }/leaseexpiryprofilereport/`
      )
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const fetchLargestTenantReport = request =>
    http
      .get(`/api/v1/buildingreports/${request.buildingId}/largesttenantreport/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const fetchVacantSuitesReport = request =>
    http
      .get(`/api/v1/buildingreports/${request.buildingId}/vacancyreport/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  return {
    fetchOccupancyReport,
    fetchDistributionReport,
    fetchLeaseExpiryProfileReport,
    fetchLargestTenantReport,
    fetchVacantSuitesReport
  };
};

const api = BuildingReportsService();
export default api;
