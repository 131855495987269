import http from "../auth/http";

function getFiltersQueryString(filters) {
  var queryString = "";
  if (!!filters) {
    if (!!filters.cities && filters.cities.length) {
      queryString = queryString.concat(
        "&city=" +
          filters.cities.map(city => encodeURIComponent(city)).join(",")
      );
    }

    if (!!filters.suiteTypes && filters.suiteTypes.length) {
      queryString = queryString.concat(
        "&_type=" +
          filters.suiteTypes.map(type => encodeURIComponent(type)).join(",")
      );
    }

    // TODO: This contacts filter does not work right now. Check!
    if (!!filters.contacts && filters.contacts.length) {
      queryString = queryString.concat(
        "&contacts=" +
          filters.contacts.map(contact => encodeURIComponent(contact)).join(",")
      );
    }

    // Area range filters
    !!filters.area_max &&
      (queryString = queryString.concat("&area__lte=" + filters.area_max));
    !!filters.area_min &&
      (queryString = queryString.concat("&area__gte=" + filters.area_min));
  }

  return queryString;
}

const SuitesService = () => {
  const fetchSuites = request => {
    var { page, ordering } = request;
    page = page || 1;

    let url = `api/v1/suites/?page=${page}`;
    url = !!ordering ? url.concat(`&ordering=${ordering}`) : url;

    let filtersQueryString = getFiltersQueryString(request.filters);
    url = !!filtersQueryString ? url.concat(filtersQueryString) : url;

    return http
      .get(url)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const updateSuite = request =>
    http
      .patch(`/api/v1/suites/${request.suiteId}/`, request.suite)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const deleteSuite = request =>
    http
      .delete(`/api/v1/suites/${request.suiteId}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const fetchSuitesFilterItems = () =>
    http
      .get(`/api/v1/suites/filteritems/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const splitSuite = request =>
    http
      .patch(`/api/v1/suites/${request.suiteId}/split/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const addNewSuite = request =>
    http
      .post("/api/v1/suites/", { floor: request.floor })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const addSuiteLeft = request =>
    http
      .post(`/api/v1/suites/${request.suiteId}/addsuiteleft/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const addSuiteRight = request =>
    http
      .post(`/api/v1/suites/${request.suiteId}/addsuiteright/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const fetchStackingSuiteDetails = request =>
    http
      .get(`/api/v1/suites/${request.suiteId}/stackingsuitedetail/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  return {
    fetchSuites,
    updateSuite,
    deleteSuite,
    fetchSuitesFilterItems,
    splitSuite,
    addSuiteLeft,
    addSuiteRight,
    addNewSuite,
    fetchStackingSuiteDetails
  };
};

const api = SuitesService();
export default api;
