import http from "../auth/http";

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

const searchResults = [
  {
    title: "120 Bremner St, Toronto M5A 1R6",
    description: "120 Bremner St, Toronto M5A 1R6",
    type: "B",
    buildingId: 1
  },
  {
    title: "Suite 100, 120 Bremner St",
    description: "Vacant",
    type: "S",
    buildingId: 1,
    suiteId: 2
  },
  {
    title: "Suite 512, 120 Bremner St",
    description: "Lease expiring in Sep 2017",
    type: "S",
    buildingId: 1,
    suiteId: 3
  },
  {
    title: "Ashley Good, Checking",
    description: "Ashley Good is interested in 120 Bremner St",
    type: "C",
    contactId: 1
  }
];

const SearchService = () => {
  const searchApp_fake = request =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(searchResults.slice(0, getRandomInt(searchResults.length)));
      }, 500);
    });

  const searchApp = query =>
    http
      .get(`/api/v1/search/?query=${encodeURIComponent(query)}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  return {
    searchApp,
    searchApp_fake
  };
};

const api = SearchService();
export default api;
