import { call, put, takeEvery } from "redux-saga/effects";

import * as suitesVirtualTourConstants from "../constants/suitesVirtualTourConstants";
import SuiteVirtualTourService from "../services/suitesVirtualTourService";

function* createSuiteTour(action) {
  const { tour, onSuccess } = action.payload;

  try {
    const result = yield call(SuiteVirtualTourService.createTour, tour);
    yield put({
      type: suitesVirtualTourConstants.CREATE_SUITE_TOURS_SUCCESSFUL,
      payload: result
    });

    !!onSuccess && onSuccess(result);
  } catch (error) {
    yield put({ type: suitesVirtualTourConstants.CREATE_SUITE_TOURS_FAILED, error });
  }
}

function* fetchSuiteTours(action){
  const { id, onSuccess } = action.payload;

  try {
    const tours = yield call(SuiteVirtualTourService.fetchTours, { id: id });
    yield put({
      type: suitesVirtualTourConstants.FETCH_SUITE_TOURS_SUCCESSFUL,
      payload: tours
    });

    !!onSuccess && onSuccess();
  } catch (error) {
    yield put({ type: suitesVirtualTourConstants.FETCH_SUITE_TOURS_FAILED, error });
  }
}

function* updateSuiteTour(action) {
  const { onSuccess } = action.payload;

  try {
    const result = yield call(SuiteVirtualTourService.updateTour, action.payload);
    yield put({
      type: suitesVirtualTourConstants.UPDATE_SUITE_TOURS_SUCCESSFUL,
      payload: result
    });

    onSuccess && onSuccess(result);
  } catch (error) {
    yield put({ type: suitesVirtualTourConstants.UPDATE_SUITE_TOURS_FAILED, error });
  }
}

function* deleteSuiteTour(action) {
  const { onSuccess } = action.payload;

  try {
    const result = yield call(SuiteVirtualTourService.deleteTour, action.payload);
    yield put({
      type: suitesVirtualTourConstants.DELETE_SUITE_TOURS_SUCCESSFUL,
      payload: {
        id: action.payload.tourId
      }
    });

    !!onSuccess && onSuccess();
  } catch (error) {
    yield put({ type: suitesVirtualTourConstants.DELETE_SUITE_TOURS_FAILED, error });
  }
}

export function* createSuiteTourWatcher() {
  yield takeEvery(suitesVirtualTourConstants.CREATE_SUITE_TOURS, createSuiteTour);
}
export function* fetchSuiteToursWatcher() {
  yield takeEvery(suitesVirtualTourConstants.FETCH_SUITE_TOURS, fetchSuiteTours);
}
export function* updateSuiteTourWatcher() {
  yield takeEvery(suitesVirtualTourConstants.UPDATE_SUITE_TOURS, updateSuiteTour);
}
export function* deleteSuiteTourWatcher() {
  yield takeEvery(suitesVirtualTourConstants.DELETE_SUITE_TOURS, deleteSuiteTour);
}

