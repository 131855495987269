import * as appConstants from "../constants/appConstants";

export const startProgress = progressId => ({
  type: appConstants.START_ASYNC_ACTION,
  progressId: progressId
});
export const stopProgress = progressId => ({
  type: appConstants.STOP_ASYNC_ACTION,
  progressId: progressId
});
