import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory";

const fm = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [
        {
          kind: "UNION",
          name: "BuildingDetails",
          possibleTypes: [
            { name: "BuildingOfficeType" },
            { name: "BuildingRetailType" },
            { name: "BuildingIndustrialType" }
          ]
        },
        {
          kind: "UNION",
          name: "suiteBuildingDetails",
          possibleTypes: [
            { name: "SuiteOfficeType" },
            { name: "SuiteRetailType" },
            { name: "SuiteIndustrialType" }
          ]
        }
      ]
    }
  }
});

export default fm;
