import { call, put, takeEvery } from "redux-saga/effects";
import BuildingsService from "../../services/buildingsService";
import UserService from "../../services/userService";
import * as actionTypes from "./constants";

function* fetchUser(action) {
  const userId = action.payload;
  try {
    const user = yield call(UserService.fetchUser, userId);
    yield put({ type: actionTypes.FETCH_USER_SUCCESSFUL, payload: user });
  } catch (error) {
    yield put({ type: actionTypes.FETCH_USER_FAILED, error });
  }
}

function* fetchOktaUser(action) {
  try {
    const user = yield call(UserService.fetchOktaAuthUser);
    yield put({ type: actionTypes.FETCH_OKTA_USER_SUCCESSFUL, payload: user });
  } catch (error) {
    yield put({ type: actionTypes.FETCH_OKTA_USER_FAILED, error });
  }
}

function* createUser(action) {
  try {
    const createdUser = yield call(UserService.createUser, action.payload.user);
    yield put({
      type: actionTypes.CREATE_USER_SUCCESSFUL,
      payload: createdUser
    });
    yield put({ type: actionTypes.FETCH_USERS, payload: {} });
    action.payload.onSuccess(createdUser.id);
  } catch (error) {
    yield put({ type: actionTypes.CREATE_USER_FAILED, error });
  }
}

function* editUser(action) {
  const {
    user: { id: userId, ...userDetails },
    onSuccess
  } = action.payload;
  try {
    const updatedUser = yield call(UserService.editUser, userId, userDetails);
    yield put({ type: actionTypes.EDIT_USER_SUCCESSFUL, payload: updatedUser });

    !!onSuccess && onSuccess();
  } catch (error) {
    yield put({ type: actionTypes.EDIT_USER_FAILED, error });
  }
}

function* deleteUser(action) {
  const { userId, onSuccess } = action.payload;
  try {
    const result = yield call(UserService.deleteUser, userId);
    yield put({ type: actionTypes.DELETE_USER_SUCCESSFUL, payload: result });
    onSuccess();
  } catch (error) {
    yield put({ type: actionTypes.DELETE_USER_FAILED, error });
  }
}

function* fetchUsers(action) {
  try {
    const users = yield call(UserService.fetchUsers, action.payload);
    yield put({ type: actionTypes.FETCH_USERS_SUCCESSFUL, payload: users });
  } catch (error) {
    yield put({ type: actionTypes.FETCH_USERS_FAILED, error });
  }
}

function* fetchUserDetails(action) {
  try {
    const userDetails = yield call(UserService.fetchUser, action.payload);
    yield put({
      type: actionTypes.FETCH_USER_DETAILS_SUCCESSFUL,
      payload: userDetails
    });
  } catch (error) {
    yield put({ type: actionTypes.FETCH_USER_DETAILS_FAILED, error });
  }
}

function* editUserDetails(action) {
  const { userId, user, onSuccess } = action.payload;
  try {
    const userDetails = yield call(UserService.editUser, userId, user);
    yield put({
      type: actionTypes.EDIT_USER_DETAILS_SUCCESSFUL,
      payload: userDetails
    });
    yield put({ type: actionTypes.FETCH_USER_DETAILS, payload: userId });
    !!onSuccess && onSuccess();
  } catch (error) {
    yield put({ type: actionTypes.EDIT_USER_DETAILS_FAILED, error });
  }
}

function* fetchUserBuildings(action) {
  try {
    const userBuildings = yield call(
      UserService.fetchUserBuildings,
      action.payload
    );
    yield put({
      type: actionTypes.FETCH_USER_BUILDINGS_SUCCESSFUL,
      payload: userBuildings
    });
  } catch (error) {
    yield put({ type: actionTypes.FETCH_USER_BUILDINGS_FAILED, error });
  }
}

function* deleteUserBuilding(action) {
  const { userId, buildingId, onSuccess } = action.payload;
  try {
    const result = yield call(
      UserService.deleteUserBuilding,
      userId,
      buildingId
    );
    yield put({
      type: actionTypes.DELETE_USER_BUILDING_SUCCESSFUL,
      payload: result
    });
    !!onSuccess && onSuccess();
  } catch (error) {
    yield put({ type: actionTypes.DELETE_USER_BUILDING_FAILED, error });
  }
}

function* editUserRoleType(action) {
  try {
    const result = yield call(UserService.editUserRole, action.payload);
    yield put({
      type: actionTypes.EDIT_USER_ROLE_TYPE_SUCCESSFUL,
      payload: result
    });
    yield put({
      type: actionTypes.FETCH_USER_DETAILS,
      payload: action.payload.userId
    });
  } catch (error) {
    yield put({ type: actionTypes.EDIT_USER_ROLE_TYPE_FAILED, error });
  }
}

function* fetchAllBuildings(action) {
  try {
    const buildingsPage = yield call(
      BuildingsService.fetchBuildings,
      action.payload
    );
    yield put({
      type: actionTypes.FETCH_ALL_BUILDINGS_SUCCESSFUL,
      payload: buildingsPage
    });
  } catch (error) {
    yield put({ type: actionTypes.FETCH_ALL_BUILDINGS_FAILED, error });
  }
}

function* transferBuildingAssignments(action) {
  const { from, to, onSuccess } = action.payload;

  try {
    const result = yield call(
      UserService.transferBuildingAssignments,
      from,
      to
    );
    yield put({
      type: actionTypes.TRANSFER_BUILDING_ASSIGNMENTS_SUCCESSFUL,
      payload: result
    });
    !!onSuccess && onSuccess();
  } catch (error) {
    yield put({
      type: actionTypes.TRANSFER_BUILDING_ASSIGNMENTS_FAILED,
      error
    });
  }
}

function* copyBuildingAssignments(action) {
  const { from, to, onSuccess } = action.payload;

  try {
    const result = yield call(UserService.copyBuildingAssignments, from, to);
    yield put({
      type: actionTypes.COPY_BUILDING_ASSIGNMENTS_SUCCESSFUL,
      payload: result
    });
    !!onSuccess && onSuccess();
  } catch (error) {
    yield put({ type: actionTypes.COPY_BUILDING_ASSIGNMENTS_FAILED, error });
  }
}

function* addUserBuildings(action) {
  const { userId, request, onSuccess } = action.payload;

  try {
    const result = yield call(UserService.addUserBuildings, userId, request);
    yield put({
      type: actionTypes.ADD_USER_BUILDINGS_SUCCESSFUL,
      payload: result
    });
    !!onSuccess && onSuccess();
  } catch (error) {
    yield put({ type: actionTypes.ADD_USER_BUILDINGS_FAILED, error });
  }
}

export function* fetchUserWatcher() {
  yield takeEvery(actionTypes.FETCH_USER, fetchUser);
}

export function* fetchOktaUserWatcher() {
  yield takeEvery(actionTypes.FETCH_OKTA_USER, fetchOktaUser);
}

export function* createUserWatcher() {
  yield takeEvery(actionTypes.CREATE_USER, createUser);
}

export function* editUserWatcher() {
  yield takeEvery(actionTypes.EDIT_USER, editUser);
}

export function* deleteUserWatcher() {
  yield takeEvery(actionTypes.DELETE_USER, deleteUser);
}

export function* fetchUsersWatcher() {
  yield takeEvery(actionTypes.FETCH_USERS, fetchUsers);
}

export function* fetchUserDetailsWatcher() {
  yield takeEvery(actionTypes.FETCH_USER_DETAILS, fetchUserDetails);
}

export function* editUserDetailsWatcher() {
  yield takeEvery(actionTypes.EDIT_USER_DETAILS, editUserDetails);
}

export function* fetchUserBuildingsWatcher() {
  yield takeEvery(actionTypes.FETCH_USER_BUILDINGS, fetchUserBuildings);
}

export function* deleteUserBuildingWatcher() {
  yield takeEvery(actionTypes.DELETE_USER_BUILDING, deleteUserBuilding);
}

export function* editUserRoleTypeWatcher() {
  yield takeEvery(actionTypes.EDIT_USER_ROLE_TYPE, editUserRoleType);
}

export function* fetchAllBuildingsWatcher() {
  yield takeEvery(actionTypes.FETCH_ALL_BUILDINGS, fetchAllBuildings);
}

export function* transferBuildingAssignmentsWatcher() {
  yield takeEvery(
    actionTypes.TRANSFER_BUILDING_ASSIGNMENTS,
    transferBuildingAssignments
  );
}

export function* copyBuildingAssignmentsWatcher() {
  yield takeEvery(
    actionTypes.COPY_BUILDING_ASSIGNMENTS,
    copyBuildingAssignments
  );
}

export function* addUserBuildingsWatcher() {
  yield takeEvery(actionTypes.ADD_USER_BUILDINGS, addUserBuildings);
}
