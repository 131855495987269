import { takeEvery, call, put } from "redux-saga/effects";
import * as actionTypes from "./constants";
import AuthService from "../../services/authService";

function* verifyToken(action) {
  try {
    const token = yield call(AuthService.verifyToken);
    yield put({ type: actionTypes.TOKEN_VERIFICATION_SUCCESSFUL });
  } catch (error) {
    yield put({ type: actionTypes.TOKEN_VERIFICATION_FAILED, error });
    yield put({ type: actionTypes.LOGOUT_USER, error });
  }
}

function* refreshToken(action) {
  try {
    const token = yield call(AuthService.refreshToken);
    yield put({ type: actionTypes.TOKEN_REFRESH_SUCCESSFUL, payload: token });
  } catch (error) {
    yield put({ type: actionTypes.TOKEN_REFRESH_FAILED, error });
    yield put({ type: actionTypes.LOGOUT_USER, error });
  }
}

function* authenticateUser(action) {
  const { username, password } = action.payload;
  try {
    const token = yield call(AuthService.login, username, password);
    yield put({ type: actionTypes.AUTHENTICATION_SUCCESSFUL, payload: token });
  } catch (error) {
    yield put({ type: actionTypes.AUTHENTICATION_FAILED, error });
  }
}

function* authenticateOktaUser(action) {
  try {
    const token = yield call(AuthService.exchangeOktaAuthCode, action.payload);
    yield put({
      type: actionTypes.OKTA_AUTHENTICATION_SUCCESSFUL,
      payload: token
    });
  } catch (error) {
    yield put({ type: actionTypes.OKTA_AUTHENTICATION_FAILED, error });
  }
}

function* authenticateRefreshOktaUser(action) {
  try {
    const token = yield call(AuthService.refreshOktaAuthCode, action.payload);
    yield put({
      type: actionTypes.OKTA_REFRESH_AUTHENTICATION_SUCCESSFUL,
      payload: token
    });
  } catch (error) {
    yield put({ type: actionTypes.OKTA_REFRESH_AUTHENTICATION_FAILED, error });
  }
}

function* redirectOktaUser(action) {
  const { hasClientId } = action.payload;
  try {
    yield call(AuthService.oktaAuthRedirect, hasClientId);
    yield put({ type: actionTypes.OKTA_REDIRECT_SUCCESSFUL });
  } catch (error) {
    yield put({ type: actionTypes.OKTA_REDIRECT_FAILED, error });
  }
}

function* logout(action) {
  const { onSuccess, onError } = action.payload;
  try {
    yield call(AuthService.logout);
    yield put({ type: actionTypes.LOGOUT_SUCCESSFUL });
    yield put({ type: actionTypes.CLEAR_APP_STATE });
    onSuccess();
  } catch (error) {
    yield put({ type: actionTypes.LOGOUT_FAILED, error });
    onError();
  }
}

/**
 * Watcher function exports
 */

export function* refreshTokenWatcher() {
  yield takeEvery(actionTypes.REFRESH_TOKEN, refreshToken);
}

export function* verifyTokenWatcher() {
  yield takeEvery(actionTypes.VERIFY_TOKEN, verifyToken);
}

export function* tokenVerificationFailedWatcher() {
  //yield takeEvery(actionTypes.TOKEN_VERIFICATION_FAILED, logoutUser)
  //yield takeEvery("FFFF", logout)
}

export function* authenticateUserWatcher() {
  yield takeEvery(actionTypes.AUTHENTICATE_USER, authenticateUser);
}

export function* logoutWatcher() {
  yield takeEvery(actionTypes.LOGOUT_USER, logout);
}

export function* redirectOktaUserWatcher() {
  yield takeEvery(actionTypes.OKTA_REDIRECT, redirectOktaUser);
}

export function* authenticateOktaUserWatcher() {
  yield takeEvery(actionTypes.AUTHENTICATE_OKTA_USER, authenticateOktaUser);
}

export function* refreshOktaUserWatcher() {
  yield takeEvery(
    actionTypes.REFRESH_AUTHENTICATE_OKTA_USER,
    authenticateRefreshOktaUser
  );
}
