import http from "../auth/http";

const BuildingAnnouncementService = () => {
  const fetchBuildingAnnouncements = request => {
    const { buildingId } = request;

    return http
      .get(`/api/v1/buildingannouncement/?building=${buildingId}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const createBuildingAnnouncement = request => {
    return http
      .post(`/api/v1/buildingannouncement/`, request)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const deleteBuildingAnnouncement = request => {
    const { announcementId } = request;

    return http
      .delete(`/api/v1/buildingannouncement/${announcementId}/`, request)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const updateBuildingAnnouncement = (announcementId, announcement) => {
    return http
      .patch(`/api/v1/buildingannouncement/${announcementId}/`, announcement)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  return {
    fetchBuildingAnnouncements,
    createBuildingAnnouncement,
    deleteBuildingAnnouncement,
    updateBuildingAnnouncement
  };
};

const api = BuildingAnnouncementService();
export default api;
