import http from "../auth/http";

const NetworkService = () => {
  const checkConnectivity = () =>
    http
      .get("/api/v1/ping/")
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  return {
    checkConnectivity
  };
};

const api = NetworkService();
export default api;
