import http from "../auth/http";

const ExtendedBuildingService = () => {
  const fetchExtendedBuilding = buildingId =>
    http
      .get(`/api/v1/extendedbuilding/${buildingId}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const updateExtendedBuilding = (buildingId, building) =>
    http
      .patch(`/api/v1/extendedbuilding/${buildingId}/`, building)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const autoComputeValues = (buildingId, building) =>
    http
      .get(`/api/v1/extendedbuilding/${buildingId}/computevalues/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const calculateAdditionalRent = (buildingId, building) =>
    http
      .get(`/api/v1/extendedbuilding/${buildingId}/calculate_additional_total/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  return {
    fetchExtendedBuilding,
    updateExtendedBuilding,
    autoComputeValues,
    calculateAdditionalRent
  };
};

const api = ExtendedBuildingService();
export default api;
