import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import qs from "query-string";
import _ from "lodash";
import { toast } from "react-toastify";
import "offline-js/offline.min";
import ReactGA from "react-ga";
import { ProgressBarProvider } from "react-redux-progress";

import AppLoader from "./components/common/AppLoader";
import { verifyToken } from "./containers/Auth/actions";
import { connectionDown, connectionUp } from "./actions/networkActions";
import { enablePrintMode } from "./actions/appActions";
import { applyStackingPlanFilters } from "./actions/stackingPlanActions";
import AuthService from "./services/authService";
import UserService from "./services/userService";
import http from "./auth/http";

import "./App.css";
import { pendo } from "./utilities";

const Offline = window.Offline;
const PRINT_CLASS_NAME = "print-enabled";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      noToken: false
    };

    // http interceptors
    http.interceptors.request.use(
      function(config) {
        const isOktaAuth = AuthService.getOktaAuthFlag();
        let headerPrefix = "JWT";

        if (isOktaAuth) headerPrefix = "OIDC_JWT";
        const token = AuthService.getToken();
        config.headers.Authorization = `${headerPrefix} ${token}`;
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    http.interceptors.response.use(
      function(response) {
        return response;
      },
      error => {
        // Unauthorized request redirect to login page
        if (error.response.status === 401) {
          const next = this.props.location.pathname;
          AuthService.removeToken();
          this.props.history.replace(`/login?next=${next}`);
        } else if (
          error.response.status === 400 ||
          error.response.status === 403
        ) {
          if (error.response.data && error.response.data.error) {
            const errorPayload = error.response.data.error;
            let errorMessage = "";
            let isSessionExpired = false;

            /**
             * If error payload is an array, then
             * concatenate all messages separated by
             * a comma
             */
            if (_.isArray(errorPayload)) {
              errorMessage = errorPayload.join(", ");

              if (errorMessage === "Signature has expired.") {
                errorMessage = "Session expired";
                window.location.reload();
                isSessionExpired = true;
              }
              /**
               * If the error payload is a string,
               * display it as it is
               */
            } else if (_.isString(errorPayload)) {
              errorMessage = errorPayload;

              if (errorMessage === "Signature has expired.") {
                errorMessage = "Session expired";
                isSessionExpired = true;
              }
              /**
               * If the error payload is an object, check every objects
               * if the inner error is an array or a string
               */
            } else {
              _.forOwn(errorPayload, (value, key, object) => {
                if (!!errorMessage) {
                  errorMessage = errorMessage.concat("\n");
                }

                errorMessage = errorMessage.concat(key + " : ");

                if (_.isArray(value)) {
                  if (value.join(", ") === "Signature has expired.") {
                    errorMessage = "Session expired";
                    isSessionExpired = true;
                  } else {
                    errorMessage = errorMessage.concat(value.join(", "));
                  }
                } else {
                  if (value === "Signature has expired.") {
                    errorMessage = errorMessage.concat("Session expired");
                    isSessionExpired = true;
                  } else {
                    errorMessage = errorMessage.concat(value);
                  }
                }
              });
            }

            /**
             * Show the error message for all cases except:-
             * - If the error is a session expired error which resulted from
             * a token verification failure call. This is to ensure that on a fresh app load
             * the user is not presented with this error if the token is stale.
             */

            if (
              !_.isEmpty(errorMessage) &&
              !(
                isSessionExpired &&
                !this.isResponseFromTokenVerificationAPI(error.response)
              )
            ) {
              toast(
                <span className="toast-content-container">
                  <i className="fa fa-exclamation-circle" aria-hidden="true" />
                  {errorMessage}
                </span>,
                {
                  position: toast.POSITION.TOP_CENTER
                }
              );
            }
          }
        }

        return Promise.reject(error);
      }
    );
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.printModeEnabled && nextProps.printModeEnabled) {
      document.body.classList.add(PRINT_CLASS_NAME);
    }
  }

  componentDidMount() {
    /**
     * Check if a token is present in the URL, override the token
     * present in local storage
     */

    const search = this.props.location.search;
    const token =
      !!search && !!qs.parse(search).token ? qs.parse(search).token : null;

    if (!!token) {
      AuthService.setToken(token);
    }

    /**
     * Check if a token is present
     * If present then verify token and load app
     * If not, then forward to the asked route and it will take care of login redirection
     */

    if (!AuthService.hasToken()) {
      this.setState({ noToken: true });
    } else {
      this.props.actions.verifyToken();
    }

    /**
     * Check if print mode enabled
     */

    const queryHash = !!search ? qs.parse(search) : null;
    const print =
      !!queryHash && !!queryHash.print && queryHash.print !== "false"
        ? true
        : false;

    if (print) {
      this.props.actions.enablePrintMode();
      this.setupPrintParamters(queryHash);
    }

    this.setupNetworkConnectivityChecker();
    setTimeout(() => {
      UserService.fetchOktaAuthUser().then(async r => {
        if (r.sub_id && r.sub_id.length > 0) {
          pendo(r.client_name, r.client_id, r.sub_id);
        }
      });
    }, 800);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      ReactGA.pageview(this.props.location.pathname);
    }
  }

  isResponseFromTokenVerificationAPI(response) {
    if (
      response.request &&
      response.request.responseURL.indexOf("/token/verify") > -1
    ) {
      return true;
    }
    return false;
  }

  /**
   * Receives a hash containing all the filters extracted from the URL
   * and normalizes them to the standard accepted by the respective reducer.
   * @param {*} queryHash
   */
  setupPrintParamters(queryHash) {
    // For stacking plan print
    if (this.props.location.pathname.indexOf("/stacking-plan") !== -1) {
      queryHash.disabledSuitesForLeaseExpiryYear = !!queryHash.disabledSuitesForLeaseExpiryYear
        ? queryHash.disabledSuitesForLeaseExpiryYear.split(",").map(key => {
            return key !== undefined && key !== "" && !isNaN(key)
              ? parseInt(key, 10)
              : key;
          })
        : [];
      queryHash.disabledSuitesForSubtenancies = !!queryHash.disabledSuitesForSubtenancies
        ? queryHash.disabledSuitesForSubtenancies.split(",")
        : [];
      queryHash.tenantRightsVisible = !!queryHash.tenantRightsVisible
        ? queryHash.tenantRightsVisible === "true"
          ? true
          : false
        : false;
      queryHash.unselectedTenantRights = !!queryHash.unselectedTenantRights
        ? queryHash.unselectedTenantRights.split(",")
        : [];
      queryHash.area_max = !!queryHash.area_max
        ? parseInt(queryHash.area_max, 10)
        : null;
      queryHash.area_min = !!queryHash.area_min
        ? parseInt(queryHash.area_min, 10)
        : null;
      queryHash.selectedProfileTab = parseInt(queryHash.selectedProfileTab, 10);

      this.props.actions.applyStackingPlanFilters(queryHash);
    }
  }

  setupNetworkConnectivityChecker() {
    Offline.options = {
      checkOnLoad: false,
      interceptRequests: true,
      reconnect: {
        // How many seconds should we wait before rechecking.
        initialDelay: 30,
        // How long should we wait between retries. (1.5 * last delay, capped at 1 hour)
        delay: 5
      }
    };

    Offline.on(
      "down",
      () => {
        ReactGA.event({
          category: "Network",
          action: "Connection down"
        });

        this.props.actions.connectionDown();
      },
      this
    );

    Offline.on(
      "up",
      () => {
        ReactGA.event({
          category: "Network",
          action: "Connection restored"
        });

        this.props.actions.connectionUp();
      },
      this
    );
  }

  componentWillUnmount() {
    Offline.off("down");
    Offline.off("up");
  }

  render() {
    /**
     * If the user is authenticated OR
     * there is no token, then load the app
     * else wait for token verification to compelete
     *
     * TODO: Handle token verification failure here
     */

    const { isProgressActive } = this.props;

    if (
      !AuthService.hasToken() ||
      this.props.authenticated ||
      this.props.oktaAuthenticated ||
      this.props.tokenVerificationFailed
    ) {
      return (
        <div className="app-container container-fluid">
          <ProgressBarProvider
            styles={{ height: "3px" }}
            isActive={isProgressActive}
            color="#674083"
          />
          {this.props.children}
        </div>
      );
    } else {
      return <AppLoader />;
    }
  }
}

function mapStateToProps(state, ownProps) {
  return {
    authenticated: state.auth.authenticated, // To ensure logout happens when state changes
    tokenVerificationFailed: state.auth.tokenVerificationFailed,
    printModeEnabled: state.app.printModeEnabled,
    isProgressActive: state.progress.isActive,
    oktaAuthenticated: state.auth.oktaAuthenticated
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        verifyToken: verifyToken,
        connectionDown: connectionDown,
        connectionUp: connectionUp,
        enablePrintMode: enablePrintMode,
        applyStackingPlanFilters: applyStackingPlanFilters
      },
      dispatch
    )
  };
}

App.propTypes = {
  children: PropTypes.node.isRequired
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
