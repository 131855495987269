import { takeEvery, call, put } from "redux-saga/effects";

import * as leaseConstants from "../constants/leaseConstants";
import LeaseService from "../services/leaseService";

function* fetchLeases(action) {
  try {
    const leasesPage = yield call(LeaseService.fetchLeases, action.payload);
    yield put({
      type: leaseConstants.FETCH_LEASES_SUCCESSFUL,
      payload: leasesPage
    });
  } catch (error) {
    yield put({ type: leaseConstants.FETCH_LEASES_FAILED, error });
  }
}

function* updateLease(action) {
  try {
    const result = yield call(LeaseService.updateLease, action.payload);
    yield put({
      type: leaseConstants.UPDATE_LEASE_SUCCESSFUL,
      payload: result
    });
    yield put({
      type: leaseConstants.FETCH_LEASES,
      payload: action.payload.filters
    });
  } catch (error) {
    yield put({ type: leaseConstants.UPDATE_LEASE_FAILED, error });
  }
}

function* createLease(action) {
  const { lease, onSuccess } = action.payload;

  try {
    const result = yield call(LeaseService.createLease, lease);
    yield put({
      type: leaseConstants.CREATE_LEASE_SUCCESSFUL,
      payload: result
    });

    !!onSuccess && onSuccess();
  } catch (error) {
    yield put({ type: leaseConstants.CREATE_LEASE_FAILED, error });
  }
}

function* deleteLease(action) {
  const { leaseId, onSuccess } = action.payload;

  try {
    const result = yield call(LeaseService.deleteLease, leaseId);
    yield put({
      type: leaseConstants.DELETE_LEASE_SUCCESSFUL,
      payload: result
    });

    !!onSuccess && onSuccess();
  } catch (error) {
    yield put({ type: leaseConstants.DELETE_LEASE_FAILED, error });
  }
}

export function* fetchLeasesWatcher() {
  yield takeEvery(leaseConstants.FETCH_LEASES, fetchLeases);
}

export function* updateLeaseWatcher() {
  yield takeEvery(leaseConstants.UPDATE_LEASE, updateLease);
}

export function* createLeaseWatcher() {
  yield takeEvery(leaseConstants.CREATE_LEASE, createLease);
}

export function* deleteLeaseWatcher() {
  yield takeEvery(leaseConstants.DELETE_LEASE, deleteLease);
}
