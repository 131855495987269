export const FETCH_BUILDING_MEDIA = "buildingMedia/FETCH_BUILDING_MEDIA";
export const FETCH_BUILDING_MEDIA_SUCCESSFUL =
  "buildingMedia/FETCH_BUILDING_MEDIA_SUCCESSFUL";
export const FETCH_BUILDING_MEDIA_FAILED =
  "buildingMedia/FETCH_BUILDING_MEDIA_FAILED";

export const UPLOAD_BUILDING_MEDIA = "buildingMedia/UPLOAD_BUILDING_MEDIA";
export const UPLOAD_BUILDING_MEDIA_SUCCESSFUL =
  "buildingMedia/UPLOAD_BUILDING_MEDIA_SUCCESSFUL";
export const UPLOAD_BUILDING_MEDIA_FAILED =
  "buildingMedia/UPLOAD_BUILDING_MEDIA_FAILED";

export const BUILDING_MEDIA_UPLOAD_PROGRESS =
  "buildingMedia/BUILDING_MEDIA_UPLOAD_PROGRESS";
export const CLEAR_BUILDING_MEDIA_UPLOAD_STATUS =
  "buildingMedia/CLEAR_BUILDING_MEDIA_UPLOAD_STATUS";

export const DELETE_BUILDING_MEDIA = "buildingMedia/DELETE_BUILDING_MEDIA";
export const DELETE_BUILDING_MEDIA_SUCCESSFUL =
  "buildingMedia/DELETE_BUILDING_MEDIA_SUCCESSFUL";
export const DELETE_BUILDING_MEDIA_FAILED =
  "buildingMedia/DELETE_BUILDING_MEDIA_FAILED";

export const UPDATE_BUILDING_MEDIA = "buildingMedia/UPDATE_BUILDING_MEDIA";
export const UPDATE_BUILDING_MEDIA_SUCCESSFUL =
  "buildingMedia/UPDATE_BUILDING_MEDIA_SUCCESSFUL";
export const UPDATE_BUILDING_MEDIA_FAILED =
  "buildingMedia/UPDATE_BUILDING_MEDIA_FAILED";

export const SET_PRIMARY_PICTURE = "buildingMedia/SET_PRIMARY_PICTURE";
export const SET_PRIMARY_PICTURE_SUCCESSFUL =
  "buildingMedia/SET_PRIMARY_PICTURE_SUCCESSFUL";
export const SET_PRIMARY_PICTURE_FAILED =
  "buildingMedia/SET_PRIMARY_PICTURE_FAILED";

export const TOGGLE_PUBLISH_MEDIA_STATUS =
  "buildingMedia/TOGGLE_PUBLISH_MEDIA_STATUS";
export const TOGGLE_PUBLISH_MEDIA_STATUS_SUCCESSFUL =
  "buildingMedia/TOGGLE_PUBLISH_MEDIA_STATUS_SUCCESSFUL";
export const TOGGLE_PUBLISH_MEDIA_STATUS_FAILED =
  "buildingMedia/TOGGLE_PUBLISH_MEDIA_STATUS_FAILED";

export const REORDER_BUILDING_MEDIA = "buildingMedia/REORDER_BUILDING_MEDIA";
export const REORDER_BUILDING_MEDIA_SUCCESSFUL =
  "buildingMedia/REORDER_BUILDING_MEDIA_SUCCESSFUL";
export const REORDER_BUILDING_MEDIA_FAILED =
  "buildingMedia/REORDER_BUILDING_MEDIA_FAILED";
