import * as suitesTourConstants from "../constants/suitesVirtualTourConstants";

const initialState = {
  virtualTours: [],
  createTour: {
    submitting: false
  },
  updateTour: {
    submitting: false
  },
  fetching: false,
  selectedTour: {}
};

const reducer = function suitesReducer(state = initialState, action){
  switch (action.type) {
    // CREATE
    case suitesTourConstants.CREATE_SUITE_TOURS: {
      return Object.assign({}, state, {
        createTour: {
          submitting: true
        }
      });
    }
    case suitesTourConstants.CREATE_SUITE_TOURS_SUCCESSFUL: {
      return Object.assign({}, state, {
        createTour: {
          submitting: false
        }
      });
    }
    case suitesTourConstants.CREATE_SUITE_TOURS_FAILED: {
      return Object.assign({}, state, {
        createTour: {
          submitting: false
        }
      });
    }

    // FETCH
    case suitesTourConstants.FETCH_SUITE_TOURS: {
      return Object.assign({}, state, {
        fetching: true
      });
    }

    case suitesTourConstants.FETCH_SUITE_TOURS_SUCCESSFUL: {
      return Object.assign({}, state, {
        fetching: false,
        virtualTours: action.payload
      });
    }

    case suitesTourConstants.FETCH_SUITE_TOURS_FAILED: {
      return Object.assign({}, state, {
        fetching: false
      });
    }

    // UPDATE
    case suitesTourConstants.UPDATE_SUITE_TOURS: {
      return Object.assign({}, state, {
        updateTour: {
          submitting: true
        }
      });
    }

    case suitesTourConstants.UPDATE_SUITE_TOURS_SUCCESSFUL: {
      return Object.assign({}, state, {
        updateTour: {
          submitting: false
        }
      });
    }

    case suitesTourConstants.UPDATE_SUITE_TOURS_FAILED: {
      return Object.assign({}, state, {
        updateTour: {
          submitting: false
        }
      });
    }

    // DELETE
    case suitesTourConstants.DELETE_SUITE_TOURS: {
      return Object.assign({}, state, {
        deleteTour: {
          submitting: true
        }
      });
    }

    case suitesTourConstants.DELETE_SUITE_TOURS_SUCCESSFUL: {
      return Object.assign({}, state, {
        deleteTour: {
          submitting: false
        }
      });
    }

    case suitesTourConstants.DELETE_SUITE_TOURS_FAILED: {
      return Object.assign({}, state, {
        deleteTour: {
          submitting: false
        }
      });
    }

    default:
      return state;
  }
};

export default reducer;
