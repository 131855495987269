import { takeEvery, call, put } from "redux-saga/effects";
import { startProgress, stopProgress } from "./sagaUtils";
import uuid from "uuid/v4";

import * as dashboardConstants from "../constants/dashboardConstants";
import DashboardService from "../services/dashboardService";

function* fetchDashboardMeta(action) {
  const progressId = uuid();
  yield put(startProgress(progressId));
  try {
    const result = yield call(
      DashboardService.fetchDashboardMeta,
      action.payload
    );
    yield put({
      type: dashboardConstants.FETCH_DASHBOARD_META_SUCCESSFUL,
      payload: result
    });
  } catch (error) {
    yield put({ type: dashboardConstants.FETCH_DASHBOARD_META_FAILED, error });
  } finally {
    yield put(stopProgress(progressId));
  }
}

function* fetchDashboardFilterItems(action) {
  const progressId = uuid();
  yield put(startProgress(progressId));
  try {
    const result = yield call(
      DashboardService.fetchDashboardFilterItems,
      action.payload
    );
    yield put({
      type: dashboardConstants.FETCH_DASHBOARD_FILTERITEMS_SUCCESSFUL,
      payload: result
    });
  } catch (error) {
    yield put({
      type: dashboardConstants.FETCH_DASHBOARD_FILTERITEMS_FAILED,
      error
    });
  } finally {
    yield put(stopProgress(progressId));
  }
}

function* fetchDistribution(action) {
  const progressId = uuid();
  yield put(startProgress(progressId));
  try {
    const result = yield call(
      DashboardService.fetchDistribution,
      action.payload
    );
    yield put({
      type: dashboardConstants.FETCH_DISTRIBUTION_SUCCESSFUL,
      payload: result
    });
  } catch (error) {
    yield put({ type: dashboardConstants.FETCH_DISTRIBUTION_FAILED, error });
  } finally {
    yield put(stopProgress(progressId));
  }
}

function* fetchLeaseExpiryProfile(action) {
  const progressId = uuid();
  yield put(startProgress(progressId));
  try {
    const result = yield call(
      DashboardService.fetchLeaseExpiryProfile,
      action.payload
    );
    yield put({
      type: dashboardConstants.FETCH_DASHBOARD_LEASE_EXPIRY_PROFILE_SUCCESSFUL,
      payload: result
    });
  } catch (error) {
    yield put({
      type: dashboardConstants.FETCH_DASHBOARD_LEASE_EXPIRY_PROFILE_FAILED,
      error
    });
  } finally {
    yield put(stopProgress(progressId));
  }
}

function* fetchInsights(action) {
  const progressId = uuid();
  yield put(startProgress(progressId));
  try {
    const result = yield call(DashboardService.fetchInsights, action.payload);
    yield put({
      type: dashboardConstants.FETCH_INSIGHTS_SUCCESSFUL,
      payload: result
    });
  } catch (error) {
    yield put({ type: dashboardConstants.FETCH_INSIGHTS_FAILED, error });
  } finally {
    yield put(stopProgress(progressId));
  }
}

function* fetchEngagement(action) {
  const progressId = uuid();
  yield put(startProgress(progressId));
  try {
    const result = yield call(DashboardService.fetchEngagement, action.payload);
    yield put({
      type: dashboardConstants.FETCH_ENGAGEMENT_SUCCESSFUL,
      payload: result
    });
  } catch (error) {
    yield put({ type: dashboardConstants.FETCH_ENGAGEMENT_FAILED, error });
  } finally {
    yield put(stopProgress(progressId));
  }
}

export function* fetchDashboardMetaWatcher() {
  yield takeEvery(dashboardConstants.FETCH_DASHBOARD_META, fetchDashboardMeta);
}

export function* fetchDashboardFilterItemsWatcher() {
  yield takeEvery(
    dashboardConstants.FETCH_DASHBOARD_FILTERITEMS,
    fetchDashboardFilterItems
  );
}

export function* fetchDistributionWatcher() {
  yield takeEvery(dashboardConstants.FETCH_DISTRIBUTION, fetchDistribution);
}

export function* fetchLeaseExpiryProfileWatcher() {
  yield takeEvery(
    dashboardConstants.FETCH_DASHBOARD_LEASE_EXPIRY_PROFILE,
    fetchLeaseExpiryProfile
  );
}

export function* fetchInsightsWatcher() {
  yield takeEvery(dashboardConstants.FETCH_INSIGHTS, fetchInsights);
}

export function* fetchEngagementWatcher() {
  yield takeEvery(dashboardConstants.FETCH_ENGAGEMENT, fetchEngagement);
}
