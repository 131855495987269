import * as buildingMediaConstants from "../constants/buildingMediaConstants";
import update from "immutability-helper";

const initialState = {
  media: [],
  uploadStatus: {},
  failedUploads: [],
  uploading: false,
  uploadFailed: false
};

const reducer = function buildingMediaReducer(state = initialState, action) {
  switch (action.type) {
    case buildingMediaConstants.FETCH_BUILDING_MEDIA_SUCCESSFUL:
      return Object.assign({}, state, {
        media: action.payload
      });

    case buildingMediaConstants.UPLOAD_BUILDING_MEDIA:
      return Object.assign({}, state, {
        uploading: true,
        uploadFailed: false,
        failedUploads: [],
        uploadStatus: {}
      });

    case buildingMediaConstants.UPLOAD_BUILDING_MEDIA_SUCCESSFUL:
      return Object.assign({}, state, {
        uploading: false
      });

    case buildingMediaConstants.UPLOAD_BUILDING_MEDIA_FAILED:
      return update(state, {
        failedUploads: { $push: [action.payload.uid] },
        uploadFailed: { $set: true },
        uploading: { $set: false }
      });

    case buildingMediaConstants.BUILDING_MEDIA_UPLOAD_PROGRESS:
      return update(state, {
        uploadStatus: {
          [action.payload.uid]: { $set: action.payload.progress }
        }
      });

    case buildingMediaConstants.CLEAR_BUILDING_MEDIA_UPLOAD_STATUS:
      return Object.assign({}, state, {
        uploadStatus: {}
      });

    default:
      return state;
  }
};

export default reducer;
