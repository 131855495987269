import { takeEvery, call, put } from "redux-saga/effects";

import * as buildingsConstants from "../constants/buildings";
import BuildingsService from "../services/buildingsService";

function* fetchBuilding(action) {
  const { id, onSuccess } = action.payload;

  try {
    const building = yield call(BuildingsService.fetchBuilding, { id: id });
    
    if (building?.detail === "Not found.") {
      yield put({
        type: buildingsConstants.FETCH_BUILDING_FAILED,
        payload: building.detail
      });
    } else {
      yield put({
        type: buildingsConstants.FETCH_BUILDING_SUCCESSFUL,
        payload: building
      });
    }

    !!onSuccess && onSuccess();
  } catch (error) {
    yield put({ type: buildingsConstants.FETCH_BUILDING_FAILED, error });
  }
}

function* fetchBuildingAfterUpdate(action) {
  try {
    const building = yield call(BuildingsService.fetchBuilding, action.payload);
    yield put({
      type: buildingsConstants.FETCH_BUILDING_AFTER_UPDATE_SUCCESSFUL,
      payload: building
    });
  } catch (error) {
    yield put({
      type: buildingsConstants.FETCH_BUILDING_AFTER_UPDATE_FAILED,
      error
    });
  }
}

function* fetchBuildings(action) {
  try {
    const buildingsPage = yield call(
      BuildingsService.fetchBuildings,
      action.payload
    );
    yield put({
      type: buildingsConstants.FETCH_BUILDINGS_SUCCESSFUL,
      payload: buildingsPage
    });
  } catch (error) {
    yield put({ type: buildingsConstants.FETCH_BUILDINGS_FAILED, error });
  }
}

function* fetchBuildingsFilterItems(action) {
  try {
    const result = yield call(BuildingsService.fetchBuildingsFilterItems);
    yield put({
      type: buildingsConstants.FETCH_BUILDINGS_FILTER_ITEMS_SUCCESSFUL,
      payload: result
    });
  } catch (error) {
    yield put({
      type: buildingsConstants.FETCH_BUILDINGS_FILTER_ITEMS_FAILED,
      error
    });
  }
}

function* createBuilding(action) {
  const { building, onSuccess } = action.payload;

  try {
    const result = yield call(BuildingsService.createBuilding, building);
    yield put({
      type: buildingsConstants.CREATE_BUILDING_SUCCESSFUL,
      payload: result
    });

    !!onSuccess && onSuccess(result);
  } catch (error) {
    yield put({ type: buildingsConstants.CREATE_BUILDING_FAILED, error });
  }
}

function* deleteBuilding(action) {
  const { onSuccess } = action.payload;

  try {
    const result = yield call(BuildingsService.deleteBuilding, action.payload);
    yield put({
      type: buildingsConstants.DELETE_BUILDING_SUCCESSFUL,
      payload: {
        id: action.payload.buildingId
      }
    });

    !!onSuccess && onSuccess();
  } catch (error) {
    yield put({ type: buildingsConstants.DELETE_BUILDING_FAILED, error });
  }
}

function* updateBuilding(action) {
  const { onSuccess } = action.payload;

  try {
    const result = yield call(BuildingsService.updateBuilding, action.payload);
    yield put({
      type: buildingsConstants.UPDATE_BUILDING_SUCCESSFUL,
      payload: result
    });
    yield put({
      type: buildingsConstants.FETCH_BUILDING_AFTER_UPDATE,
      payload: {
        id: action.payload.buildingId
      }
    });

    onSuccess && onSuccess(result);
  } catch (error) {
    yield put({ type: buildingsConstants.UPDATE_BUILDING_FAILED, error });
  }
}

function* setFavourite(action) {
  const { onSuccess } = action.payload;

  try {
    const result = yield call(BuildingsService.setFavourite, action.payload);
    yield put({
      type: buildingsConstants.SET_FAVOURITE_SUCCESSFUL,
      payload: result
    });
    yield put({
      type: buildingsConstants.FETCH_BUILDING_AFTER_UPDATE,
      payload: {
        id: action.payload.buildingId
      }
    });

    onSuccess && onSuccess(result);
  } catch (error) {
    yield put({ type: buildingsConstants.SET_FAVOURITE_FAILED, error });
  }
}

function* unsetFavourite(action) {
  const { onSuccess } = action.payload;

  try {
    const result = yield call(BuildingsService.unsetFavourite, action.payload);
    yield put({
      type: buildingsConstants.UNSET_FAVOURITE_SUCCESSFUL,
      payload: result
    });
    yield put({
      type: buildingsConstants.FETCH_BUILDING_AFTER_UPDATE,
      payload: {
        id: action.payload.buildingId
      }
    });

    onSuccess && onSuccess(result);
  } catch (error) {
    yield put({ type: buildingsConstants.UNSET_FAVOURITE_FAILED, error });
  }
}

function* fetchBuildingGroups(action) {
  try {
    const result = yield call(BuildingsService.fetchBuildingGroups);
    yield put({
      type: buildingsConstants.FETCH_BUILDING_GROUPS_SUCCESSFUL,
      payload: result
    });
  } catch (error) {
    yield put({ type: buildingsConstants.FETCH_BUILDING_GROUPS_FAILED, error });
  }
}

function* fetchPromotedBuildings(action) {
  try {
    const buildingsPage = yield call(
      BuildingsService.fetchPromotedBuildings,
      action.payload
    );
    yield put({
      type: buildingsConstants.FETCH_PROMOTED_BUILDINGS_SUCCESSFUL,
      payload: buildingsPage
    });
  } catch (error) {
    yield put({
      type: buildingsConstants.FETCH_PROMOTED_BUILDINGS_FAILED,
      error
    });
  }
}

function* promoteBuilding(action) {
  const { onSuccess, buildingId } = action.payload;

  try {
    const result = yield call(BuildingsService.promoteBuilding, buildingId);
    yield put({
      type: buildingsConstants.PROMOTE_BUILDING_SUCCESSFUL,
      payload: result
    });
    yield put({
      type: buildingsConstants.FETCH_BUILDING_AFTER_UPDATE,
      payload: {
        id: buildingId
      }
    });

    onSuccess && onSuccess(result);
  } catch (error) {
    yield put({ type: buildingsConstants.PROMOTE_BUILDING_FAILED, error });
  }
}

function* unPromoteBuilding(action) {
  const { onSuccess, buildingId } = action.payload;

  try {
    const result = yield call(BuildingsService.unPromoteBuilding, buildingId);
    yield put({
      type: buildingsConstants.UNPROMOTE_BUILDING_SUCCESSFUL,
      payload: result
    });
    yield put({
      type: buildingsConstants.FETCH_BUILDING_AFTER_UPDATE,
      payload: {
        id: buildingId
      }
    });

    onSuccess && onSuccess(result);
  } catch (error) {
    yield put({ type: buildingsConstants.UNPROMOTE_BUILDING_FAILED, error });
  }
}

function* updatePromotedBuildingList(action) {
  const { request, onSuccess } = action.payload;

  try {
    const result = yield call(
      BuildingsService.updatePromotedBuildingList,
      request
    );
    yield put({
      type: buildingsConstants.UPDATE_PROMOTED_BUILDING_LIST_SUCCESSFUL,
      payload: result
    });

    onSuccess && onSuccess(result);
  } catch (error) {
    yield put({
      type: buildingsConstants.UPDATE_PROMOTED_BUILDING_LIST_FAILED,
      error
    });
  }
}

function* archiveBuilding(action) {
  const { onSuccess, buildingId } = action.payload;

  try {
    const result = yield call(BuildingsService.archiveBuilding, buildingId);
    yield put({
      type: buildingsConstants.ARCHIVE_BUILDING_SUCCESSFUL,
      payload: result
    });
    yield put({
      type: buildingsConstants.FETCH_BUILDING_AFTER_UPDATE,
      payload: {
        id: buildingId
      }
    });

    onSuccess && onSuccess(result);
  } catch (error) {
    yield put({ type: buildingsConstants.ARCHIVE_BUILDING_FAILED, error });
  }
}

function* unArchiveBuilding(action) {
  const { onSuccess, buildingId } = action.payload;

  try {
    const result = yield call(BuildingsService.unArchiveBuilding, buildingId);
    yield put({
      type: buildingsConstants.UNARCHIVE_BUILDING_SUCCESSFUL,
      payload: result
    });
    yield put({
      type: buildingsConstants.FETCH_BUILDING_AFTER_UPDATE,
      payload: {
        id: buildingId
      }
    });

    onSuccess && onSuccess(result);
  } catch (error) {
    yield put({ type: buildingsConstants.UNARCHIVE_BUILDING_FAILED, error });
  }
}

function* fetchBuildingSuites(action) {
  const { buildingId } = action.payload;

  try {
    const suites = yield call(BuildingsService.fetchBuildingSuites, buildingId);
    yield put({
      type: buildingsConstants.FETCH_SUITES_FOR_BUILDING_SUCCESSFUL,
      payload: suites
    });
  } catch (error) {
    yield put({
      type: buildingsConstants.FETCH_SUITES_FOR_BUILDING_FAILED,
      error
    });
  }
}

export function* fetchBuildingWatcher() {
  yield takeEvery(buildingsConstants.FETCH_BUILDING, fetchBuilding);
}

export function* fetchBuildingAfterUpdateWatcher() {
  yield takeEvery(
    buildingsConstants.FETCH_BUILDING_AFTER_UPDATE,
    fetchBuildingAfterUpdate
  );
}

export function* fetchBuildingsWatcher() {
  yield takeEvery(buildingsConstants.FETCH_BUILDINGS, fetchBuildings);
}

export function* fetchBuildingsFilterItemsWatcher() {
  yield takeEvery(
    buildingsConstants.FETCH_BUILDINGS_FILTER_ITEMS,
    fetchBuildingsFilterItems
  );
}

export function* createBuildingWatcher() {
  yield takeEvery(buildingsConstants.CREATE_BUILDING, createBuilding);
}

export function* deleteBuildingWatcher() {
  yield takeEvery(buildingsConstants.DELETE_BUILDING, deleteBuilding);
}

export function* updateBuildingWatcher() {
  yield takeEvery(buildingsConstants.UPDATE_BUILDING, updateBuilding);
}

export function* setFavouriteWatcher() {
  yield takeEvery(buildingsConstants.SET_FAVOURITE, setFavourite);
}

export function* unsetFavouriteWatcher() {
  yield takeEvery(buildingsConstants.UNSET_FAVOURITE, unsetFavourite);
}

export function* fetchBuildingGroupsWatcher() {
  yield takeEvery(
    buildingsConstants.FETCH_BUILDING_GROUPS,
    fetchBuildingGroups
  );
}

export function* fetchPromotedBuildingsWatcher() {
  yield takeEvery(
    buildingsConstants.FETCH_PROMOTED_BUILDINGS,
    fetchPromotedBuildings
  );
}

export function* promoteBuildingWatcher() {
  yield takeEvery(buildingsConstants.PROMOTE_BUILDING, promoteBuilding);
}

export function* unPromoteBuildingWatcher() {
  yield takeEvery(buildingsConstants.UNPROMOTE_BUILDING, unPromoteBuilding);
}

export function* updatePromotedBuildingListWatcher() {
  yield takeEvery(
    buildingsConstants.UPDATE_PROMOTED_BUILDING_LIST,
    updatePromotedBuildingList
  );
}

export function* archiveBuildingWatcher() {
  yield takeEvery(buildingsConstants.ARCHIVE_BUILDING, archiveBuilding);
}

export function* unArchiveBuildingWatcher() {
  yield takeEvery(buildingsConstants.UNARCHIVE_BUILDING, unArchiveBuilding);
}

export function* fetchBuildingSuitesWatcher() {
  yield takeEvery(
    buildingsConstants.FETCH_SUITES_FOR_BUILDING,
    fetchBuildingSuites
  );
}
