export const UPDATE_BUILDING = "BuildingInfo/UPDATE_BUILDING";
export const UPDATE_BUILDING_SUCCESSFUL =
  "BuildingInfo/UPDATE_BUILDING_SUCCESSFUL";
export const UPDATE_BUILDING_FAILED = "BuildingInfo/UPDATE_BUILDING_FAILED";

export const FETCH_BUILDING_INFO_LIST = "BuildingInfo/FETCH_BUILDING_INFO_LIST";
export const FETCH_BUILDING_INFO_LIST_SUCCESSFUL =
  "BuildingInfo/FETCH_BUILDING_INFO_LIST_SUCCESSFUL";
export const FETCH_BUILDING_INFO_LIST_FAILED =
  "BuildingInfo/FETCH_BUILDING_INFO_LIST_FAILED";

export const CREATE_BUILDING_INFORMATION_ITEM =
  "BuildingInfo/CREATE_BUILDING_INFORMATION_ITEM";
export const CREATE_BUILDING_INFORMATION_ITEM_SUCCESSFUL =
  "BuildingInfo/CREATE_BUILDING_INFORMATION_ITEM_SUCCESSFUL";
export const CREATE_BUILDING_INFORMATION_ITEM_FAILED =
  "BuildingInfo/CREATE_BUILDING_INFORMATION_ITEM_FAILED";

export const UPDATE_BUILDING_INFORMATION_ITEM =
  "BuildingInfo/UPDATE_BUILDING_INFORMATION_ITEM";
export const UPDATE_BUILDING_INFORMATION_ITEM_SUCCESSFUL =
  "BuildingInfo/UPDATE_BUILDING_INFORMATION_ITEM_SUCCESSFUL";
export const UPDATE_BUILDING_INFORMATION_ITEM_FAILED =
  "BuildingInfo/UPDATE_BUILDING_INFORMATION_ITEM_FAILED";

export const DELETE_BUILDING_INFORMATION_ITEM =
  "BuildingInfo/DELETE_BUILDING_INFORMATION_ITEM";
export const DELETE_BUILDING_INFORMATION_ITEM_SUCCESSFUL =
  "BuildingInfo/DELETE_BUILDING_INFORMATION_ITEM_SUCCESSFUL";
export const DELETE_BUILDING_INFORMATION_ITEM_FAILED =
  "BuildingInfo/DELETE_BUILDING_INFORMATION_ITEM_FAILED";

export const FETCH_BUILDING_INFO_META = "BuildingInfo/FETCH_BUILDING_INFO_META";
export const FETCH_BUILDING_INFO_META_SUCCESSFUL =
  "BuildingInfo/FETCH_BUILDING_INFO_META_SUCCESSFUL";
export const FETCH_BUILDING_INFO_META_FAILED =
  "BuildingInfo/FETCH_BUILDING_INFO_META_FAILED";

export const REORDER_BUILDING_INFORMATION_ITEMS =
  "BuildingInfo/REORDER_BUILDING_INFORMATION_ITEMS";
export const REORDER_BUILDING_INFORMATION_ITEMS_SUCCESSFUL =
  "BuildingInfo/REORDER_BUILDING_INFORMATION_ITEMS_SUCCESSFUL";
export const REORDER_BUILDING_INFORMATION_ITEMS_FAILED =
  "BuildingInfo/REORDER_BUILDING_INFORMATION_ITEMS_FAILED";

export const BUILDING_INFORMATION_UNMOUNT =
  "BuildingInfo/BUILDING_INFORMATION_UNMOUNT";
