import http from "../auth/http";
import config from "../config";
import { getGoogleAPIKeyValuePair } from "../utilities";
import axios from "axios";

const BuildingInformationService = () => {
  const fetchBuildingInformation = request => {
    const { buildingId } = request;

    return http
      .get(`/api/v1/buildinginformation/?building=${buildingId}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const updateBuildingInformation = request => {
    return http
      .patch(
        `/api/v1/buildinginformation/${request.buildingInformationId}/`,
        request.buildingInformation
      )
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const fetchBuildingInformationMeta = request =>
    http
      .get(`/api/v1/buildings/${request.buildingId}/buildinginformationmeta/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const createBuildingInformationItem = request => {
    return http
      .post(`/api/v1/buildinginformation/`, request)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const updateBuildingInformationItem = (
    buildingId,
    buildingInformationItem
  ) => {
    return http
      .patch(
        `/api/v1/buildinginformation/${buildingId}/`,
        buildingInformationItem
      )
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const deleteBuildingInformationItem = id => {
    return http
      .delete(`/api/v1/buildinginformation/${id}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const getBuildingGeolocation = request => {
    const googleAPIKeyValuePair = getGoogleAPIKeyValuePair();
    return axios
      .get("https://maps.googleapis.com/maps/api/geocode/json", {
        params: {
          address: request.address,
          key: config.GOOGLE_MAPS_API_KEY
        }
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const reorderBuildingInformationItems = (buildingId, request) => {
    return http
      .patch(
        `/api/v1/buildinginformation/orderinformations/${buildingId}/`,
        request
      )
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  return {
    fetchBuildingInformation,
    updateBuildingInformation,
    reorderBuildingInformationItems,
    fetchBuildingInformationMeta,

    createBuildingInformationItem,
    updateBuildingInformationItem,
    deleteBuildingInformationItem,

    getBuildingGeolocation
  };
};

const api = BuildingInformationService();
export default api;
