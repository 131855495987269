import { takeEvery, call, put, take } from "redux-saga/effects";
import { startProgress, stopProgress } from "./sagaUtils";
import uuid from "uuid/v4";

import * as buildingMediaConstants from "../constants/buildingMediaConstants";
import BuildingMediaService from "../services/buildingMediaService";

function* fetchBuildingMedia(action) {
  const progressId = uuid();
  yield put(startProgress(progressId));
  try {
    const result = yield call(
      BuildingMediaService.fetchBuildingMedia,
      action.payload
    );
    yield put({
      type: buildingMediaConstants.FETCH_BUILDING_MEDIA_SUCCESSFUL,
      payload: result
    });
  } catch (error) {
    yield put({
      type: buildingMediaConstants.FETCH_BUILDING_MEDIA_FAILED,
      error
    });
  } finally {
    yield put(stopProgress(progressId));
  }
}

function* uploadBuildingMedia(action) {
  const progressId = uuid();
  yield put(startProgress(progressId));
  try {
    const channel = yield call(
      BuildingMediaService.uploadBuildingMedia,
      action.payload.data
    );
    while (true) {
      const { progress = 0, err, success } = yield take(channel);
      if (err) {
        /**
         * Manually complete the progress as the final progress event may have been debounced
         * in the service call by lodash
         */
        yield put({
          type: buildingMediaConstants.BUILDING_MEDIA_UPLOAD_PROGRESS,
          payload: {
            uid: action.payload.upload_uid,
            progress: 1
          }
        });
        yield put({
          type: buildingMediaConstants.UPLOAD_BUILDING_MEDIA_FAILED,
          payload: {
            uid: action.payload.upload_uid,
            error: err
          }
        });
        return;
      }
      if (success) {
        /**
         * Manually complete the progress as the final progress event may have been debounced
         * in the service call by lodash
         */
        yield put({
          type: buildingMediaConstants.BUILDING_MEDIA_UPLOAD_PROGRESS,
          payload: {
            uid: action.payload.upload_uid,
            progress: 1
          }
        });
        yield put({
          type: buildingMediaConstants.UPLOAD_BUILDING_MEDIA_SUCCESSFUL,
          payload: {
            uid: action.payload.upload_uid
          }
        });
        yield put({
          type: buildingMediaConstants.FETCH_BUILDING_MEDIA,
          payload: {
            buildingId: action.payload.buildingId
          }
        });
        return;
      }

      yield put({
        type: buildingMediaConstants.BUILDING_MEDIA_UPLOAD_PROGRESS,
        payload: {
          uid: action.payload.upload_uid,
          progress: progress
        }
      });
    }
  } catch (error) {
    /**
     * Manually complete the progress as the final progress event may have been debounced
     * in the service call by lodash
     */
    yield put({
      type: buildingMediaConstants.BUILDING_MEDIA_UPLOAD_PROGRESS,
      payload: {
        uid: action.payload.upload_uid,
        progress: 1
      }
    });
    yield put({
      type: buildingMediaConstants.UPLOAD_BUILDING_MEDIA_FAILED,
      payload: {
        uid: action.payload.upload_uid,
        error: error
      }
    });
  } finally {
    yield put(stopProgress(progressId));
    return;
  }
}

function* deleteBuildingMedia(action) {
  const progressId = uuid();
  yield put(startProgress(progressId));
  try {
    const result = yield call(
      BuildingMediaService.deleteBuildingMedia,
      action.payload
    );
    yield put({
      type: buildingMediaConstants.DELETE_BUILDING_MEDIA_SUCCESSFUL,
      payload: result
    });
    yield put({
      type: buildingMediaConstants.FETCH_BUILDING_MEDIA,
      payload: {
        buildingId: action.payload.buildingId
      }
    });
  } catch (error) {
    yield put({
      type: buildingMediaConstants.DELETE_BUILDING_MEDIA_FAILED,
      error
    });
  } finally {
    yield put(stopProgress(progressId));
  }
}

function* updateBuildingMedia(action) {
  const progressId = uuid();
  yield put(startProgress(progressId));

  const { mediaId, media, onSuccess } = action.payload;

  try {
    const result = yield call(
      BuildingMediaService.updateBuildingMedia,
      mediaId,
      media
    );
    yield put({
      type: buildingMediaConstants.UPDATE_BUILDING_MEDIA_SUCCESSFUL,
      payload: result
    });

    !!onSuccess && onSuccess();
  } catch (error) {
    yield put({
      type: buildingMediaConstants.UPDATE_BUILDING_MEDIA_FAILED,
      error
    });
  } finally {
    yield put(stopProgress(progressId));
  }
}

function* setPrimaryPicture(action) {
  const progressId = uuid();
  yield put(startProgress(progressId));
  try {
    const result = yield call(
      BuildingMediaService.setPrimaryPicture,
      action.payload
    );
    yield put({
      type: buildingMediaConstants.SET_PRIMARY_PICTURE_SUCCESSFUL,
      payload: result
    });
    yield put({
      type: buildingMediaConstants.FETCH_BUILDING_MEDIA,
      payload: {
        buildingId: action.payload.buildingId
      }
    });
  } catch (error) {
    yield put({
      type: buildingMediaConstants.SET_PRIMARY_PICTURE_FAILED,
      error
    });
  } finally {
    yield put(stopProgress(progressId));
  }
}

function* togglePublishMediaStatus(action) {
  const progressId = uuid();
  yield put(startProgress(progressId));

  const { mediaId, publish, buildingId } = action.payload;
  try {
    const result = yield call(
      publish
        ? BuildingMediaService.publishMedia
        : BuildingMediaService.unpublishMedia,
      mediaId
    );
    yield put({
      type: buildingMediaConstants.TOGGLE_PUBLISH_MEDIA_STATUS_SUCCESSFUL,
      payload: result
    });
    yield put({
      type: buildingMediaConstants.FETCH_BUILDING_MEDIA,
      payload: {
        buildingId: buildingId
      }
    });
  } catch (error) {
    yield put({
      type: buildingMediaConstants.TOGGLE_PUBLISH_MEDIA_STATUS_FAILED,
      error
    });
  } finally {
    yield put(stopProgress(progressId));
  }
}

function* reorderMedia(action) {
  const progressId = uuid();
  yield put(startProgress(progressId));

  const { data, buildingId, onSuccess } = action.payload;
  try {
    const result = yield call(
      BuildingMediaService.reorderMedia,
      buildingId,
      data
    );
    yield put({
      type: buildingMediaConstants.REORDER_BUILDING_MEDIA_SUCCESSFUL,
      payload: result
    });
    !!onSuccess && onSuccess();
  } catch (error) {
    yield put({
      type: buildingMediaConstants.REORDER_BUILDING_MEDIA_FAILED,
      error
    });
  } finally {
    yield put(stopProgress(progressId));
  }
}

export function* fetchBuildingMediaWatcher() {
  yield takeEvery(
    buildingMediaConstants.FETCH_BUILDING_MEDIA,
    fetchBuildingMedia
  );
}

export function* uploadBuildingMediaWatcher() {
  yield takeEvery(
    buildingMediaConstants.UPLOAD_BUILDING_MEDIA,
    uploadBuildingMedia
  );
}

export function* deleteBuildingMediaWatcher() {
  yield takeEvery(
    buildingMediaConstants.DELETE_BUILDING_MEDIA,
    deleteBuildingMedia
  );
}

export function* updateBuildingMediaWatcher() {
  yield takeEvery(
    buildingMediaConstants.UPDATE_BUILDING_MEDIA,
    updateBuildingMedia
  );
}

export function* setPrimaryPictureWatcher() {
  yield takeEvery(
    buildingMediaConstants.SET_PRIMARY_PICTURE,
    setPrimaryPicture
  );
}

export function* togglePublishMediaStatusWatcher() {
  yield takeEvery(
    buildingMediaConstants.TOGGLE_PUBLISH_MEDIA_STATUS,
    togglePublishMediaStatus
  );
}

export function* reorderMediaWatcher() {
  yield takeEvery(buildingMediaConstants.REORDER_BUILDING_MEDIA, reorderMedia);
}
