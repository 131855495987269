import { noAuthHttp } from "../auth/http";

const SettingService = () => {
  const getSystemSettings = () => {
    return noAuthHttp
      .get(`/api/v1/settings/system`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data));
  };

  return {
    getSystemSettings
  };
};

const api = SettingService();
export default api;
