export const FETCH_STACKING_PLAN = "stacking-plan/FETCH_STACKING_PLAN";
export const FETCH_STACKING_PLAN_SUCCESSFUL =
  "stacking-plan/FETCH_STACKING_PLAN_SUCCESSFUL";
export const FETCH_STACKING_PLAN_FAILED =
  "stacking-plan/FETCH_STACKING_PLAN_FAILED";

export const LEASE_EXPIRY_TOGGLE = "stacking-plan/LEASE_EXPIRY_TOGGLE";
export const LEASE_EXPIRY_SELECT_ALL = "stacking-plan/LEASE_EXPIRY_SELECT_ALL";
export const LEASE_EXPIRY_UNSELECT_ALL =
  "stacking-plan/LEASE_EXPIRY_UNSELECT_ALL";

export const SUBTENANCY_TOGGLE = "stacking-plan/SUBTENANCY_TOGGLE";

export const TENANT_RIGHTS_VISIBILITY_TOGGLE =
  "stacking-plan/TENANT_RIGHTS_VISIBILITY_TOGGLE";
export const TENANT_RIGHT_TOGGLE = "stacking-plan/TENANT_RIGHT_TOGGLE";
export const TENANT_RIGHTS_SELECT_ALL =
  "stacking-plan/TENANT_RIGHTS_SELECT_ALL";
export const TENANT_RIGHTS_UNSELECT_ALL =
  "stacking-plan/TENANT_RIGHTS_UNSELECT_ALL";

export const SUITE_SEARCH_FILTER_CHANGED =
  "stacking-plan/SUITE_SEARCH_FILTER_CHANGED";

export const SUITE_TYPE_FILTER_CHANGED =
  "stacking-plan/SUITE_TYPE_FILTER_CHANGED";
export const SUITE_AVAILABILITY_FILTER_CHANGED =
  "stacking-plan/SUITE_AVAILABILITY_FILTER_CHANGED";
export const AREA_RANGE_FILTER_CHANGED =
  "stacking-plan/AREA_RANGE_FILTER_CHANGED";
export const SUITE_VISIBILITY_FILTER_CHANGED =
  "stacking-plan/SUITE_VISIBILITY_FILTER_CHANGED";

export const FETCH_STACKING_PLAN_FILTERPROFILE =
  "stacking-plan/FETCH_STACKING_PLAN_FILTERPROFILE";
export const FETCH_STACKING_PLAN_FILTERPROFILE_SUCCESSFUL =
  "stacking-plan/FETCH_STACKING_PLAN_FILTERPROFILE_SUCCESSFUL";
export const FETCH_STACKING_PLAN_FILTERPROFILE_FAILED =
  "stacking-plan/FETCH_STACKING_PLAN_FILTERPROFILE_FAILED";
export const CHANGE_SELECTED_FILTER_PROFILE =
  "stacking-plan/CHANGE_SELECTED_FILTER_PROFILE";

export const STACKING_PLAN_UNMOUNT = "stacking-plan/STACKING_PLAN_UNMOUNT";
export const APPLY_FILTERS = "stacking-plan/APPLY_FILTERS";

export const panelLayout = {
  EXPANDED: "stacking-plan/filter-panel/expanded",
  NORMAL: "stacking-plan/filter-panel/normal",
  COLLAPSED: "stacking-plan/filter-panel/collapsed"
};

export const filterProfileTypes = {
  LEASE_EXPIRY: 1,
  SUBTENANCIES: 2
};

export const disabilityFilter = "4D";

export const SUITE_AVAILABILITY_TYPES = [
  { id: "P", value: "Public" },
  { id: "PR", value: "Private" },
  { id: "L", value: "Leased" },
  { id: "C", value: "Committed" },
  { id: "M", value: "Month-To-Month" },
  { id: "PE", value: "Pending" }
];
