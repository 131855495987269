import React, { Component } from "react";
import queryString from 'query-string';
import { Redirect } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { authenticateOktaUser, logout } from "../Auth/actions";
import AuthService from "../../services/authService";
import AppLoader from "../../components/common/AppLoader";
import { toast } from "react-toastify";


class LoginOktaRedirect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: ""
    }
  }

  componentDidMount() {
    const location = queryString.parse(this.props.location.search);
    this.props.actions.authenticateOktaUser(location)
  }

  render() {
    const searchParams = queryString.parse(this.props.location.search);
    if(searchParams.logout) {
      this.props.actions.logout({
        onSuccess: () => {
          this.props.history.push(`/login`);
        } ,
        onError: () => {
          toast("Unable to logout. Please try again later.", {
            position: toast.POSITION.TOP_CENTER
          });
        }
      });
    }
    if (AuthService.hasToken()) {
      const path = searchParams.next || "/";
      return <Redirect to={path} />;
    }
    return <AppLoader />
  }
}

const mapDispatchToProps = (dispatch)  => {
  return {
    actions: bindActionCreators(
      {
        authenticateOktaUser: authenticateOktaUser,
        logout: logout,
      },
      dispatch
    )
  };
}

const connected = connect(null, mapDispatchToProps)(LoginOktaRedirect);



export default connected;
